<div class="container breadcrumbs-opeff d-none">
    <p><a href="/">Home</a><span> &gt; </span><a href="/overview">flu360® Overview</a><span> &gt;
        </span><strong>Operational Efficiency</strong></p>
</div>
<!--clinical-support-->
<div class="breadcrumbs--page-nav d-none">
    <div class="container">
        <p><a href="/">Home</a><span> &gt; </span><a href="/overview">flu360® Overview</a><span> &gt;
            </span><strong>Clinical Support</strong></p>
    </div>
</div>
<div *ngIf="!userLoggedIn">
    <div class="container op-ef-firstcontainer" *ngIf="leftBannerNode | async  as item">
        <!--operational-efficiency starting-->
        <div class="row-flex hero--circlechart opeffciency-circlechart">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 hero--circlechart-mobile"
                *ngIf="item?.mediaPicture">
                <img class="hero--circlechart-chart" alt="image" [src]="getImage(item.mediaPicture)">
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-hero-100">
                <div class="hero--circlechart-content">
                    <img class="rectangle-down" alt="angled corner"
                        src="../../../app/../assets/images/rectangle-down.svg">
                    <div class="content-container">
                        <div class="container">
                            <h1 [innerHTML]="item.headline"></h1>
                            <p class="text--grey-110" [innerHTML]="item.content"></p>
                            <p class="bottom_line"><strong [innerHTML]="item.h2content"></strong></p>
                            <a href="/createprofile">
                                <div class="global_greybtn d-none">Create Account</div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div></div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12  hero--circlechart-desktop"
                *ngIf="item?.mediaPicture">
                <img class="hero--circlechart-chart" alt="image" [src]="getImage(item.mediaPicture)">
            </div>
        </div>
    </div>
</div>

<!--Help and faq logged In version-->
<div class="help_LIheadersection col-xs-12" *ngIf="userLoggedIn">
    <div *ngIf="leftBannerNode | async  as item">
        <h1 [innerHTML]="item.headline" class="help_LIheader new_header"></h1>
        <p class="help_LIsubheader" [innerHTML]="item.content"></p>
    </div>
</div>































<!--operational-efficiency ends above-->

<!-- <div class="row-flex hero--circlechart" >
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 hero--circlechart-mobile">
            <img class="hero--circlechart-chart" alt="image" [src]="getImage(item.mediaPicture)">
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-hero-100">
            <div class="hero--circlechart-content">
                <img class="rectangle-down" alt="angled corner" src="../../../app/../assets/images/rectangle-down.svg">
                <div class="content-container text-left">
                    <h1>{{item.headline}}</h1>
                    <p class="text-gray" [innerHTML]="item.content"></p>
                    <p class="content"><strong>{{item.h2content}}</strong></p>
                    <a href="/createprofile">
                    </a>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 hero--circlechart-desktop">

            <img class="hero--circlechart-chart" alt="image" [src]="getImage(item.mediaPicture)">
        </div>
</div> -->