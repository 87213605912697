import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { CmsNavigationComponent, CmsLinkComponent, AuthService, User, UserIdService, CmsBannerComponent, OccConfig, BaseSiteService} from '@spartacus/core';
import { Observable, Subscription, map } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrl: './top-header.component.scss'
})

  export class TopHeaderComponent {
    toggleListView:boolean=false;
    node$: Observable<NavigationNode | null | undefined | any> = this.service.createNavigation(
      this.componentData?.data$
    );
    isCartExist: Observable<any> = this.userInfoService.checkCartExist();
    styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
      map((d) => d?.styleClass)
    );
  navigation: any=[];
  mobnav:any=[];
  Ukclass: boolean = false;
  iconClass:string="fa fa-bars";
  isOpen: boolean=false;
  afluriaurl: any;
  fluadurl: any;
  flucelvaxurl: any;
  isUserLoggedIn: boolean=false;
  userInfo: any;
  glyphiconIcon = "glyphicon-menu-down";   
  iconClassMob: string="fa fa-bars";
  subscription:Subscription[]=[];
  flucelvaxPdf!: Observable<any>;
  fluadPdf!: Observable<any>;
  afluriaPdf!: Observable<any>;
  currentSite: string | undefined;

    constructor(
      public userInfoService:UserInfoService,public component: CmsComponentData<CmsBannerComponent>,
      private cdr:ChangeDetectorRef,
      protected componentData: CmsComponentData<CmsNavigationComponent>,
      protected service: NavigationService, protected _mobmenu:ContentService,
      protected config: OccConfig,private baseSiteService:BaseSiteService
    ) {
    }

    multipleSoldTos$= this.userInfoService.fetchMultipleSoldToForUser();
    
    fetchPIPdfData(){
      if(this.currentSite == 'seqirusb2b-flu360'){
        this.afluriaPdf= this._mobmenu.getNavigation("AfluriaHeaderLink");
        this.fluadPdf= this._mobmenu.getNavigation("FluadHeaderLink");
        this.flucelvaxPdf= this._mobmenu.getNavigation("FlucelvaxHeaderLink");

        this.afluriaPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
          this.afluriaurl = res;
      });
      this.fluadPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
        this.fluadurl = res;
      });
      this.flucelvaxPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
        this.flucelvaxurl = res;
      });
      }
    }
  
    openList(){
      this.toggleListView=!this.toggleListView;
      
    }
    toggleSlideLoggedInMob(){
      if(this.iconClassMob === 'fa fa-bars'){
        this.iconClassMob = 'fa fa-times';
        this.isOpen=true;
        this.userInfoService.setMobileHeader(true);
      }
      else{
        this.iconClassMob = 'fa fa-bars';
        this.isOpen=false;
        this.userInfoService.setMobileHeader(false);
      }
    }
    toggleSlide(){
      this._mobmenu.togglemabmenu=!this._mobmenu.togglemabmenu;
 
      const temp= <HTMLElement> document.getElementsByClassName("mob_exp_menu")[1];
  
      if(this._mobmenu.togglemabmenu){
        temp.style.display="block";
      }
      else{
        temp.style.display="none";
      }
      if(this.iconClass === 'fa fa-bars'){
        this.iconClass = 'fa fa-times';
        this.isOpen=true;
      }
      else{
        this.iconClass = 'fa fa-bars';
        this.isOpen=false;
      }
    }
    setDifferentTopHeader(){
      const temp= <HTMLElement> document.getElementsByClassName("TopHeaderNavSlot")[0];
      if(temp){
      if(this.isUserLoggedIn && this.userInfo.webEnabled){
          temp.style.display='none';
      }
      else if((!this.isUserLoggedIn) || ((this.isUserLoggedIn) && (!this.userInfo.webEnabled))){
        temp.style.display='block';
        }
      }
    }
    setDifferentBottomHeader(){
      const temp= <HTMLElement> document.getElementsByClassName("BottomHeaderSlot")[1];
      const body=<HTMLElement>document.querySelector("body");
      if(temp){
      if(this.isUserLoggedIn && this.userInfo.webEnabled){
        body.classList.add("loggedin");
          temp.style.display='none';
      }
      else if((!this.isUserLoggedIn) || ((this.isUserLoggedIn) && (!this.userInfo.webEnabled))){
        body.classList.remove("loggedin");
        temp.style.display='none';
        }
      }
    }
    ngOnInit(){
      this.baseSiteService.getActive().subscribe((siteId) => {
        this.currentSite = siteId;
        console.log(this.currentSite);
      });

      this.fetchPIPdfData();

      this.userInfoService.userLoggedIn$.subscribe(res=>{
        this.isUserLoggedIn=res;
        if(this.isUserLoggedIn){
          this.userInfoService.getUserData().subscribe(res=>{
            if(res){
            this.userInfo=res;
            this.setDifferentTopHeader();
            this.setDifferentBottomHeader();
            }
          })
        }
        else{
        this.setDifferentTopHeader();
        this.cdr.detectChanges();
        }
      })
      
      this.node$.subscribe((res:any)=>{
        if(res){

          if(res.title=='For GB Healthcare Professionals Only'){
            
            
            this.navigation=res.children[0].children;
            this.Ukclass = true;
            
          }
          
          if(res.title=='For US Healthcare Professionals Only' && this.currentSite == 'seqirusb2b-flu360'){
            let a=res.children[0].children.find((x:any)=>x.title==='For US Healthcare Professionals Only');
            let b=res.children[0].children.find((x:any)=>x.title==='Prescribing Information');
            b.children[0].url = this.fluadurl;
            b.children[1].url = this.flucelvaxurl;
            b.children[2].url = this.afluriaurl;
            
            this.navigation=res.children[0].children;
            this.navigation.splice(this.navigation.findIndex((x:any)=>x.title==='For US Healthcare Professionals Only') , 1);
            this.navigation.unshift(a)
          }
          if(res.title=='Main Navigation Component' && this.currentSite == 'seqirusb2b-flu360'){
            let a=res.children[0].children.find((x:any)=>x.title==='Prescribing Information');
            a.children[0].url = this.fluadurl;
            a.children[1].url = this.flucelvaxurl;
            a.children[2].url = this.afluriaurl;
            this.mobnav = res.children[0].children;
            
          }
        }
      })

    
    }
    getImage(image: any): string {
      return this.config.backend?.occ?.baseUrl + image.url;
    }
    ngOnDestroy(){
      // this.subscription.forEach(sub=>{
      //   sub.unsubscribe();
      // })
    }
  }