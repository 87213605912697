import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BaseSiteService, CmsService, PageMeta, PageMetaConfig, PageMetaService, UnifiedInjector } from '@spartacus/core';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustompagemetatitleService extends PageMetaService {
  currentSite: string='';

  constructor(protected override cms: CmsService,
    protected override unifiedInjector: UnifiedInjector,
    protected override pageMetaConfig: PageMetaConfig,
    @Inject(PLATFORM_ID) protected override platformId: string,
    private baseSiteService:BaseSiteService){
    super(cms,unifiedInjector,pageMetaConfig,platformId);
  }

  public override getMeta():Observable<PageMeta>{ 

    return combineLatest([super.getMeta(),this.baseSiteService.getActive()]).pipe(
      map(([meta,baseSite]) => {
        let siteTitle="";
        if(baseSite == 'seqirusb2b-flu360'){
          siteTitle = 'CSL Seqirus flu360';
        }
        else if(baseSite == 'seqirusb2b-uk'){
          siteTitle = 'flu360 GB';
        }
        if(meta?.title){
          return {
          ...meta,
          title: `${meta?.title} | ${siteTitle}`,
          };
        }
        else{
          return{
            ...meta,title:`${siteTitle}`
          };
        }
      })
    );
  }

}


