<div class="adjuvantbasedvideo" id="adjuvantbasedvideo"*ngIf="node2 | async as item">
    <div class="container">
        <div class="videocontainer abovewidth">
            <div class="col-md-5 col-xs-12 no-padding">
                <div class="watchtext">{{item.headLine}}</div>
                <div class="watchsubtext" [innerHTML]="item.content"></div>
            </div>
            <div class="col-md-7 col-xs-12 no-padding">
                <div class="video-wrap">
                    <video autoplay muted playsinline poster="/_ui/responsive/theme-lambda/cms/assets/images/video-placeholder.png" controls="" 
                    title="Seqirus MF59® adjuvant flu vaccine technology">
                            <source [src]="getVideo(item.mediaVideo)" type="video/mp4"/>
                        </video>
                    </div>
</div>
</div>
</div>
</div>