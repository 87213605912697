import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { OccEndpointsService, AuthConfigService, CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { UserInfoService } from '../spartacus/services/user-info.service';
import { StartOrderService } from './start-order.service';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

export interface LocationDetails {
   "locQty"?: number,
   "locID": string
}
@Component({
   selector: 'app-custom-start-order',
   templateUrl: './custom-start-order.component.html',
   styleUrl: './custom-start-order.component.scss'
})
export class CustomStartOrderComponent {
   orderLists: any;
   membershipDecision: string = '';
   requestedMembership: any;
   fieldsetDisabled: boolean = true;
   showMembershipContent: boolean = false;
   noContent: any;
   notSureContent: any;
   yesContent: any;
   activeMembershipContent: any;
   pendingMembershipContent: any;
   poNumber: string = '';
   needHelpContent: Observable<any> = this.cmsService.getComponentData('SeqirusStartOrderUserGuidePDFComponent');
   uid: string = '';
   disableStartOrderBtn: boolean = true;
   showLocationContent: boolean = false;
   iframeUrl: any;
   displayedColumns: string[] = ['locID', 'state', 'address', 'orderExists'];
   dataSource: any;
   selection = new SelectionModel<any>(true, []);
   seasonValue: string = '';
   selectedProducts: any[] = [];
   showSelectedProducts: boolean = false;
   showProductList: boolean = false;
   units: string = '';
   shipLocationQty: LocationDetails[] = [];
   totalDoses: number = 0;
   totalAmount: any;
   totalquantity: number = 0;
   productForm: any = {
      productQty: []
   }
   showProductContent: boolean = false;
   showReviewContent: boolean = false;
   cartListData: any;
   termsCheckbox: boolean = false;
   mobileSeasonValue: any = 'Select New Order Type';
   showErrorMsg: boolean = false;
   showThankYouContent: boolean = false;
   duplicateSeletedArray: any[] = [];
   @ViewChild('closeButton') closeButton!: ElementRef;
   maxFileSize = 5 * 1024 * 1024;
   maxNumFilesError: boolean = false;
   isUploading: boolean = false;
   acceptedFiles = ['image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.eml', 'application/pdf', '.msg'];
   bulkOrderExcel: any;
   maxFileSizeError: boolean = false;
   showError1Msg: boolean = false;
   showBulkOrderSection: boolean = false;
   uploadData: string = '';
   orderSubmitContent: string = '';
   showSubmitButton: boolean = false;
   formData = new FormData();
   dupOrderLists: any;
   isLoading: boolean = false;
   progressValue: number = 0;
   showTryAgain: boolean = false;
   afluria: any;
   fileSize: any;
   bannerContent: any;
   termsAndConditionContent: any = "<a href='/terms-and-condition' target='_blank'>Terms and Conditions of Sale</a>";
   disablePlaceOrder: boolean = true;
   @ViewChild("poNumberField") poNumberField !: ElementRef;
   @ViewChild("termsCheckboxField") termsCheckboxField !: ElementRef;
   constructor(
      private cmsService: CmsService, private cdr: ChangeDetectorRef, public component: CmsComponentData<CmsBannerComponent>, private occEndpointsService: OccEndpointsService, private http: HttpClient, private userInfoService: UserInfoService, private authConfigService: AuthConfigService, private startOrderService: StartOrderService, protected config: OccConfig, private router: Router, private sanitizer: DomSanitizer, private occEndPointsService: OccEndpointsService) {
   }
   ngOnInit(): void {
      this.getOrderDetails();
      this.cmsService.getComponentData('seqirusStartOrderMemberShipSubmitno').subscribe(data => {
         this.noContent = data;
      })
      this.cmsService.getComponentData('seqirusStartOrderMemberShipSubmitNotSure').subscribe(data => {
         this.notSureContent = data;
      })
      this.cmsService.getComponentData('seqirusStartOrderMemberShipSubmitYes').subscribe(data => {
         this.yesContent = data;
      })
      this.cmsService.getComponentData('membership-messageComponentActive').subscribe(data => {
         this.activeMembershipContent = data;
      })
      this.cmsService.getComponentData('membership-messageComponentPending').subscribe(data => {
         this.pendingMembershipContent = data;
      })
      this.startOrderService.getAfluriaPrescribingInfoPdf().subscribe((response: any) => {
         const url = this.occEndPointsService.buildUrl(response);
         this.afluria = url.replace("occ/v2/seqirusb2b-flu360/", "");
         this.cdr.detectChanges();
      });
      this.cmsService.getComponentData('cartseasonbegincomponent').subscribe(data => {
         this.bannerContent = data;
      })
   }
   getOrderDetails() {
      this.isLoading = true;
      this.startOrderService.getOrderDetails().subscribe(data => {
         this.orderLists = data;
         this.dupOrderLists = { ...this.orderLists };
         this.orderLists.shippingData.forEach((item: any) => {
            item['value'] = '';
         })
         this.selection = new SelectionModel<any>(true, []);
         this.selectedProducts = [];
         this.dataSource = new MatTableDataSource(this.orderLists.shippingData);
         this.isLoading = false;
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('order error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         })
   }
   handleOnSeasonBtnClick(value: string) {

      // this.showMembershipContent = !(this.orderLists.membershipData.isMembershipDeclared);
      // this.showLocationContent = this.orderLists.membershipData.isMembershipDeclared;
      // this.cdr.detectChanges();
      this.handleOnClearSelection();
      this.seasonValue = value === 'mobile' ? this.mobileSeasonValue : value;
      this.mobileSeasonValue = this.mobileSeasonValue ? this.mobileSeasonValue : this.seasonValue;
      if (this.seasonValue == 'inSeason' || this.seasonValue == 'prebook') {
         this.isLoading = true;
         this.startOrderService.checkOrderExist(this.seasonValue).subscribe(data => {
            if (data.toLocaleLowerCase() === "yes") {
               this.getReviewData();
            } else {
               this.getSectionContent();
            }
         },
            (error) => {
               console.log('order api error: ', error);
               this.isLoading = false;
               this.cdr.detectChanges();
            })
      } else {
         this.showBulkOrderSection = true;
      }
   }
   getSectionContent() {
      if (this.cartListData && this.cartListData.length > 0) {
         this.showReviewContent = true;
         this.cdr.detectChanges();
      } else {
         if (this.orderLists.membershipData.isMembershipDeclared) {
            if (this.seasonValue === 'prebook' || this.seasonValue === 'inSeason') {
               if (!this.showReviewContent) {
                  this.showLocationContent = true;
                  this.showBulkOrderSection = false;
               }
            } else {
               this.showBulkOrderSection = true;
               this.showLocationContent = false;
            }
         } else {
            this.showMembershipContent = !this.orderLists.membershipData.isMembershipDeclared;
         }
         this.cdr.detectChanges();
      }
      this.isLoading = false;
      this.cdr.detectChanges();
   }
   handleOnFormSubmit(form: any, orderLists: any) {
      let memberFormData = {
         currentMembership: orderLists.membershipData.currentMembership,
         membershipStatus: '',
         isContractSigned: orderLists.membershipData.isContractSigned,
         requestedMembership: form.requestedMembership || '',
         membershipDecision: form.membershipDecision
      }
      this.isLoading = true;
      this.startOrderService.postMembershipDetails(memberFormData).subscribe(data => {
         this.fieldsetDisabled = false;
         this.isLoading = false;
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('membership api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   clearMembershipData() {
      this.membershipDecision = '';
   }
   handleOnStartOrderBtnClick() {
      this.showMembershipContent = false;
      this.showLocationContent = true;
   }
   applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
   }
   isAllSelected() {
      const rowSelected = this.selection.selected.length;
      const orderNotExistLocations = this.dataSource.data.filter((el: any) => !el.orderExists)
      const totalRows = orderNotExistLocations.length;
      return rowSelected === totalRows;
   }
   handleOnSelectAllBtnClick() {

      // if(rowSelected === totalRows) {

      // }
      // this.selection = new SelectionModel<any>(true, []);
      if (this.selection.selected.length > 0) {
         this.dataSource.data.forEach((row: any) => {
            // if (!row.orderExists) {
            if (!row.selected) {
               this.selection.toggle(row);
               row['selected'] = true;
               row['value'] = '';
               this.duplicateSeletedArray = [...this.duplicateSeletedArray, row];
            }
            // }
         });
      } else {
         this.dataSource.data.forEach((row: any) => {
            // if (!row.orderExists) {
            this.selection.toggle(row);
            row['value'] = "";
            row['selected'] = true;
            this.duplicateSeletedArray = [...this.duplicateSeletedArray, row];
            // }
         });
      }
   }
   handleOnRowClick(row: any) {
      // if (!row.orderExists) {
      row['value'] = "";
      row['selected'] = row.selected ? !row.selected : true;
      if (!row.selected) {
         // this.selection.toggle(row);
         this.selection.selected.forEach((el: any, index) => {
            if (el.locID === row.locID) {
               this.selection.selected.splice(index, 1);
            }
         })
         this.selectedProducts.forEach((el: any) => {
            el.location.forEach((data: any, index: any) => {
               if (data.locID === row.locID) {
                  el.location.splice(index, 1);
               }
            })
         })
         this.selection = new SelectionModel<any>(true, this.selection.selected);
         // this.selectedProducts.forEach((el:any, index) => {
         //    if(el.locID === row.locID) {
         //       this.selectedProducts.splice(index,1);
         //    }
         // })
         this.cdr.detectChanges();
      } else {
         this.selection.toggle(row);
      }
      if (this.duplicateSeletedArray.length === 0 && row.selected) {
         // if (this.duplicateSeletedArray.length === 0) {
         this.duplicateSeletedArray = [...this.duplicateSeletedArray, row];
      } else if (this.duplicateSeletedArray.length > 0) {
         // } else {   
         this.duplicateSeletedArray.forEach((el: any, index) => {
            if (el.locID === row.locID) {
               this.duplicateSeletedArray.splice(index, 1);
            }
         })
         // }
      }
   }
   handleOnSelectProductClick() {
      this.showLocationContent = false;
      this.showProductContent = true;
      this.isLoading = true;
      if (this.seasonValue === 'inSeason' && this.orderLists.inSeasonProducts.length > 0) {
         this.orderLists.inSeasonProducts.forEach((item: any) => {
            item['location'] = [];
            item['location'] = this.selection.selected.map(data => ({ ...data }));
            item['showAngleDownArrow'] = item['showAngleDownArrow'] ? item['showAngleDownArrow'] : false;
         })
      }
      if (this.seasonValue === 'prebook' && this.orderLists.preBookProducts.length > 0) {
         this.orderLists.preBookProducts.forEach((item: any) => {
            item['location'] = [];
            item['location'] = this.selection.selected.map(data => ({ ...data }));
            item['showAngleDownArrow'] = item['showAngleDownArrow'] ? item['showAngleDownArrow'] : false;
         })
      }
      if (this.selectedProducts && this.selectedProducts.length > 0) {
         this.selectedProducts.forEach((prod: any) => {
            this.duplicateSeletedArray && this.duplicateSeletedArray.forEach((el: any) => {
               const obj = {
                  "address": el.address,
                  "checked": el.checked,
                  "locID": el.locID,
                  "locname": el.locname,
                  "orderExists": el.orderExists,
                  "state": el.state,
                  "selected": 'true',
                  "value": ""
               }
               prod.location = [...prod.location, obj]
            })
         })
      }
      this.isLoading = false;
      this.cdr.detectChanges();

   }
   handleOnAddClick(item: any, index: number) {
      this.showSelectedProducts = true;
      // this.selectedProducts && this.selectedProducts.forEach((el: any) => {
      //    el.showAngleDownArrow = true;
      // })
      item['totalquantity'] = this.totalquantity;
      item['totalDoses'] = this.totalDoses;
      item['totalAmount'] = this.totalAmount;
      item['location'] = this.selection.selected.map(data => ({ ...data }));
      item['showAngleDownArrow'] = false;
      // item['location'] = [...this.selection.selected];
      this.seasonValue === 'inSeason' ? this.orderLists.inSeasonProducts.splice(index, 1) : this.orderLists.preBookProducts.splice(index, 1);
      this.selectedProducts = [...this.selectedProducts, item];
      // this.productForm.productQty = [];

      // this.selectedProducts.forEach(item => {
      //    let shippingData: LocationDetails[] = [];
      //    item.location && item.location.forEach((data: any) => {
      //       shippingData.push({ locID: data.locID, locQty: data.value })
      //    })
      //    this.productForm.productQty.push({ productCode: item.code, shipLocationQty: shippingData });
      // })
   }
   handleOnToggle(item1: any) {
      // this.showAngleDownArrow = !this.showAngleDownArrow;
      item1['showAngleDownArrow'] = !item1['showAngleDownArrow'];
      this.cdr.detectChanges();
   }
   handleOnDeleteBtnClick(item: any, index: number) {
      item.totalAmount = '';
      item.totalAmount = 0;
      item.totalquantity = ''
      item.location.forEach((data: any) => {
         data.value = ""
      })
      // this.productForm.productQty = this.productForm.productQty.filter((data: any) => data.productCode !== item.code)
      this.selectedProducts.splice(index, 1);
      this.orderLists.inSeasonProducts = this.seasonValue === 'inSeason' ? [...this.orderLists.inSeasonProducts, item] : [...this.orderLists.preBookProducts, item];
      if (this.selectedProducts.length == 0) {
         this.showSelectedProducts = false;
      }
   }
   handleOnAddProductBtnClick() {
      this.showProductList = !this.showProductList;
   }
   handleOnUnitChange(prodValue: number, productIndex: number, locationIndex: number) {
      const value = Number(prodValue);
      const sum = this.selectedProducts[productIndex].location.reduce((accumulator: any, current: any) => accumulator + Number(current.value), 0);
      this.selectedProducts[productIndex].totalDoses = sum ? sum * this.selectedProducts[productIndex].doses : 0;
      this.selectedProducts[productIndex].totalAmount = new Intl.NumberFormat("en",
         { style: 'currency', currency: this.selectedProducts[productIndex].priceData.currencyIso, minimumFractionDigits: 2 }
      ).format(sum * this.selectedProducts[productIndex].priceData.value);
      this.selectedProducts[productIndex].totalquantity = sum ? sum : 0;
      this.productForm.productQty.forEach((product: any) => {
         if (product.productCode === this.selectedProducts[productIndex].code) {
            product.shipLocationQty[locationIndex]['locQty'] = value;
         }
      })
   }
   handleOnPlaceOrderBtnClick() {
      this.isLoading = true;
      this.duplicateSeletedArray = [];
      this.productForm.productQty = [];
      this.selectedProducts.forEach(item => {
         let shippingData: LocationDetails[] = [];
         item.location && item.location.forEach((data: any) => {
            shippingData.push({ locID: data.locID, locQty: data.value })
         })
         this.productForm.productQty.push({ productCode: item.code, shipLocationQty: shippingData });
      })
      this.startOrderService.addToCart(this.productForm, this.seasonValue).subscribe((data: any) => {
         this.getReviewData();
      },
         (error) => {
            console.log('add to cart api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         })
   }
   getReviewData() {
      this.startOrderService.getReviewPageData(this.seasonValue).subscribe((reviewData: any) => {
         this.cartListData = reviewData;
         this.cdr.detectChanges();
         this.startOrderService.getIframeUrl(this.seasonValue).subscribe((iframe: any) => {
            let url = this.occEndPointsService.buildUrl(`${iframe}`); //&embedded=true
            url = url.replace("occ/v2/seqirusb2b-flu360/", "");
            this.iframeUrl = url;
            if (!this.showReviewContent && this.showProductContent) {
               this.showReviewContent = true;
               this.showProductContent = false;
            }
            else {
               this.showReviewContent = true;
               const selectedProdListArray = this.cartListData.products.filter((item: any) => {
                  const prodNameArray = item.productCode.split(',');
                  if (this.seasonValue === 'inSeason') {
                     return this.orderLists.inSeasonProducts.find((el: any) => {
                        if (prodNameArray.includes(el.code)) { return el };
                     })
                  } else {
                     return this.orderLists.preBookProducts.find((el: any) => {
                        if (prodNameArray.includes(el.code)) { return el };
                     })
                  }

               })
            }
            // this.showProductContent = false;
            // this.showReviewContent = true;

            this.isLoading = false;
            this.cdr.detectChanges();
         },
            (error) => {
               console.log('iframe api error: ', error);
               this.isLoading = false;
               this.cdr.detectChanges();
            })
      },
         (error) => {
            console.log('review api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   handleOnBackToLocationBtnClick() {
      this.duplicateSeletedArray = [];
      this.selection.selected.forEach((row: any) => {
         this.dataSource.data.find((el: any) => {
            if (row.locID === el.locID) {
               el['selected'] = true;
            }
         })
      })

      this.showLocationContent = true;
      this.showProductContent = false;
   }
   getImage(image: any): string {
      return this.config.backend?.occ?.baseUrl + image.url;
   }
   handleOnBackToProductBtnClick() {
      this.showReviewContent = false;
      this.showProductContent = true;
      if (this.seasonValue === 'inSeason') {
         this.orderLists.inSeasonProducts.forEach((item: any, index: number) => {
            this.cartListData.data.find((el: any) => {
               if (el.productCode === item.code) {
                  let locationArray: any[] = [];
                  item['totalquantity'] = el.totalquantity;
                  item['totalDoses'] = (Number(el.totalquantity) * item.doses).toString();
                  item['totalAmount'] = el.totalcost.includes('$') ? el.totalcost : new Intl.NumberFormat("en",
                     { style: 'currency', currency: "USD", minimumFractionDigits: 2 }
                  ).format(el.totalcost);
                  item['showAngleDownArrow'] = false;

                  el.locations.forEach((loc: any) => {
                     this.orderLists.shippingData.find((location: any) => {
                        if (location.locID === loc.locID) {
                           const obj = {
                              "address": location.address,
                              "checked": location.checked,
                              "locID": location.locID,
                              "locname": location.locname,
                              "orderExists": location.orderExists,
                              "state": location.state,
                              "value": loc.locqtn,

                           }
                           locationArray = [...locationArray, obj];

                        }
                     })
                  })
                  if (item.location) {
                     item.location.length = 0;
                     item.location = locationArray;
                  } else {
                     item['location'] = locationArray;
                  }
                  this.selectedProducts = [...this.selectedProducts, item];

                  // this.selectedProducts.forEach((el: any) => {
                  //    this.selection.select(el)
                  // })

                  this.orderLists.inSeasonProducts.splice(index, 1);
               }
            })
         })
      } else {
         this.orderLists.preBookProducts.forEach((item: any, index: number) => {
            this.cartListData.data.find((el: any) => {
               if (el.productCode === item.code) {
                  let locationArray: any[] = [];
                  item['totalquantity'] = el.totalquantity;
                  item['totalDoses'] = (Number(el.totalquantity) * item.doses).toString();
                  item['totalAmount'] = el.totalcost;
                  item['showAngleDownArrow'] = false;

                  el.locations.forEach((loc: any) => {
                     this.orderLists.shippingData.find((location: any) => {
                        if (location.locID === loc.locID) {
                           const obj = {
                              "address": location.address,
                              "checked": location.checked,
                              "locID": location.locID,
                              "locname": location.locname,
                              "orderExists": location.orderExists,
                              "state": location.state,
                              "value": loc.locqtn,

                           }
                           locationArray = [...locationArray, obj];

                        }
                     })
                  })
                  if (item.location) {
                     item.location.length = 0;
                     item.location = locationArray;
                  } else {
                     item['location'] = locationArray;
                  }
                  this.selectedProducts = [...this.selectedProducts, item];

                  // this.selectedProducts.forEach((el: any) => {
                  //    this.selection.select(el)
                  // })

                  this.orderLists.preBookProducts.splice(index, 1);
               }
            })
         })
      }
      if (this.selectedProducts && this.selectedProducts.length > 0) {
         this.selection = new SelectionModel<any>(true, []);
         this.selectedProducts[0].location.forEach((el: any) => {
            this.selection.toggle({
               "address": el.address,
               "checked": el.checked,
               "locID": el.locID,
               "locname": el.locname,
               "orderExists": el.orderExists,
               "state": el.state,
               "selected": 'true'
            });
         })

         this.showSelectedProducts = true;

      }

   }
   handleOnDeleteModelYesBtnClick() {
      this.isLoading = true;
      this.startOrderService.deleteCartData(this.seasonValue).subscribe(data => {
         this.showReviewContent = false;
         this.getOrderDetails();
         this.closeButton.nativeElement.click();
         this.isLoading = false;
         this.seasonValue = '';
         this.cartListData = [];
         this.selection = new SelectionModel<any>(true, []);
         this.duplicateSeletedArray = [];
         this.selectedProducts = [];
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('delete api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   handleOnPlaceOrder() {
      this.isLoading = true;
      this.startOrderService.placeOrderAPI(this.poNumber, this.seasonValue).subscribe(data => {
         if (data) {
            this.showThankYouContent = true;
            this.showReviewContent = false;
            this.cdr.detectChanges();
         }
         this.isLoading = false;
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('place order api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   handleOnAutoGeneratePoNo() {
      this.startOrderService.getPORefNumber().subscribe(data => {
         this.poNumber = data; //Su1021202488
         this.cdr.detectChanges();
      });
   }
   handleOnViewOrders() {
      this.router.navigate(['/orders/my-orders']);
   }
   cleanURL(): SafeResourceUrl {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl + `#toolbar=0&navpanes=0&view=FitH`);
   }
   filesValidator(control: any) {
      const files = control.value;
      let totalSize = 0;
      // if (files && files.length > this.maxFiles) {
      //    return { maxFiles: true };
      // }
      for (const file of files) {
         totalSize += file.size;
      }
      if (totalSize > this.maxFileSize) {
         return { maxFileSize: true };
      }
      return null;
   }
   onFileChange(event: any) {
      // this.progress = 1;
      this.isUploading = true;
      const fileList: FileList = event.target.files;
      if (fileList.length > 0) {
         const file: File = fileList[0];
         this.bulkOrderExcel = file;
         if (this.bulkOrderExcel.size < 1000000) {
            this.fileSize = Math.floor(this.bulkOrderExcel.size / 1000) + 'KB';
         } else {
            this.fileSize = Math.floor(this.bulkOrderExcel.size / 1000000) + 'MB';
         }
      }
      if (this.bulkOrderExcel.size > this.maxFileSize) {
         this.showError1Msg = true;
      } else {
         this.formData.append('bulkOrderExcel', this.bulkOrderExcel, this.bulkOrderExcel.name);
         this.startOrderService.uploadFile(this.formData).subscribe((data: any) => {
            if (data.type == 0) {
               this.progressValue = 0;
               this.cdr.detectChanges();
               this.progressValue = this.progressValue + 10;
               this.cdr.detectChanges();
            }
            if (data.type == 2) {
               this.progressValue = 40;
               this.cdr.detectChanges();
            }
            if (data.type == 3) {
               this.progressValue = 70;
               this.cdr.detectChanges();
            }
            if (data['body']) {
               this.progressValue = 100;
               this.cdr.detectChanges();
               setTimeout(() => {
                  this.uploadData = 'success';
                  this.cdr.detectChanges();
               }, 2000);

            }
            // this.isUploading = false;
         })
      }
      //    this.addFiles( event.target.files[0]);

   }
   addFiles(file: any) {

      // for (const file of this.filesToUpload) {

      // }
      if (file.size > this.maxFileSize) {
         this.maxNumFilesError = true;
         // this.filesToUpload = file;
         // this.filesToUpload.push(...files);
      }


      else {
         // this.filesToUpload = file;
         // this.filesToUpload.push(...file);
      }

      this.isUploading = false;
      this.cdr.detectChanges();
   }
   onDragOver(event: DragEvent) {
      event.preventDefault();
   }
   onDragLeave(event: DragEvent) {
      event.preventDefault();
   }
   onDrop(event: DragEvent) {
      this.isUploading = true;
      event.preventDefault();
      if (event.dataTransfer?.files) {
         const files = Array.from(event.dataTransfer.files) as File[];
         if (files.length > 0) {
            const file: File = files[0];
            this.bulkOrderExcel = file;
         }
         // this.filesToUpload = event.target.files[0]

         if (this.bulkOrderExcel.size > this.maxFileSize) {
            this.showError1Msg = true;
         } else {
            this.formData.append('bulkOrderExcel', this.bulkOrderExcel, this.bulkOrderExcel.name);

            this.startOrderService.uploadFile(this.formData).subscribe((data: any) => {
               if (data.type == 0) {
                  this.progressValue = 0;
                  this.cdr.detectChanges();
                  this.progressValue = this.progressValue + 10;
                  this.cdr.detectChanges();
               }
               if (data.type == 2) {
                  this.progressValue = 40;
                  this.cdr.detectChanges();
               }
               if (data.type == 3) {
                  this.progressValue = 70;
                  this.cdr.detectChanges();
               }
               if (data['body']) {
                  this.progressValue = 100;
                  this.cdr.detectChanges();
                  setTimeout(() => {
                     this.uploadData = 'success';
                     this.cdr.detectChanges();
                  }, 2000);

               }
               // this.isUploading = false;
            })
            this.cdr.detectChanges();
         }
      }
   }
   removeFile() {
      this.bulkOrderExcel = '';
      this.maxNumFilesError = false;
      this.maxFileSizeError = false;
      this.isUploading = false;
      this.uploadData = '';
      this.showTryAgain = false;
   }
   handleOnBulkOrderSeasonBtnClick(season: string) {
      this.isLoading = true;
      this.startOrderService.getBulkOrderSeasonDocument(season)
         .subscribe(
            (data: any) => {
               this.isLoading = false;
               this.cdr.detectChanges();
               const contentDisposition = data.headers.get('Content-disposition').split('filename=')[1];
               const file = new Blob([data.body]);
               const fileURL = URL.createObjectURL(file);
               const a = document.createElement('a');
               a.href = fileURL;
               a.download = contentDisposition.replace(/["]+/g, '').trimStart();
               document.body.appendChild(a);
               a.click();
            },
            (error) => {
               console.log('download pdf error: ', error);
               this.isLoading = false;
               this.cdr.detectChanges();
            }
         );


   }
   handleOnTryAgainBtnClick() {
      this.isUploading = false;
      this.showError1Msg = false;
      this.orderSubmitContent = "";
      this.uploadData = "";
      this.progressValue = 0;
      this.showTryAgain = false;
   }
   handleOnUploadSubmit() {
      this.isLoading = true;
      this.startOrderService.submitFile(this.formData).subscribe((data: any) => {
         this.orderSubmitContent = data;
         this.isLoading = false;
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('upload file error: ', error);
            this.orderSubmitContent = 'success';
            this.isLoading = false;
            this.cdr.detectChanges();
         })
   }
   handleOnClearSelection() {
      this.selection.clear();
      this.duplicateSeletedArray = [];
      this.selectedProducts = [];
      this.showBulkOrderSection = false;
      this.showMembershipContent = false;
      this.showLocationContent = false;
      this.showProductContent = false;
      this.showProductList = false;
      this.showReviewContent = false;
      this.showThankYouContent = false;
      // this.selectedProducts.forEach((data: any) => {
      //    data.location = [];
      //    data['totalAmount'] = '';
      //    data['totalDoses'] = '';
      //    data['totalquantity'] = '0';
      // })
      this.orderLists.shippingData.forEach((el: any) => {
         el.selected = false;
      })
      this.cdr.detectChanges();
   }
   handleOnToggle1(item: any) {
      item['showAngleDownArrow'] = item['showAngleDownArrow'] ? !item['showAngleDownArrow'] : false;
      this.cdr.detectChanges();
   }
   handleOnReorderClick() {
      this.isLoading = true;
      this.startOrderService.preBookRollOver().subscribe((data: any) => {
         if (data == 'success') {
            this.seasonValue = 'prebook';
            this.cdr.detectChanges();
            this.getReviewData();
         }
      }, (error) => {
         console.log('upload file error: ', error);
         this.isLoading = false;
         this.cdr.detectChanges();
      })
   }
   handleMyOrdersLink(address: string) {
      this.router.navigate(
         ['/orders/my-orders'],
         { queryParams: { season: this.seasonValue == 'inSeason' ? '2024-2025' : '2025-2026', address: address } }
      );
   }
   handleOnLinkClick(event: any) {
      event.preventDefault();
      window.open(this.iframeUrl, '_blank');
      // this.router.navigate(['/orders/my-orders']);
   }
   scrollToTop(): void {
      window.scrollTo({ top: 0, behavior: 'smooth' });
   }
   focusOnInputField() {
      if (!this.poNumber) {
         this.poNumberField.nativeElement.focus();
         this.showErrorMsg = true;
      }
      if (!this.termsCheckbox) {
         this.termsCheckboxField.nativeElement.focus();
         this.showErrorMsg = true;
      }
      if (this.poNumber && this.termsCheckbox) {
         this.showErrorMsg = false;
         this.handleOnPlaceOrder();
         this.scrollToTop();
      }
   }
   disablePlaceOrderBtn() {
      if (this.selectedProducts.length > 0) {
         const productArray = this.selectedProducts.find((el: any) => { return el.totalquantity == 0 });
         return productArray != undefined;
      } else {
         return true
      }
   }
}