import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
 @Component({
  selector: 'app-about-flu-world',
  templateUrl: './about-flu-world.component.html',
  styleUrl: './about-flu-world.component.scss'
})
 
export class AboutFluWorldComponent {
  leftBannerNode: Observable<any> = this.bannerComponent.data$;
  activeIndex1: number = 0;
  transitioning: boolean = false;
  mergedObject1: any[] = [];
  totalSlides: number = this.mergedObject1.length;
  constructor(
    private cmsService: CmsService,
    protected config: OccConfig,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    public accessService: ContentService,
    public cd: ChangeDetectorRef
  ) {}
  ngOnInit() {
   this.leftBannerNode.subscribe(res=>{
    console.log('Received datafinal:', res);
    const tilesList = res.featuredCarouselOptions.split(" ")
    tilesList.forEach((el:any) => {this.accessService.getcarouselslides(el).subscribe(res=>{
      this.mergedObject1 = [...this.mergedObject1, res]
      this.cd.detectChanges();
      console.log(this.mergedObject1);
     })})
   })
}
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  goprevious() {
    if (this.transitioning) return;
    this.transitioning = true;
    const fromIndex = this.activeIndex1;
    this.activeIndex1 = (this.activeIndex1 > 0) ? this.activeIndex1 - 1 : this.mergedObject1.length - 1;
    this.triggerSlideAnimationNum(fromIndex, this.activeIndex1, 'prev');
  }
 
  gonext() {
    if (this.transitioning) return;
    this.transitioning = true;
    const fromIndex = this.activeIndex1;
    this.activeIndex1 = (this.activeIndex1 < this.mergedObject1.length - 1) ? this.activeIndex1 + 1 : 0;
    this.triggerSlideAnimationNum(fromIndex, this.activeIndex1, 'next');
  }
 
  triggerSlideAnimationNum(fromIndex: number, toIndex: number, direction: 'next' | 'prev') {
    const fromSlide = document.querySelectorAll('.carousel-item1')[fromIndex] as HTMLElement;
    const toSlide = document.querySelectorAll('.carousel-item1')[toIndex] as HTMLElement;
 
    if (fromSlide && toSlide) {
      // Apply outgoing and incoming classes for sliding effect
      toSlide.classList.add(`carousel-item1-${direction}-enter`,'carousel-item1-${direction}');
      fromSlide.classList.add(`carousel-item1-${direction}-exit`, 'carousel-item1-${direction}');
 
      setTimeout(() => {
        toSlide.classList.add('active');
        toSlide.classList.remove(`carousel-item1-${direction}-enter`,'carousel-item1-${direction}');
        fromSlide.classList.remove('active', `carousel-item1-${direction}-exit`,'carousel-item1-${direction}');
        this.transitioning = false;
      },10);
      // Match the transition duration with CSS
    }
 
  }
  setActiveIndex1(index: number){
    if(index!==this.activeIndex1){
      const fromIndex = this.activeIndex1;
      this.activeIndex1=index;
      const direction = index> fromIndex ? 'next'  : 'prev';
      this.triggerSlideAnimationNum(fromIndex,index,direction);
    }
    // this.activeIndex = index;
  }
 
}