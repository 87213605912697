import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstAnimationComponent } from './first-animation/first-animation.component';
// import { SecondBannerComponent } from './second-banner/second-banner.component';
import { ThirdNavbarComponent } from './third-navbar/third-navbar.component';
import { FourthComponent } from './fourth/fourth.component';
import { FlucelFifthComponent } from './flucel-fifth/flucel-fifth.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';

@NgModule({
  declarations: [
    FirstAnimationComponent,
    // SecondBannerComponent,
    ThirdNavbarComponent,
    FourthComponent,
    FlucelFifthComponent,
    // SixthChoosecompComponent,
   
  
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusProductVideoBannerComponent:{
        component:FirstAnimationComponent
      },
      // SeqirusProductNavigationBanner:{
      //   component:SecondBannerComponent
      // },
      SeqirusProductTimeEliminateComponent:{
      component:ThirdNavbarComponent
    },
    SeqirusFlucelvaxVaccineSubBannerComponent:{
      component:FourthComponent
    },
    SeqirusProductSubPageComponent:{
      component:FlucelFifthComponent
    },
 
  }
    }as CmsConfig),
  
  ]
})
export class FlucelvaxModule { }