import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-first-animation',
  templateUrl: './first-animation.component.html',
  styleUrl: './first-animation.component.scss'
})
export class FirstAnimationComponent {
  node2: Observable<any> = this.component.data$;
  
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
}
  // getVideo(video: any): string {
  // }
}
