import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';


@Component({
  selector: 'app-locationpage',
  templateUrl: './locationpage.component.html',
  styleUrl: './locationpage.component.scss'
})
export class LocationpageComponent {
  displayedColumns: string[] = ['checkbox', 'locname', 'state', 'address', 'orderPlaced'];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  seasonValue: string = '';
  shippingLocations: any;
  constructor(public component: CmsComponentData<CmsBannerComponent>, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService) {

  }
  locationData: Observable<any> = this.component.data$;
  exitPopupData: any;
  ngOnInit() {
    this.startOrderService.getUkShippingLocations('2024-2025').subscribe((data: any) => {
      this.shippingLocations = data.shippingData.filter((el:any) => {
        if(el.rbaPermission === 'manage') {
          return el;
        }
      })
      this.shippingLocations.forEach((el: any) => {
        el.checked = el.checked == "true" ? true : false;
        if(el.checked) {
          this.selection.toggle(el);
        }
      })
      this.dataSource = new MatTableDataSource(this.shippingLocations);
      this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
        return data.locname.toLowerCase().includes(filter) || data.address.toLowerCase().includes(filter) || data.state.toString() === filter;
      };
      this.cdr.detectChanges();
    })
    // const data = [{
    //   "address": "1230 BAY DALE DR",
    //   "checked": "",
    //   "locID": "0060054154",
    //   "locname": "Rediclinic #48489",
    //   "rbaPermission": "manage",
    //   "state": "ARNOLD, MD  21012"
    // }, {
    //   "address": "8701 GEORGIA AVE STE 100",
    //   "checked": "",
    //   "locID": "0060054166",
    //   "locname": "Rediclinic #46761",
    //   "rbaPermission": "manage",
    //   "state": "SILVER SPRING, MD  20910"
    // }];
  }

  handleOnRowClick(row: any) {
    row['checked'] = !row['checked'];
    this.selection.toggle(row);
    this.cdr.detectChanges;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handleOnSelectAllBtnClick() {
    this.dataSource.data.forEach((row: any) => {
      if (row['checked'] == false) {
        row['checked'] = true;
        this.selection.toggle(row);
      }
    })
  }
  handleOnClearSelection() {
    this.dataSource.data.forEach((row: any) => {
      row['checked'] = false;
    })
    this.selection.clear();
  }
  handleOnOrderLinkClick() {
    this.router.navigate(
      ['/orders/my-orders'],
      { queryParams: { season: this.seasonValue } }
    );
  }
  handleOnExitClick() {
    this.userInfoService.setPopUpValue(true);
  }
}
