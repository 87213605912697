import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { catchError, combineLatest, concatMap, forkJoin, from, map, mergeMap, Observable, of, switchMap, toArray } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { HelpnfaqsearchPipe } from '../../cms-common/Pipes/helpnfaqsearch.pipe';


@Component({
  selector: 'app-help-faq-second',
  templateUrl: './help-faq-second.component.html',
  styleUrl: './help-faq-second.component.scss'
})
export class HelpFaqSecondComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>, public accessService: ContentService, public cd: ChangeDetectorRef) { }
  secondnode: Observable<any> = this.component.data$;
  activeIndexes: Set<string> = new Set();
  activeAccordion: number | null = null;
  mergedObject1: any = [];
  mergedObject2: any = [];
  filteredData: any[] = [];
 seallbtn:boolean = false;
 searchvalue :string=''; 
 searchresults:boolean = false;
 shownoresult:boolean =false;

//  filterValues = {
//   search: '',
//   filterbyproduct:[],
//   filterbystatus:[],
// };

  ngOnInit() {
    this.secondnode.subscribe(res => {
      this.loadFaqData(res);
    });
  }
  // inputsearch(event:Event){
  //   const filterValue = (event.target as HTMLInputElement).value;
  // console.log('my filtervalue:',filterValue);
  //   this.searchvalue =filterValue.trim().toLowerCase(); 
  // }

  //all the data present in the tiles and sub-tiles iam getting here
  // loadFaqData(res: any) {
  //   const tilesList = res.FaqAllResources.split(" ");
  //   tilesList.forEach((el: any) => {
  //     this.accessService.opeffeaturedResources(el).subscribe((result: any) => {
  //       this.mergedObject1 = [...this.mergedObject1, result];
  //       const subtilesList = result.faqTopics.split(" ");
  //       subtilesList.forEach((el: any) => {
  //         this.accessService.opeffeaturedResources(el).subscribe((result2: any) => {
  //           result2.parentId = result.uid;
  //           this.mergedObject2 = [...this.mergedObject2, result2];
  //           this.cd.detectChanges();
  //           console.log('The result of tiles is',this.mergedObject1);
  //           console.log('The result of sub tiles is', this.mergedObject2);
  //         });
  //       });
  //     });
  //   });
  // }
  loadFaqData(res: any) {
    const tilesList = res.FaqAllResources.split(" ");
    const tileObservables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  
    combineLatest(tileObservables).pipe(
      switchMap((results:any) => {
        this.mergedObject1 = results;
        const subtilesObservables = results.flatMap((result:any) => {
          const subtilesList = result.faqTopics.split(" ");
          return subtilesList.map((el: any) => this.accessService.opeffeaturedResources(el).pipe(
            map(result2 => ({ ...result2, parentId: result.uid }))
          ));
        });
        return combineLatest(subtilesObservables);
      })
    ).subscribe(results2 => {
      this.mergedObject2 = results2;
      this.cd.detectChanges();
      console.log('The result of tiles is', this.mergedObject1);
      console.log('The result of sub tiles is', this.mergedObject2);
    });
  }
  //when user searches something the following method will be useful for showing relavant searches
  applyFilter(mysearchvalue:any) { 
    console.log(this.mergedObject2)
    let final =mysearchvalue.trim().toLowerCase(); 
    console.log(mysearchvalue);
    // this.searchvalue = final;
    console.log('my final is',final);
    const filteredData1 = new HelpnfaqsearchPipe().transform(this.mergedObject2, final);
    if(this.filteredData){
      this.searchresults = true;
    }
    //when the search doesn't match or the user gives empty the no result will be opened
    if(final != " " && filteredData1.length<1){
  this.shownoresult=true;
    }
    else{
      this.shownoresult=false;
    }
    //iam assigning the filteredData1 to one variable so that i can use in html
    this.filteredData = filteredData1;
    console.log(filteredData1);
    console.log(filteredData1);
    return filteredData1
    // this.mergedObject2 = filteredData1;
 }
//the following is to show all the accordions initally
  toggleDropdown(i: number, j: number) {
    const key = `${i}-${j}`;
    if (this.activeIndexes.has(key)) {
      this.activeIndexes.delete(key);
    } else {
      this.activeIndexes.add(key);
    }
  } isActive(i: number, j: number): boolean 
  {
    const key = `${i}-${j}`;
    return this.activeIndexes.has(key);
  }
//to show only the particualr accordion clicked by user
  showAccordion(index: number) {
    this.activeAccordion = index;
    this.mergedObject1 = [];
    this.mergedObject2 = [];
    // this.isLoading = true;
    this.secondnode.subscribe(res => {
      const tilesList = res.FaqAllResources.split(" ");
      const selectedTile = tilesList[index];

      this.accessService.opeffeaturedResources(selectedTile).subscribe((result: any) => {
        this.mergedObject1 = [result];
        const subtilesList = result.faqTopics.split(" ");
        subtilesList.forEach((el: any) => {
          this.accessService.opeffeaturedResources(el).subscribe((result2: any) => {
            result2.parentId = result.uid;
            this.mergedObject2.push(result2);
            this.cd.detectChanges();
          });
        });
        this.seallbtn = true;
        // this.isLoading = false;
        this.cd.detectChanges();
      });
    });
  }
  //when clicking on SEE ALL TOPICS button to make every accordion appear again
  sealltopics(){
    this.mergedObject1 = [];
    this.secondnode.subscribe(res => {
      this.loadFaqData(res);
    });
    this.seallbtn = false;
    this.cd.detectChanges();
  }
  //i wrote this for to show the results in accordions 
  toggleAccordion(item: any) {
    item.isOpen = !item.isOpen;
  }
}





















































































































// // activeIndexes: Set<string> = new Set();
// // toggleDropdown(i: number, j: number) {     const key = `${i}-${j}`;     if (this.activeIndexes.has(key)) {       this.activeIndexes.delete(key);     } else {       this.activeIndexes.add(key);     }   }   isActive(i: number, j: number): boolean {     const key = `${i}-${j}`;     return this.activeIndexes.has(key);   }
// // activeIndexes: Set<number> = new Set();   // Existing methods...
// // toggleAccordion(index: number) { if (this.activeIndexes.has(index)) { this.activeIndexes.delete(index); } else { this.activeIndexes.add(index); } } isActive(index: number): boolean { return this.activeIndexes.has(index); }

// import { ChangeDetectorRef, Component } from '@angular/core';
// import { CmsBannerComponent } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// import { ContentService } from '../../spartacus/services/content.service';

// @Component({
//   selector: 'app-help-faq-second',
//   templateUrl: './help-faq-second.component.html',
//   styleUrls: ['./help-faq-second.component.scss']
// })
// export class HelpFaqSecondComponent {
//   constructor(public component: CmsComponentData<CmsBannerComponent>, public accessService: ContentService, public cd: ChangeDetectorRef) { }
  
//   secondnode: Observable<any> = this.component.data$;
//   activeAccordion: number | null = null;
//   mergedObject1: any = [];
//   mergedObject2: any = [];
//   activeIndexes: Set<string> = new Set();

//   ngOnInit() {
//     this.secondnode.subscribe(res => {
//       console.log('Received datafinal:', res);
//       const tilesList = res.FaqAllResources.split(" ");
//       tilesList.forEach((el: any) => {
//         this.accessService.opeffeaturedResources(el).subscribe((result: any) => {
//           this.mergedObject1 = [...this.mergedObject1, result];
//           const subtilesList = result.faqTopics.split(" ");
//           subtilesList.forEach((el: any) => {
//             this.accessService.opeffeaturedResources(el).subscribe((result2: any) => {
//               result2.parentId = result.uid;
//               this.mergedObject2 = [...this.mergedObject2, result2];
//               this.cd.detectChanges();
//               console.log(this.mergedObject1);
//               console.log('The result of sub tiles is', this.mergedObject2);
//             });
//           });
//         });
//       });
//     });
//   }

//   showAccordion(index: number) {
//     this.activeAccordion = this.activeAccordion === index ? null : index;
//     this.cd.detectChanges();
//   }

//   toggleDropdown(i: number, j: number) {
//     const key = `${i}-${j}`;
//     if (this.activeIndexes.has(key)) {
//       this.activeIndexes.delete(key);
//     } else {
//       this.activeIndexes.add(key);
//     }
//   }

//   isActive(i: number, j: number): boolean {
//     const key = `${i}-${j}`;
//     return this.activeIndexes.has(key);
//   }
// }
