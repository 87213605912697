import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { combineLatest, map, Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, CmsService } from '@spartacus/core';

@Component({
  selector: 'app-topnavigation',
  templateUrl: './topnavigation.component.html',
  styleUrl: './topnavigation.component.scss'
})
export class TopnavigationComponent {
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, private cmsService: CmsService, private cd: ChangeDetectorRef) { }
topheadline: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  // ngOnInit() {
  //   this.secondheadline.subscribe(res => {
  //     console.log('Received datafinal:', res);
  //     const tilesList = res.productNavList.split(" ")
  //     tilesList.forEach((el: any) => {
  //       this.accessService.opeffeaturedResources(el).subscribe(res => {
  //         this.mergedObject1 = [...this.mergedObject1, res]
  //         this.cd.detectChanges();
  //         console.log(this.mergedObject1);
  //       })
  //     })
  //   })
  // }
  ngOnInit() {
    this.topheadline.subscribe(res => {
      this.navtabs(res);
    });
  }

  navtabs(res: any) {
    const tilesList = res.productNavList.split(" ");
    const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

    combineLatest(observables).pipe(
      map((results:any) => results.flat())
    ).subscribe((mergedResults: any) => {
      this.mergedObject1 = mergedResults;
      this.cd.detectChanges();
      console.log(this.mergedObject1);
    });
  }
}
