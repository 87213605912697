import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-orders',
  templateUrl: './new-orders.component.html',
  styleUrl: './new-orders.component.scss'
})
export class NewOrdersComponent implements OnInit {
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService) {}
    bannerNode: Observable<any> = this.component.data$;
    seasonData :Observable<any> =  this.userInfoService.getseasonDropdown("2024-2025");
    orderSummary : Observable<any>= this.userInfoService.getOrderSummary();
    percDelivered:any;
    percInTransit:any;
    percProcessed:any;
    productsArray:any;
  ngOnInit(): void {
    
  }
   
  getProductsArray1(data:any){
     return data.products.filter((res:any) =>res.baseName == 'Flucelvax');
  }
  getProductsArray2(data:any){
    return data.products.filter((res:any) =>res.baseName == 'Fluad');
  }
  getProductsArray3(data:any){          
    return data.products.filter((res:any) =>res.baseName == 'Afluria');
  }
}
