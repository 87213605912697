<div class="product_image-textCenter fluad_adjuvant-imagecenter" *ngIf=" node1 | async as item">
    <div class="simple-banner banner__component--responsive">
<img class="js-responsive-image animate-shield3" src="../../../app/../assets/images/40208_3_FLUAD_Adj-Advantage_1440x801 copy.webp">
</div><div class="container">
        <div class="product_image-textcontainer">
            <div class="product_image-textHeader">{{item.heaLine}}</div>
            <div class="product_image-text1" [innerHTML]="item.textContent"></div>
            <a href="../../../app/../assets/images/download.png" target="_blank" download=""> <span>Download Summary of ACIP Decision</span>
                <img alt="Download" src="../../../app/../assets/images/product_download_icon.svg"></a>
        </div>
    </div>
    <div class="product_box1-disclaimer">{{item.bottomContent}}</div>
</div>

