import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { AuthService, BaseSiteService, OccEndpointsService } from '@spartacus/core';
import { Observable, tap, catchError, shareReplay, of, switchMap, BehaviorSubject, take } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
 
  userInfo$ = new BehaviorSubject<any>(null);//Observable<any> | undefined;
  seasonValue$ = new BehaviorSubject<string>('');
  invoiceCount$ = new BehaviorSubject<string>('0')
  profileInfo$ = new BehaviorSubject<any>(null);
  checkoutId$ = new BehaviorSubject<any>('');
  isCancelled$ = new BehaviorSubject<any>(false);
  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  dataInvoice$: Observable<any> | undefined;
  orderData$: Observable<any> | undefined;
  isMobileHeader$ = new BehaviorSubject<boolean>(false); // <---- You give 'false' as an initial value
  showPopUp$= new BehaviorSubject<any>(false);
  private orderDataSubject = new BehaviorSubject<any>(null);
  public dashboardOrderData$ = this.orderDataSubject.asObservable();
  private orgLocRespData = new BehaviorSubject<any>(null);
  currentOrgLocData = this.orgLocRespData.asObservable();
  private filterValSub= new BehaviorSubject<string>("");
  filterData$ = this.filterValSub.asObservable();
  
  constructor(
    private http: HttpClient,
    private occ: OccEndpointsService,
    private router: Router,
    private authService: AuthService
  ) {

  }
  
  checkUserLoggedIn(){
    this.userLoggedIn$ = this.authService.isUserLoggedIn();
    this.userLoggedIn$.subscribe(data=>{
      //console.log("entered",data);
    });
  }
  fetchMultipleSoldToForUser(){
    const url = this.occ.buildUrl("/users/current/fetchMultipleSoldTo");
    return this.http.get(url);
  }
  sendDropdownValue(value: string) {
    this.seasonValue$.next(value);
  }
  getDropdownValue() {
    return this.seasonValue$.asObservable();
  }
  setInvoiceCount(value: string) {
    this.invoiceCount$.next(value);
  }
  getInvoiceCount() {
    return this.invoiceCount$.asObservable();
  }
  setcheckOutId(value: string) {
    this.checkoutId$.next(value);
    localStorage.setItem('checkoutId', value);
  }

  getcheckOutId() {
    return this.checkoutId$.asObservable();
  }
//   setCancelStatus(){
//    // let new = localStorage.getItem('isCancelled')
//     this.isCancelled$.next(localStorage.getItem('isCancelled'))
//   }
// getCancelStatus(){
//   return this.isCancelled$
// }
 
  loginRedirection(res: any) {
    if (res.webEnabled) {
      this.router.navigate(['/my-account']);
    }
    else {
      this.router.navigate(['/homepage']);
    }
  }

  setMobileHeader(flag: boolean) {
    return this.isMobileHeader$.next(flag)
  }
  /**
   * Get current user
   */
  public getMobileHeader(): Observable<boolean> {
    return this.isMobileHeader$.asObservable();
  }
  fetchUserData(): Observable<any> | any {

      this.authService.isUserLoggedIn().pipe(shareReplay(1)).subscribe(isloggedIn => {
        if (isloggedIn) {
          let url = this.occ.buildUrl('/users/current');
          this.http.get(url).subscribe({
            next: (v: any) => {
              //console.log(v),
              v.webEnabled = v.orgUnit.uid !== 'SeqirusUK';
              if (v) {
                this.setUserData(v);
                if (!localStorage.getItem('loggedIn')) {
                  this.loginRedirection(v);
                localStorage.setItem('loggedIn', v.uid);
                }
                
              }
            },

            error: (e) => console.error(e),
          })
        }
        else{
          localStorage.removeItem('loggedIn');
        }
      })
}
setUserData(result: any){
  this.userInfo$.next(result);
}
getUserData(){
  return this.userInfo$.asObservable();
}
setProfileData(result: any){
  this.profileInfo$.next(result);
}
getUKMyProfileData(){
  const url = this.occ.buildUrl("/users/current/my-profile/profile"); 
       return this.http.get(url);
}
getProfileData(){
  return this.profileInfo$.asObservable();
}
getInvoiceData(): Observable < any > {
  let url=this.occ.buildUrl(`/users/current/financials/invoicesAndCreditDetails`);
  if(!this.dataInvoice$) {
  this.dataInvoice$ = this.http.get(url).pipe(
    shareReplay(1),
    catchError(error => {
      console.error('Error fetching data', error);
      return of(null);
    })
  );
}
return this.dataInvoice$;
    }
    getseasonDropdown(season:any){
      const url = this.occ.buildUrl("/users/current/orders/seasonDropdown/"+season+"?fields=FULL"); 
       return this.http.get(url);
    }
    getOrderSummary(){      
      const url = this.occ.buildUrl("/users/current/orders/getOrderSummary?fields=FULL"); 
       return this.http.get(url);
    }
    getMyOrdersShipmentTable(){
      const url = this.occ.buildUrl("/users/current/orders/getMyOrdersShipmentTable?fields=FULL"); 
       return this.http.get(url);
    //getMyOrdersShipmentTable?fields=FULL
    }
getOrdersDashboard(){
  const url = this.occ.buildUrl("/users/current/my-account/dashboard?fields=FULL");
  if (!this.orderData$) {
    this.orderData$ = this.http.get(url).pipe(
      shareReplay(1),
      catchError(error => {
        console.error('Error fetching data', error);
        return of(null);
      })
    );
    this.orderDataSubject.next(this.orderData$);
  }
  return this.orderData$;
}

getOrgLocData():Observable<any> {
  const url = this.occ.buildUrl("/users/current/fetchOrgLocTableData");
  
  return this.http.get(url);
} 
saveOriginalLocData(originalLocData: any) {
  
  const url = this.occ.buildUrl("/users/current/saveOriginalLocationData");
  this.http.post(url,originalLocData,{headers:{'Content-Type':'application/json'}}).subscribe(data => {
    if(data == 200){
      console.log("success");
    }
  });
}

updateLocationData(updateLocData:any):Observable<any>{
  const url = this.occ.buildUrl("/users/current/updateOrgLocations");
  return this.http.post(url,updateLocData,{headers:{'Content-Type':'application/json'}})
}

validateLicense(data: HttpParams) :Observable<any>{
  const url = this.occ.buildUrl("/users/current/register/validateLicence");
  return this.http.get(url,{params:data,responseType: 'text'});
}

addOrgLocation(addLocData: any): Observable<any> {
  const url = this.occ.buildUrl("/users/current/addOrgLocation");
  return this.http.post(url,addLocData,{headers:{'Content-Type':'application/json'}})
}


filterproduct(){  
  const url = this.occ.buildUrl("/users/current/orders/filterProducts?fields=FULL"); 
  return this.http.get(url);
    // console.log(res)
  
}
UkMyorderleftsection(season:string){  
  //https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/ordersUK?fields=DEFAULT&selectedSeason=2024-2025
  const url = this.occ.buildUrl("/users/current/orders/ordersUK?fields=DEFAULT&selectedSeason="+season); 
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/getOrderbyID?fields=DEFAULT&orderId=0010319474&selectedSeason=2024-2025


UkMyorderRightsection(season:string,orderid:string){
  const url=this.occ.buildUrl("/users/current/orders/getOrderbyID?fields=FULL&orderId="+orderid+"&selectedSeason="+season);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/getDeliveryScheduleDetails?fields=DEFAULT&orderId=0010319179&selectedSeason=2023-2024
MyorderRightTable(season:string,orderid:string){
  const url=this.occ.buildUrl("/users/current/orders/getDeliveryScheduleDetails?fields=FULL&orderId="+orderid+"&selectedSeason="+season);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/CalenderEvents?fields=FULL&orderId=0010319179&selectedSeason=2023-2024&lang=en&curr=GBP
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/calenderEvents?fields=DEFAULT&orderId=0010319179&selectedSeason=2023-2024
MyCalenderUk(season:string,orderid:string){
  const url=this.occ.buildUrl("/users/current/orders/calenderEvents?fields=DEFAULT&orderId="+orderid+"&selectedSeason="+season);
  return this.http.get(url);
}
filterstatus(){  
  
  const url = this.occ.buildUrl("/users/current/orders/filterStatus?fields=FULL"); 
  return this.http.get(url);
}
Myorderleftsection(season:string){  
  
  const url = this.occ.buildUrl("/users/current/orders/ordersList/"+season+"?fields=FULL"); 
  return this.http.get(url);
}
MyorderRightsection(season:string,orderid:string){
  const url=this.occ.buildUrl("/users/current/orders/orderDetails/"+season+"/"+orderid+"?fields=FULL");
  return this.http.get(url);
}
AllInvoicesRightsection(season:string,invoiceId:string, status:string){
  const url=this.occ.buildUrl("/users/current/financials/invoicesDetailsAjax?fields=FULL&invoiceNumber="+invoiceId+"&invoiceStatus="+status);
  return this.http.get(url);
}
fetchSavedCards(){
  
  const url=this.occ.buildUrl("/users/current/financials/fetch-saved-cards?fields=FULL");
  return this.http.get(url);
}

savedCardClicked(obj: any): Observable<any> {
  const url = this.occ.buildUrl("/users/current/financials/saved-card-payment?fields=FULL");
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
redirectIframeUrl(obj: any): Observable<any> {
  const url = this.occ.buildUrl("/users/current/financials/createHostedCheckout?fields=FULL");
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
getAllInvoices(season:any):Observable<any>{
  const url=this.occ.buildUrl("/users/current/financials/dashboard/all-invoices?fields=DEFAULT&selectedSeason="+season+"");
  return this.http.get(url);
  ///"+season+"?fields=FULL"
}
getCredits(season:any):Observable<any>{
  const url=this.occ.buildUrl("/users/current/financials/dashboard?fields=DEFAULT&selectedSeason=2023-2024")
  return this.http.get(url);
  ///"+season+"?fields=FULL"
}
downloadInvoiceApi(invoicenum :string) {
   
  const url = this.occ.buildUrl("/users/current/financials/downloadInvoice?invoiceNumber="+invoicenum);  
  return this.http.get(url, { responseType: 'arraybuffer' });
}
financialdashboard(selectedseason:string){
  const url=this.occ.buildUrl("users/current/financials/dashboard?fields=FULL&selectedSeason="+selectedseason);
  return this.http.get(url);
}
financialdownloadInvoice(invoicenumber:number) {
   
  const url = this.occ.buildUrl("/users/current/financials/viewCreditReport?invoiceNumber="+invoicenumber);
  return this.http.get(url, { responseType: 'arraybuffer' });
} 
financialcurrentseason(){
  const url=this.occ.buildUrl("users/current/financials/dashboard/currentSeason");
  return this.http.get(url);

}


//createHostedCheckout
//financials/dashboard?fields=DEFAULT&selectedSeason=2023-2024
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-flu360/users/current/financials/invoicesDetailsAjax?fields=FULL&invoiceNumber=9130226308&invoiceStatus=DueSoon

deleteCreditDetails(token: string) {
  const url = this.occ.buildUrl("/users/current/financials/delete-saved-card/" + token + "?fields=FULL");
  return this.http.get(url);
}
//edit 
updateOrder(orderData:any){ 
  const url= this.occ.buildUrl("/users/current/cart/editOrder/addToCart?fields=DEFAULT");
  return this.http.post(url,orderData,{ responseType: 'text' });

}
MembershipDetails(){
  const url=this.occ.buildUrl("/users/current/my-profile/membership-status?fields=DEFAULT");
  return this.http.get(url);
}
editconfirmorder(PORefNumber:any,sapOrderID:any){  
  const url=this.occ.buildUrl("/users/current/checkout/confirmOrder?PORefNumber="+PORefNumber+"&fields=DEFAULT&sapOrderID="+sapOrderID+"&season=editorder")
  console.log(PORefNumber);
  console.log(sapOrderID);
  return this.http.get(url, {responseType: 'text'});
}
paymentStatusIframe(obj: any, id:any): Observable<any> {
  const url = this.occ.buildUrl("/users/current/financials/payment-status?fields=FULL&hostedCheckoutId="+id+"&isFramed=true");
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
checkCartExist() {
  const checkOrderUrl = this.occ.buildUrl(`/users/current/Start-order/checkCartExistforSeason?fields=DEFAULT&season=`);
  return this.http.get(checkOrderUrl, { responseType: 'text' });
}
setPopUpValue(result: boolean){
  this.showPopUp$.next(result);
}
getPopValue(){
  return this.showPopUp$.asObservable();
}
updateOrgLocData(data: any) {
  this.orgLocRespData.next(data);
}
sendFilterValue(filterValue: any) {
 this.filterValSub.next(filterValue);
}


}
