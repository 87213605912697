<div class="product_image-textSide"*ngIf="node1 | async as item">
    <div class="container">
        <div class="product_image-textSide-container ">
                <div class="product_image-textSide-content">
                    <div class="product_image-textHeader">{{item.headLine}}</div>
                    <div [innerHTML]="item.textContent"></div>
                    <a href="/products/flucelvax/real-world-evidence" class="product_box1-button">{{item.link1Name}}</a>
                    <div class="product_box1-disclaimer hidden-md hidden-lg">{{item.bottomContent}}</div>
                </div>
                <div class="product_image-textSide-Image">
                        <div class="simple-banner banner__component--responsive">
<img class="js-responsive-image" alt="Young girl being given a flu vaccine" title="Young girl being given a flu vaccine" src="../../../app/../assets/images/Image_FLX_Landing_02_Desktop.webp">
</div></div>
                 <div class="clearboth"></div>
                <div class="product_box1-disclaimer hidden-xs hidden-sm">{{item.bottomContent}}.</div>
            </div>
        </div>
