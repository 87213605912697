<!--OM product selection page starts here -->

<div class="col-xs-12 main no-pad OM_doseInput" id="contact_request">
    <!--OM product selection page body starts here -->
    <div class="prodSelectBody_container" id="OM_prodSelect" *ngIf="productData && productData.productList.length > 0">
        <!-- add soldout class in below div -->
        <div class="col-xs-12 col-md-12 no-pad OM_productContainer">
            <div class="col-xs-12 col-md-4 no-pad OM_productDetails" *ngFor="let item of productData.productList">
                <div class="OM_productBox"></div>
                <div class="OM_productTitle"><span *ngIf="item.baseName === 'AQIV'">Adjuvanted Quadrivalent Influenza
                        Vaccine (aQIV)</span> <span *ngIf="item.baseName === 'QIVC'">Cell-Based Quadrivalent Influenza
                        Vaccine
                        (QIVc)</span></div>
                <div class="OM_productSubTitle">(surface antigen, inactivated) Seqirus</div>
                <div class="OM_descripsionText"><span *ngIf="item.baseName === 'AQIV'">For people aged 65 years and
                        over</span><span *ngIf="item.baseName === 'QIVC'">For adults and children from 2 years of
                        age</span></div>
                <div class="OM_productCost">
                    <div>{{item.perPack}}-pack</div>
                    <div class="OM_verticlDivision"></div>
                    <div class="priceDetail">&#163;<span>{{item.doses}}</span> per dose</div>
                </div>
                <span class="SoldoutText unavailableText" *ngIf="!item.sellable">Unavailable</span>
                <div class="availableProduct" *ngIf="item.sellable">Available</div>
            </div>
            <!-- <div class="col-xs-12 col-md-4 no-pad OM_productDetails" id="qivc">
                <div class="OM_productBox"></div>
                <div class="OM_productTitle">Cell-Based Quadrivalent Influenza Vaccine (QIVc)</div>
                <div class="OM_productSubTitle">(surface antigen, inactivated) Seqirus</div>
                <div class="OM_descripsionText">For adults and children from 2 years of age</div>
                <div class="OM_productCost">
                    <div>10-pack</div>
                    <div class="OM_verticlDivision"></div>
                    <div class="priceDetail">&#163;<span>10.00</span> per dose</div>
                </div>
            </div> -->
        </div>
        <div class="clearboth"></div>


        <section class="col-xs-12 OM_productDoseSection">
            <div class="cart_summaryLocationselection">
                <div class="global_blackarrow"></div>Back to Location Selection
            </div>
            <div class="col-xs-12 OM_selectline"></div>
            <div class="OM_productTableHeader">Select Products</div>
            <div class="OM_productTableSubHeader">Standard terms {{productData.priceResponse.SOR}}% SOR &amp;
                {{productData.priceResponse.creditDays}} credit days</div>
            <div class="OM_productTableInst">You can enter 100-5000 doses per product per location</div>
            <!-- Table starts -->
            <div class="locationrowall col-md-12 locationrow60149134 no-pad">
                <div *ngFor="let locDetail of productData.shippingData">
                <div class="OM_productLocation col-xs-12" id="prodLocation1">

                    <div class="col-xs-12 col-md-5 OM_locParent">
                        <div class="col-xs-12 OM_locationheader">Location Name</div>
                        <div class="col-xs-12 col-md-7 OM_locationText pl-2 pr-0">
                            {{locDetail.locname}}{{locDetail.address}}{{locDetail.state}}
                            <!-- <div class="zipcodewrap"> GB HP18 9SA</div> -->
                        </div>
                    </div>
                    <div *ngFor="let prodList of productData.productList">
                    <div class="col-xs-12 allProd no-padding">
                        <div class="col-xs-12 productheader">
                            
                                <div class="">
                                    <div class="OM_locationProdName">{{prodList.baseProductName}}</div>
                                    <div class="OM_prodDes">(65 years &amp; above)</div>
                                    <!-- year data without backet for provisional order -->
                                </div>
                                <!-- <div class="col-md-6">
                                <div class="OM_locationProdName">QIVc 10 Pack</div>
                                <div class="OM_prodDes">(6 months &amp; above)</div>
                            </div> -->
                            
                        </div>
                        <div class="col-xs-12 prodincontainer">

                            <div class="col-xs-12 aqivcontainer createproductsize">


                                <div class="doseQuant aqivinputcont" style="padding-left:25px">
                                    <input id="dose[0]"
                                        name="createOrderData[0].CreateOrderProductData[0].productQuantity"
                                        placeholder="0" class="form-control aqiv_box dynDoseInput dynplacecolor"
                                        type="number" value="" autocomplete="no" disabled=""><input
                                        id="createOrderData0.CreateOrderProductData0.productCode"
                                        name="createOrderData[0].CreateOrderProductData[0].productCode" value="10054395"
                                        type="hidden">
                                    <div class="totaldose">
                                        Total Doses</div>
                                </div>
                            </div>
                            <!-- <div class="col-xs-12 col-md-6 aqivcontainer createproductsize pl-0">
                                <div class="doseQuant aqivinputcont pl-3">
                                    <input id="cdose[0]"
                                        name="createOrderData[0].CreateOrderProductData[1].productQuantity"
                                        placeholder="0" class="form-control qivc_box dynDoseInput onlyQivc" type="text"
                                        value="" autocomplete="no"><input
                                        id="createOrderData0.CreateOrderProductData1.productCode"
                                        name="createOrderData[0].CreateOrderProductData[1].productCode" value="10054364"
                                        type="hidden">
                                    <div class="totaldose">
                                        Total Doses</div>

                                </div>

                            </div> -->
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
    </div>
    <!-- Table Ends -->

    </section>
    <div class="col-xs-12 OM_tableBottomBtn">
        <div class="col-xs-12 col-md-6 OM_totalCost">Total Cost: &nbsp;&nbsp;&nbsp;&#163;<span
                class="OM_totalProductCost">0.00</span></div>
        <div class="col-xs-12 col-md-6 OM_rightBtnParent">
            <a class="OM_exitBtn" (click)="handleOnExitClick()">Exit</a>
            <button class="OM_selectWeekBtn" disabled="">Select Delivery Weeks</button>
        </div>
    </div>
    <div class="clearboth"></div>
</div>
<!--OM product selection page body ends here -->

<!-- external Popup starts -->
<div class="OM_externalPop modal" data-backdrop="static" id="OM_externalPop">
    <div class="OM_externalPopContent">
        <div class="OM_externalPopHeader">
            <div class="OM_externalPopTitle">Are you sure you want to exit?</div>
            <div class="OM_externalPopClose">X</div>
            <div class="clearboth"></div>
        </div>
        <div class="OM_externalPopText">
            <p>You will no longer have access to your progress for this new order.</p>
        </div>
        <div class="OM_externalPopBtns">
            <button class="OM_externalPopBtn OM_externalPopBackBtn">Go Back To Order</button>
            <button class="OM_externalPopBtn OM_externalPopExitBtn">Exit &amp; Lose Progress</button>
        </div>
    </div>
</div>
<!-- external Popup ends -->

</div>
<!-- OM product selection page ends here -->