import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-clinical-fluad-third',
  templateUrl: './clinical-fluad-third.component.html',
  styleUrl: './clinical-fluad-third.component.scss'
})
export class ClinicalFluadThirdComponent {
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>,protected config: OccConfig,) { }
  thirdheadline: Observable<any> = this.bannerComponent.data$;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getMobileImage(image: any): string{
    return this.config.backend?.occ?.baseUrl + image.tablet.url;
  }
}
