<div class="main_inner-wrapper">
    <div >
        <div class="col-12 no-padding float_left" id="dashborad_topcontent">
            <div class="col-12 dashboard-welcomesection float_left">
                <div  *ngIf="currentSite != 'seqirusb2b-uk'">
                <div class="col-12 col-md-6 dashboradnewleft no-padding float_left" *ngIf="userData$ | async as item" id="dashborad_topcontentleftcontent">
                    <h1 class="col-12 modified-h1"><span class="username" > {{item?.firstName+' '+item?.lastName}}</span></h1>
                    <div class="col-12 margin-T5 currently-booking">Last Login:
                        <span class="currently-booking-seson">{{item?.lastLogin}}</span> </div>
                </div>
            </div>
                <div  *ngIf="currentSite == 'seqirusb2b-uk'">
                <div class="col-12 col-md-6 dashboradnewleft no-padding float_left"  id="dashborad_topcontentleftcontent"  *ngIf=" profileData | async as item1">
                    <h1 class="col-12 modified-h1"><span class="username" > {{item1.firstName}} {{item1.lastName}}</span></h1>
                    <div class="col-12 margin-T5 currently-booking">Last Login:
                        <span class="currently-booking-seson">{{item1.lastLogin}}</span> </div>
                </div>
            </div>
                <div class="col-xs-12 col-md-6 dashboardadjustableright no-padding float_left" id="dashborad_topcontentrightcontent">
                    <div class=" dashboard-ordervalues">
                        <div class="col-xs-12 dashborad_openorders no-padding">
                            <div class="col-xs-12 myprofile_dateheader no-padding">Account Status: <span>Active</span>
                            </div>
                            <div class="col-xs-11 myprofile_dateheader myprofile_dateheader_link no-padding"><a [routerLink]="['/changepassword']">Update Password<div class="global_whitearrow margin-T6"></div></a></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
    <div class="row" *ngIf="currentSite == 'seqirusb2b-uk'">
        <div class="col-12 PibannerUk"><a class="pibannerlink" href="/prescribinginformation">Prescribing Information <img alt="Next screen" src="/assets/images/arrow-right-white.svg"></a></div>
      </div>
      </div>