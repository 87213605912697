import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartbannerComponent } from './cartbanner/cartbanner.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { LocationpageComponent } from './locationpage/locationpage.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ExitPopupComponent } from './exit-popup/exit-popup.component';
import { ProductpageComponent } from './productpage/productpage.component';



@NgModule({
  declarations: [
    CartbannerComponent,
    LandingpageComponent,
    LocationpageComponent,
    ExitPopupComponent,
    ProductpageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    FormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        StartOrderLocationPageComponent: {
          component: LocationpageComponent
        },
        StartOrderExitPopupComponent: {
          component: ExitPopupComponent
        },
        TopBanerStartOrderPageComponent : {
          component: CartbannerComponent ,
        },
        SeqirusStartOrderCommitmentsComponent:{
          component:LandingpageComponent,
        },
        StartOrderFlexComponent: {
          component:ProductpageComponent
        }
      }
    } as CmsConfig)
  ]
})
export class StartOrderUKModule { }
