import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Influenza65PlusComponent } from './influenza-65-plus.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { SecondComponent } from './second/second.component';
import { ThirdComponent } from './third/third.component';
import { FourthComponent } from './fourth/fourth.component';
import { FifthComponent } from './fifth/fifth.component';
import { SixthComponent } from './sixth/sixth.component';

@NgModule({
  declarations: [
    Influenza65PlusComponent,
    SecondComponent,
    ThirdComponent,
    FourthComponent,
    FifthComponent,
    SixthComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusInfluenzaBannerComponent:{
          component:Influenza65PlusComponent
        },
        SeqirusInfluenzaAdultsComponent:{
          component:SecondComponent
        },
        SeqirusInfluenzaComplicationsComponent:{
          component:ThirdComponent
        },
        SeqirusInfluenzaLeftBannerComponent:{
          component:FourthComponent
        },
        SeqirusProductBackgroundBannerComponent:{
          component:FifthComponent
        },
        SeqirusReferenceComponent:{
          component:SixthComponent
        }
      }
    }as CmsConfig)
  ]
})
export class Influenza65PlusModule { }
