import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { ClinicalFluadSecondComponent } from './clinical-fluad-second/clinical-fluad-second.component';
import { ClinicalFluadThirdComponent } from './clinical-fluad-third/clinical-fluad-third.component';
import { ClinicalFluadFirstComponent } from './clinical-fluad-first/clinical-fluad-first.component';
import { TopnavigationComponent } from './topnavigation/topnavigation.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FluadchoosenowComponent } from './fluadchoosenow/fluadchoosenow.component';





@NgModule({
  declarations: [
       ClinicalFluadSecondComponent,
       ClinicalFluadThirdComponent,
       ClinicalFluadFirstComponent,
       TopnavigationComponent,
       FluadchoosenowComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusProductNavigationBanner:{
          component:TopnavigationComponent 
        },
        SeqirusBoostedImmuneComponent:{
          component: ClinicalFluadFirstComponent
        },
        SeqirusDemonstratedComponent:{
          component: ClinicalFluadSecondComponent
        },
        SeqirusMillionDosesComponent:{
          component: ClinicalFluadThirdComponent
        },
        SeqirusProductViewComponent:{
          component:FluadchoosenowComponent 

        }
      }
    })
  ]
})
export class ClinicaldataFluadModule { }
