import { ChangeDetectorRef, Component } from '@angular/core';
import { AsmComponentService, AsmSessionTimerComponent } from '@spartacus/asm/components';
import { AsmConfig } from '@spartacus/asm/root';
import { RoutingService, UserIdService } from '@spartacus/core';

@Component({
  selector: 'app-flu360-asm-session-timer',
  templateUrl: './flu360-asm-session-timer.component.html',
  styleUrl: './flu360-asm-session-timer.component.scss'
})
export class Flu360AsmSessionTimerComponent extends AsmSessionTimerComponent {
  constructor(
    protected override config: AsmConfig,
    protected override asmComponentService: AsmComponentService,
    protected override routingService: RoutingService,
    protected override changeDetectorRef: ChangeDetectorRef,
    protected override userIdService: UserIdService
  ) {
    super(config, asmComponentService, routingService, changeDetectorRef, userIdService);
  }
}
