<section class="landingpage_videoContainer hidden-xs hidden-sm" *ngIf ="node2 | async as item" >
    <video muted="" autoplay="" playsinline="" title="egg as used in flu vaccine production" class="flucelvaxEggVideo"> <!-- Remove Interstitial Message changes-->
            <source src="../../../app/../assets/images/33196_FLUCELVAX_TIV_UPDATED_VIDEO_Egg_5-9-2024.mp4" type="video/mp4">
        </video>
    </section>
<!--hidden-->
<section class="landingpage_videoContainer hidden-md hidden-lg">
    <video muted="" autoplay="" playsinline="" title="egg as used in flu vaccine production" class="flucelvaxEggVideo"> <!-- Remove Interstitial Message changes-->
            <source src="../../../app/../assets/images/33196_Seqirus_Mobile_7-20-23_V2.mp4" type="video/mp4">
        </video>
    </section>
    <!-- <video autoplay muted playsinline poster="/_ui/responsive/theme-lambda/cms/assets/images/video-placeholder.png" controls="" 
    title="Seqirus MF59® adjuvant flu vaccine technology">
            <source [src]="getVideo(item.mediaVideo)" type="video/mp4"/>
        </video> -->