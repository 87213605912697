import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-third-image',
  templateUrl: './third-image.component.html',
  styleUrl: './third-image.component.scss'
})
export class ThirdImageComponent {
  node2: Observable<any> = this.component.data$;
  
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
}
getVideo(video: any): string {
  return this.config.backend?.occ?.baseUrl + video.url;
}
}
