<div *ngIf="bannerNode | async  as item" id="vaccine-row" class="row g--grey-0 reverse-xs">
    <div class="col-lg-6 col-md-5">
        <img class="flu-vaccine--image" alt="illustrated magnifying glass looking at virus cell" [src]="getImage(item.media)">
    </div>
    <div class="col-lg-5 col-md-7 ">
        <div class="flu-vaccine--callout">
            <img class="rectangle-down" src="assets/images/rectangle-down.svg">
            <p class="headLine">{{item.headline}}</p>
            <img class="rectangle-up" src="assets/images/rectangle-up.svg">
        </div>
    </div> 
</div>

