import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-about-references',
  templateUrl: './about-references.component.html',
  styleUrl: './about-references.component.scss'
})
export class AboutReferencesComponent {
  aboutref: Observable<any> = this.component.data$;
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
  }
}
