// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'helpnfaqsearch'
// })
// export class HelpnfaqsearchPipe implements PipeTransform {

//   // transform(value: unknown, ...args: unknown[]): unknown {
//   //   return null;
//   // }
//   searchquestion: boolean =false;
//   searchedanswer: boolean=false;
//   transform(value: any, seachvalue: String) {
   
//     if (!value) return [];
 
//   return value.filter((help:any) => {
//   if(seachvalue){
//        seachvalue = seachvalue.trim().toLowerCase();
//        this.searchquestion= Object.keys(help).some(key => {
//         return help.text2.toString().toLowerCase().includes(seachvalue);
//   });
//       this.searchedanswer= Object.keys(help).some(key => {
//         return help.content.toString().toLowerCase().includes(seachvalue);
//       });
 
//     }
//     return this.searchquestion || this.searchedanswer ;
//   });
//   }
// }
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'helpnfaqsearch'
})
export class HelpnfaqsearchPipe implements PipeTransform {

  searchquestion: boolean = false;
  searchedanswer: boolean = false;

  transform(value: any, seachvalue: String) {
    if (!value) return [];

    return value.filter((help: any) => {
      if (seachvalue) {
        seachvalue = seachvalue.trim().toLowerCase();
        this.searchquestion = Object.keys(help).some(key => {
          return help.text2 && help.text2.toString().toLowerCase().includes(seachvalue);
        });
        this.searchedanswer = Object.keys(help).some(key => {
          return help.content && help.content.toString().toLowerCase().includes(seachvalue);
        });
      }
      return this.searchquestion || this.searchedanswer;
    });
  }
}
