import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent ,BaseSiteService} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
@Component({
  selector: 'app-my-orders-dashboard',
  templateUrl: './my-orders-dashboard.component.html',
  styleUrl: './my-orders-dashboard.component.scss'
})
export class MyOrdersDashboardComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService,protected baseSiteService: BaseSiteService,private cd:ChangeDetectorRef) {
  }
  bannerNode: Observable<any> = this.component.data$;
  seasonData:any;
  //seasonData :Observable<any> =  this.userInfoService.getseasonDropdown();
  seasons$ = this.userInfoService.seasonValue$;
  data:any;
  currentSite :any;
  selectedSeason(e :any){    
    this.userInfoService.sendDropdownValue((e.target as HTMLInputElement).value)
    this.userInfoService.getseasonDropdown((e.target as HTMLInputElement).value).subscribe(data=>{
      this.seasonData=data;
    })
   // console.log('event', (e.target as HTMLInputElement).value)
  }
 
    
    
  
  ngOnInit(){

    //if null default season should be peaked changes needs to be done both side
    this.userInfoService.getseasonDropdown("2025-2026").subscribe(data=>{
this.seasonData=data;
//hard code needs to be remove from this by default season attr which will get from backend
this.userInfoService.sendDropdownValue('2025-2026');
    });
    this.baseSiteService.getActive().subscribe(res => {
      this.currentSite = res;
      
    });
   this.cd.detectChanges();
  }
}
