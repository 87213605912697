import { Component, ElementRef, EventEmitter, inject, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AsmComponentService, CustomerSelectionComponent } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import { CustomerSearchPage, AsmConfig, AsmDeepLinkParameters, AsmAuthStorageService } from '@spartacus/asm/root';
import { AuthStorageService, FeatureConfigService, useFeatureStyles, User, UserIdService } from '@spartacus/core';
import { DirectionService, LaunchDialogService, LAUNCH_CALLER, DirectionMode } from '@spartacus/storefront';
import { Subscription, Observable, debounceTime } from 'rxjs';

@Component({
  selector: 'app-flu360-asm-customer-seletcion',
  templateUrl: './flu360-asm-customer-seletcion.component.html',
  styleUrl: './flu360-asm-customer-seletcion.component.scss',
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
})
export class Flu360AsmCustomerSeletcionComponent extends CustomerSelectionComponent {
  override selectedCustomer: User | undefined;
  // searchByCustomer: boolean = false;
  // searchByOrder: boolean = false;
  isLoading: boolean = false;

  override activeFocusedButtonIndex = -1;
  submitLabel: string = "START SESSION";
  @Output()
  override submitEvent = new EventEmitter<{
    customerId?: any;
    parameters?: AsmDeepLinkParameters;
  }>();
  UIEvent: any;
  @ViewChild('resultList') override resultList: ElementRef = {} as ElementRef;;
  @ViewChild('searchTerm') override searchTerm: ElementRef = {} as ElementRef;;
  // @ViewChild('searchOrder') searchOrder: ElementRef = {} as ElementRef;;

  @ViewChild('createCustomerLink') override createCustomerLink: ElementRef = {} as ElementRef;;
  // @ViewChildren('searchResultItem') override searchResultItems: QueryList<
  //   ElementRef<HTMLElement>
  // >  = {} as ElementRef;;
  // protected featureConfig = inject(FeatureConfigService);
  // isShowSearchingCustomerByOrderInASM = this.featureConfig.isEnabled(
  //   'showSearchingCustomerByOrderInASM'
  // );
  constructor(
    protected override fb: UntypedFormBuilder,
    protected override asmService: AsmService,
    protected override config: AsmConfig,
    protected override directionService: DirectionService,
    protected override launchDialogService: LaunchDialogService,
    protected asmComponentService: AsmComponentService,
    private authStorageService: AsmAuthStorageService,
    private userIdService: UserIdService
  ) {
    super(fb, asmService, config, directionService, launchDialogService);
    // useFeatureStyles('showSearchingCustomerByOrderInASM');
  }

  override selectCustomerFromList(event: any, customer: User) {
    this.selectedCustomer = customer;
    this.customerSelectionForm.controls['searchTerm'].setValue(
      this.selectedCustomer.name
    );
    // if (this.isShowSearchingCustomerByOrderInASM) {
    //   this.customerSelectionForm.controls['searchTerm'].setValue(
    //     this.selectedCustomer.name,
    //     {
    //       emitEvent: false,
    //     }
    //   );
    // } else {
    //   this.customerSelectionForm.controls['searchTerm'].setValue(
    //     this.selectedCustomer.name
    //   );
    // }
    this.asmService.customerSearchReset();
    this.searchTerm.nativeElement.focus();
    // if (!this.isShowSearchingCustomerByOrderInASM) {
    //   this.searchTerm.nativeElement.focus();
    // }
    event.preventDefault();
    event.stopPropagation();
  }
  override onDocumentClick(event: any) {
    if (Boolean(this.resultList)) {
      if (
        this.resultList.nativeElement.contains(event.target) ||
        this.searchTerm.nativeElement.contains(event.target)
      ) {
        return;
      } else {
        this.asmService.customerSearchReset();
      }
    }
  }

  override closeResults(event: any) {
    this.asmService.customerSearchReset();
    this.searchTerm.nativeElement.focus();
    event.preventDefault();
    event.stopPropagation();
  }

  // closeOrderSearchResults(event: any) {
  //   this.asmService.customerSearchReset();
  //   this.searchOrder.nativeElement.focus();
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
  override focusFirstItem(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex = 0;
    this.updateItemIndex(this.activeFocusedButtonIndex);
  }
  override setSelectionEnd(event: any): void {
    event.preventDefault();
    if (this.searchTerm.nativeElement.value?.length) {
      const selectionStart = this.searchTerm.nativeElement.value.length;
      this.searchTerm.nativeElement.selectionStart = selectionStart;
      this.searchTerm.nativeElement.selectionEnd = selectionStart;
    }
  }
  // setOrderSearchSelectionEnd(event: any): void {
  //   event.preventDefault();
  //   if (this.searchOrder.nativeElement.value?.length) {
  //     const selectionStart = this.searchOrder.nativeElement.value.length;
  //     this.searchOrder.nativeElement.selectionStart = selectionStart;
  //     this.searchOrder.nativeElement.selectionEnd = selectionStart;
  //   }
  // }
  override focusPreviousChild(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex--;
    if (this.activeFocusedButtonIndex < 0) {
      this.activeFocusedButtonIndex = this.searchResultItems.length - 1;
    }
    this.updateItemIndex(this.activeFocusedButtonIndex);
  }
  override focusNextChild(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex++;
    if (this.activeFocusedButtonIndex > this.searchResultItems.length - 1) {
      this.activeFocusedButtonIndex = 0;
    }
    this.updateItemIndex(this.activeFocusedButtonIndex);
  }
  override focusInputText(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex = -1;
    this.searchTerm.nativeElement.focus();
    if (this.searchTerm.nativeElement.value?.length) {
      let selectionPos = this.searchTerm.nativeElement.selectionEnd;
      const searchTermLength = this.searchTerm.nativeElement.value.length;

      if (this.isBackNavigation(event)) {
        selectionPos = selectionPos <= 0 ? 0 : selectionPos - 1;
      } else if (this.isForwardsNavigation(event)) {
        selectionPos =
          selectionPos >= searchTermLength
            ? searchTermLength
            : selectionPos + 1;
      } else if (event.code === 'Home') {
        selectionPos = 0;
      } else if (event.code === 'End') {
        selectionPos = searchTermLength;
      }
      this.searchTerm.nativeElement.selectionStart = selectionPos;
      this.searchTerm.nativeElement.selectionEnd = selectionPos;
    }
  }
  // focusOrderSearchInputText(event: any): void {
  //   event.preventDefault();
  //   this.activeFocusedButtonIndex = -1;
  //   this.searchOrder.nativeElement.focus();
  //   if (this.searchOrder.nativeElement.value?.length) {
  //     let selectionPos = this.searchOrder.nativeElement.selectionEnd;
  //     const searchTermLength = this.searchOrder.nativeElement.value.length;

  //     if (this.isBackNavigation(event)) {
  //       selectionPos = selectionPos <= 0 ? 0 : selectionPos - 1;
  //     } else if (this.isForwardsNavigation(event)) {
  //       selectionPos =
  //         selectionPos >= searchTermLength
  //           ? searchTermLength
  //           : selectionPos + 1;
  //     } else if (event.code === 'Home') {
  //       selectionPos = 0;
  //     } else if (event.code === 'End') {
  //       selectionPos = searchTermLength;
  //     }
  //     this.searchOrder.nativeElement.selectionStart = selectionPos;
  //     this.searchOrder.nativeElement.selectionEnd = selectionPos;
  //   }
  // }
  // isNoResultMessageInfoVisible(
  //   results: any,
  //   searchFlag: boolean,
  //   searchElement: HTMLInputElement
  // ): boolean {
  //   const searchTermValid = searchElement.value.length >= 3;
  //   const hasEntries = !!results.entries && results.entries.length > 0;
  //   return !this.isLoading && searchTermValid && searchFlag && !hasEntries;
  // }
  // isSearchResultsVisible(results: any, searchFlag: boolean): boolean {
  //   return !!results.entries && searchFlag && results.entries.length > 0;
  // }
  handleOnSubmit(event: string) {
    this.submitLabel = event;
    if (event === 'START SESSION') {
      this.selectedCustomer = {};
      this.customerSelectionForm.controls['searchTerm'].setValue(
        ''
      );
      this.authStorageService.switchTokenTargetToCSAgent();
    }
  }
}
