import { Component, AfterViewInit } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
 
@Component({
  selector: 'app-third',
  templateUrl: './third.component.html',
  styleUrls: ['./third.component.scss']
})
export class ThirdComponent implements AfterViewInit {
  object1: Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusThirdComponent');
  currentIndex = 0;
  item: any;
 
  constructor(private cmsService: CmsService, protected config: OccConfig) {
    this.object1.subscribe(data => {
      this.item = data;
      this.showSlides(this.currentIndex);
    });
  }
 
  ngAfterViewInit() {
    this.showSlides(this.currentIndex);
  }
 
  currentSlide(index: number) {
    this.currentIndex = index;
    this.showSlides(this.currentIndex);
  }
 
  showSlides(index: number) {
    const slides = document.getElementsByClassName('mini-card') as HTMLCollectionOf<HTMLElement>;
    const dots = document.getElementsByClassName('dot') as HTMLCollectionOf<HTMLElement>;
 
    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.remove('active-slide');
    }
 
    for (let i = 0; i < dots.length; i++) {
      dots[i].classList.remove('active');
    }
 
    if (slides[index]) {
      slides[index].classList.add('active-slide');
      dots[index].classList.add('active');
    }
  }
}