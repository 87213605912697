<div class="innovation-container-over">
    <div class="bg--grey-0 innovation-container">
        <div class="row innovation-row">
        <div *ngFor="let item2 of mergedObject1; let i= index" class="col-lg-6 col-md-6 col-sm-12  innovation--card mt-60 mb-50 mb-md-0">
        <div class="innovation--card">
            <div class="col-lg-6 col-md-6 col-sm-12 innovation--card mt-60 mb-50 mb-md-0">
                <p class="innovation--card-eyebrow"><span>{{item2.headLine}}</span></p>
                 <p class="innovation--card-title text--grey-110">{{item2.subHeadLine}}</p>
    <p class="paragraph--1-book  innovation--card-paragraph">{{item2.content}}</p>
    <ul class="innovation-card-cta-container" style="list-style-type: none; padding: 0;">
        <!-- Conditional rendering for the first link -->
        <li *ngIf="item2.t1content" class="innovation--card-cta">
        <a href="/cell-technology">
             {{ item2.t1content }}
        <img alt="Next screen" src="/assets/images/blackarrow.png">
        </a>
        </li>
        <!-- Conditional rendering for the second link -->
        <li *ngIf="item2.t2content" class="innovation--card-cta">
        <a href="/adjuvant-technology">
             {{ item2.t2content }}
        <img alt="Next screen" src="/assets/images/blackarrow.png">
        </a>
        </li>
        </ul>    
     
    </div>
        </div>
       
       </div>
       <div id="page04--join360hero-footer" class="col-sm-6">
        <div class="join360--svg">
            <div>
                <svg width="585px" height="270px" viewBox="0 0 707 270" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>flu360</title>
                    <defs>
                        <filter id="filter-1">
                            <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.603922 0 0 0 0 0.639216 0 0 0 1.000000 0"></feColorMatrix>
                        </filter>
                        <polygon id="path-2" points="0.435094804 0.887278057 229.875195 0.887278057 229.875195 269.999616 0.435094804 269.999616"></polygon>
                        <polygon id="path-4" points="0.638379747 0.887278057 191.63001 0.887278057 191.63001 269.998849 0.638379747 269.998849"></polygon>
                    </defs>
                    <g id="18606_4_flu360.com_LO-ClinicalSolutionsDetailPage_Desktop_V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity=".25">
                        <g transform="translate(-716.000000, -1284.000000)" id="flu360" filter="url(#filter-1)">
                            <g transform="translate(716.000000, 1284.000000)">
                                <g id="Group-3" transform="translate(477.000000, 0.000000)">
                                    <mask id="mask-3" fill="white">
                                        <use xlink:href="#path-2"></use>
                                    </mask>
                                    <g id="Clip-2"></g>
                                    <path d="M197.91981,38.6951366 C176.485222,13.9641794 146.106789,0.886894334 110.059451,0.886894334 C58.3383579,0.886894334 18.4070542,28.3230998 0.435094804,76.1810515 C21.3679077,81.2193365 39.8646238,91.3266044 54.002412,105.459128 C61.8584411,74.1895283 82.2550063,56.2696571 110.059451,56.2696571 C128.582979,56.2696571 143.927325,64.1014466 154.43778,78.9054859 C164.385175,92.9228927 169.640403,112.473587 169.640403,135.45093 C169.640403,158.424435 164.385175,177.978967 154.43778,191.996374 C143.927325,206.800413 128.582979,214.632202 110.059451,214.632202 C98.0666535,214.632202 87.4336272,211.359044 78.4514778,204.900983 C73.0009028,223.749465 62.2184932,240.452933 47.2227086,253.273124 C65.3670334,264.370398 86.499024,269.999616 110.059451,269.999616 C146.106789,269.999616 176.485222,256.926168 197.91981,232.199048 C218.82581,208.066699 229.876344,174.617552 229.876344,135.45093 C229.876344,96.2843075 218.82581,62.8351602 197.91981,38.6951366" id="Fill-1" fill="#FFFFFF" mask="url(#mask-3)"></path>
                                </g>
                                <path d="M261,160 L239.5563,266.154895 L203.871892,266.154895 L205.35554,259.15097 C195.292875,265.498038 185.295552,269 173.845331,269 C146.966716,269 132.195585,250.833333 138.41076,220.629388 L150.410623,160 L186.098874,160 L175.109891,215.156857 C172.111847,230.25692 178.338553,237.043164 190.015549,237.043164 C196.622776,237.043164 204.194758,233.541202 211.847457,226.754958 L225.311749,160 L261,160 Z" id="Fill-4" fill="#FFFFFF"></path>
                                <g id="Group-8" transform="translate(225.000000, 0.000000)">
                                    <mask id="mask-5" fill="white">
                                        <use xlink:href="#path-4"></use>
                                    </mask>
                                    <g id="Clip-7"></g>
                                    <path d="M155.027538,135.450162 L154.299378,135.123998 L154.98179,134.713414 C174.504888,122.994509 184.4094,105.101498 184.4094,81.5293848 C184.4094,33.2953844 148.702832,0.886126888 95.5547163,0.886126888 C41.1980819,0.886126888 3.98563217,33.2608493 0.638379747,83.4134655 L59.4592882,83.4134655 C61.1786627,64.7683575 74.8231005,53.1991044 95.1696679,53.1991044 C114.734701,53.1991044 127.87972,65.1981274 127.87972,83.0604402 C127.87972,101.156824 113.819735,113.309336 92.8936888,113.309336 L72.0324527,113.309336 L72.0324527,159.88949 L92.8936888,159.88949 C117.982832,159.88949 133.575386,171.182462 133.575386,189.355591 C133.575386,206.442783 118.379318,218.837041 97.4494594,218.837041 C75.6351333,218.837041 60.1073894,207.709069 56.8287595,189.773849 L41.5068831,189.773849 L29.6923783,249.174193 C47.0348057,262.796365 70.4121996,269.998849 97.4494594,269.998849 C124.013987,269.998849 148.050919,262.059617 165.137918,247.639301 C182.221105,233.222822 191.63001,212.931541 191.63001,190.50676 C191.63001,164.935449 179.316086,146.41697 155.027538,135.450162" id="Fill-6" fill="#FFFFFF" mask="url(#mask-5)"></path>
                                </g>
                                <path d="M83.3858483,135.890687 C87.5645332,135.890687 93.6945489,136.769504 98.7089708,138.527138 L105,107.158368 C96.4164446,105.005458 88.9638176,104 81.495856,104 C54.2500639,104 30.7535872,122.255596 24.5392311,153.267466 L23.5386469,158.325459 L6.20668882,158.325459 L0,188.896003 L17.5504765,188.896003 L2.37303297,265 L37.9685275,265 L53.145971,188.896003 L81.5456935,188.896003 L87.7485487,158.325459 L59.1341414,158.325459 L60.1155573,153.927538 C62.5959327,141.827497 71.7392019,135.890687 83.3858483,135.890687" id="Fill-9" fill="#FFFFFF"></path>
                                <polygon id="Fill-11" fill="#FFFFFF" points="111.260291 108 80 266 115.735884 266 147 108"></polygon>
                                <path d="M522.083985,111.246386 C504.207307,94.1725866 479.789054,84.3633326 451.474603,82.8696157 L450.748193,82.8311179 L451.136647,82.219002 L501.041402,5 L428.116831,5 L407.785121,36.9878695 C414.979299,50.1541367 418.626887,64.9296143 418.626887,80.9177744 C418.626887,101.979952 411.172444,120.112443 397.056006,133.374955 C401.973841,136.812813 406.444953,140.889736 410.352805,145.482531 C410.352805,145.482531 421.035305,134.210358 442.979102,134.210358 C455.778679,134.210358 467.467276,138.837801 475.888971,147.230333 C483.778483,155.099295 488.300093,165.886395 488.300093,176.827486 C488.300093,197.59323 471.347937,219.063485 442.979102,219.063485 C435.711118,219.063485 428.788858,217.577468 422.410434,214.636232 C417.228451,232.179705 406.3828,247.467204 391,258.920317 C406.495452,266.273407 423.983675,270 442.979102,270 C504.413188,270 549,229.850585 549,174.521464 C549,150.290913 539.440134,127.815864 522.083985,111.246386" id="Fill-13" fill="#FFFFFF"></path>
                            </g>
                        </g>
                    </g>
                </svg>
     
    </div>
    </div>
    </div>
       </div>