<div class="orders_viewdiff d-none" id="backbtn" (click)="backbtn()"><div class="global_blackarrow mobileorder"></div>Select Different Order </div>

<div class="col-xs-12">
    <div class="orderheaderseason">{{inSeason}}
        Season</div>
    <div class="orderseasonsubheader">To view orders for another
        season, please select a season above</div>
</div>
<div class="col-lg-5 col-md-5 col-sm-12 col-12 open-order float_left" id="leftsection">

  
    <div class="form-group">
        <div class="col-md-8 col-lg-8 col-sm-8 col-8 no-padding for_tab_view float_left">
            <div class="input-group seach_order">
                <div class="input-group-addon">
                    <i class="fa fa-search"></i>
                </div>
                <input (keyup)="inputsearch($event);applyFilter()" class="form-control" id="open_order_search" name="lookup" type="text"  [(ngModel)]="valformsummary">
            </div>
        </div>
                     <div class="col-md-1 col-2 filterbox float_left"  (click)="filteropen()" style="background-image: url('../assets/images/filter.png');" >
            <span class="select_counter">{{count}}</span>
        </div>
        <div class="col-md-1 col-2 sortbox float_left" (click)="sortopen()" style="background-image: url('../assets/images/sort.png');" [ngClass]="togglesortView?'open':''"></div>
    
         
        <div class="filteropen" [ngClass]="togglefilterView?'open':''">
            <div class="prodsection">
                <div class="filterheader">BY PRODUCT</div>

                <label *ngFor="let item of getfilterdata?.seqirusProductList; let i=index"> <input type="checkbox" class="registration_checkbox" name="prodname"  value={{item}} (click)="incrementcountproduct($event,i);applyFilter()"> <span class="checkbox-button__control"></span>{{item}}</label>
                     
                    </div>
            <div class="statussection">
                <div class="filterheader">BY STATUS</div>
                <div class="row">
                    <div class="col-md-12"  *ngFor="let list of getstatus?.seqirusOrderStatusList;let i=index">
                        <label> <input type="checkbox" class="registration_checkbox" name="status" value={{list}} (click)="incrementcountstatus($event,i);applyFilter()" > <span class="checkbox-button__control" ></span>{{list}}</label>
                    </div>
                    
                </div>
                
              
            </div>
        </div>
        <div class="sortopen" [ngClass]="togglesortView?'open':''">
            <div class="row">
                <div class="col-md-6">
                    <div class="filterheader">ORDER NUMBER</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="1to9" (click)="sortTest('1to9')"> <span class="radio-button__control"></span>1 to 9</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="9to1" (click)="sortTest('9to1')"> <span class="radio-button__control"></span>9 to 1</label>
                </div>
                <div class="col-md-6">
                    <div class="filterheader">LOCATION</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="AtoZ" (click)="sortTest('AtoZ')"> <span class="radio-button__control"></span>A to Z</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="ZtoA" (click)="sortTest('ZtoA')"> <span class="radio-button__control"></span>Z to A</label>
    
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <div class="filterheader">DATE PLACED</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="MostRecent" (click)="sortTest('MostRecent')"> <span class="radio-button__control"></span>MOST RECENT</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="LeastRecent" (click)="sortTest('LeastRecent')"> <span class="radio-button__control"></span>LEAST RECENT</label>
    
    
                </div>
            </div>
        </div>
        
    </div>
    
    <div class="clearfix"></div>
    
    <hr>
    
    <div *ngIf="isLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
     <div *ngIf="!isLoading">
   
    <div class="mat-elevation-z8 " id="orderfiltertable">
        <mat-form-field class="mattablesearch">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="inputsearch($event);applyFilter()" placeholder="Search, filter, or sort your orders">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        
    
     <ng-container matColumnDef="combined" >
 <th class="odertableheader" mat-header-cell *matHeaderCellDef> order Data </th>
      <td mat-cell *matCellDef="let element" (click)="openrightsectio(element.orderId)"> 
        <div class="tablecolum_order1">
         <span>{{element.season}} Season</span>
        <div class="order_id">Order # {{element.orderId}}</div>
        <div class="order_unit"><span class="left-font">Placed On :</span><span class="totalorderquantity_Order left-font" [className]=element.orderId>{{element.orderDate}}</span></div>
        <div class="order_unit"><span class="left-font">{{element.displayProduct1}}</span><span class="totalorderquantity_Order left-font" [className]=element.orderId>{{element.aqivTotal}}</span></div>
        <div class="order_unit"><span class="left-font">{{element.displayProduct2}}</span><span class="totalorderquantity_Order left-font" [className]=element.orderId>{{element.qivcTotal}}</span></div>
        <div class="order_location "><span class="left-font">Shipping Location:</span>
            <span class="order_substaus">
            {{element.delLocation}}</span>
        </div>
        </div>
         <div class="order_status status_ord_complete">{{element.status}}</div>
        </td>
          
 
      </ng-container>
      
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row id="{{row.orderId}}" *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row " *matNoDataRow>
            <td class="mat-cell no-matching">No matching records found</td>
          </tr>
        </table>
   
   <div class="custom-paginator">
    <button class="pagination-buttonOrg" (click)="goToFirstPage()" [disabled]="isFirstPage()">
      <img alt="Navigate to previous page" src="assets/images/pagination-double-prev-arrow-icon.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg" (click)="previousPage()" [disabled]="isFirstPage()">
      <img alt="Navigate to previous page" src="assets/images/pagination-prev-btn.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg" *ngFor="let pge of getPages()" [class.active-page]="isCurrentPage(pge)" (click)="goToPage(pge)">
      {{pge}}
    </button>
    <button class="pagination-buttonOrg" (click)="nextPage()" [disabled]="isLastPage()">
      <img alt="Navigate to next page" src="assets/images/pagination-next-btn.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg" (click)="goToLastPage()" [disabled]="isLastPage()">
      <img alt="Navigate to last page" src="assets/images/pagination-double-next-arrow-icon.svg" class="locations_arrows">
    </button>
    </div>  
 </div>
    </div>
    </div>


    
    
    <div *ngIf="rightisLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="mobileright"  id="rightsection">
    <div *ngIf="!rightisLoading">
    <div class="col-lg-7 col-md-7 col-span-12 right-order  order-details-view float_left ">
      
      <ng-container *ngIf="rightsectionresponse">
        <!-- {{rightsectionresponse | json}} -->
        <!-- {{rightsectionresponse.seasonData[0].orderId}} -->
        <div class="order_details_section col-md-12" id="{{rightsectionresponse.seasonData[0].orderId}}">
    
            <div class="order_status_table">
                <div class="row order_panel_header right-first-row">
                    <div class="col-md-4 col-xs-8">
                        <div class="order_id">
                            Order #<span class="order_id_fetch">{{rightsectionresponse.seasonData[0].orderId}}</span>
                            <!-- <span style="margin-left:20px">{{rightsectionresponse.seasonData[0].status}}</span> -->
                        </div>
                        
                    </div>
                    <div class="col-md-3 col-xs-4">
                        <div class="current_order_status">
                            {{rightsectionresponse.seasonData[0].status}}
                            <!-- <span style="margin-left:20px">{{rightsectionresponse.seasonData[0].status}}</span> -->
                        </div>
                        
                    </div>
                    <div class="col-md-12 contact_inst">
                        <div class="contact_order_txt">
                            &nbsp;Any queries regarding this order?
                            &nbsp; <a href="mailto:service.uk@seqirus.com" title="Contact our Customer Service Team" target="_self">Contact our Customer Service Team</a>
                        </div>
                    </div>
                    <!-- <div class="col-md-7 col-5 button_wrap">
                            <div class="order_button edit_ord">
                                <span class="order_id_fetch">{{rightsectionresponse.seasonData[0].status}}</span>
                            </div>
                            
                     </div> -->
                </div>
                 
                    
              
                <div class="row order_panel_header" >
                    <div class="col-md-2 col-3">
                        <div class="order_status_header">PLACED ON</div>
                        <div class="order_substaus">
                            {{rightsectionresponse.seasonData[0].orderDate | dateformat}}</div>
                    </div>
                    <div class="col-md-2 col-3">
                        <div class="order_status_header">P.O. NUMBER</div>
                        <div class="order_substaus">{{rightsectionresponse.seasonData[0].poNumber}}</div>
                    </div>
                    <div class="col-md-2 col-3">
                        <div class="order_status_header">Credit days</div>
                    <div class="order_substaus">{{rightsectionresponse.seasonData[0].creditDays}}</div>
                    </div>
                   
                    <div class="col-md-2 col-3">
                        <div class="order_status_header">SoR</div>
                        <!-- <i class="fa fa-info tooltip-wrap" matTooltip="Refer to your orders form or return section">
                           </i> -->
                        <div class="order_substaus">{{rightsectionresponse.seasonData[0].sor}}</div>
                    </div>
                    <div class="col-md-4 col-12 mobile_MT">
                        <div class="order_status_header">SHIPPING LOCATION</div>
                        <div class="order_substaus">
                           {{rightsectionresponse.seasonData[0].delLocation}}</div>
                    </div>
                </div>
    
                <div class="row order_panel_header no-border-B" >
                    <div class="col-xs-12 col-md-6"> 
                        <div class="order_status_header quantity_header">aQIV aQIV Doses Delivered</div>
                        <div class="order_substaus margin-T10 margin-B30">
                            <strong class="crnt-ord"><span class="crnt_order_qty">{{rightsectionresponse.seasonData[0].aqivTotal}}</span>
                                units</strong> / <span class="total_qty total_qty0">{{rightsectionresponse.seasonData[0].qivcTotal}}</span>
                        </div>
                        <div class="progress progressUS">
                            <mat-progress-bar mode="determinate" value= {{getProgress(rightsectionresponse.seasonData[0].aqivTotal,rightsectionresponse.seasonData[0].qivcTotal)}}></mat-progress-bar>
                        </div>                                            
                    </div>
                    <div class="col-xs-12 col-md-6"> 
                        <div class="order_status_header quantity_header">QIVc QIVc Doses Delivered</div>
                        <div class="order_substaus margin-T10 margin-B30">
                            <strong class="crnt-ord"><span class="crnt_order_qty">{{rightsectionresponse.seasonData[0].aqivTotal}}</span>
                                units</strong> / <span class="total_qty total_qty0">{{rightsectionresponse.seasonData[0].qivcTotal}}</span>
                        </div>
                        <div class="progress progressUS">
                            <mat-progress-bar mode="determinate" value= {{getProgress(rightsectionresponse.seasonData[0].aqivTotal,rightsectionresponse.seasonData[0].qivcTotal)}}></mat-progress-bar>
                        </div>                                            
                    </div>
                    
                </div>
                <div class="order_panel_header no-padding-bottom">
                    <table mat-table [dataSource]="dataSource2" multiTemplateDataRows class="table order-details-table">
                        <ng-container matColumnDef="baseProdName">
                            <th mat-header-cell *matHeaderCellDef>Product</th>
                            <td  mat-cell *matCellDef="let element"> {{element.baseProdName}} </td>
                          </ng-container>
                          
                        <!-- Quantity Column -->
                        <ng-container matColumnDef="confirmedQty">
                            <th mat-header-cell *matHeaderCellDef> Volume </th>
                            <td mat-cell *matCellDef="let element"> <span class="ord_qty">{{element.quantityInDoses}}</span>&nbsp;doses<br> <i>(<span class="addComma">{{element.quantityInPacks}}</span>
                                packs)
                        </i> </td>
                          </ng-container>
                          <!-- Price Per Dose Column -->
                        <ng-container matColumnDef="listPrice">
                            <th mat-header-cell *matHeaderCellDef> List Price </th>
                            <td mat-cell *matCellDef="let element"> £ {{element.listPrice}} </td>
                          </ng-container>
                           <!-- Total Price Column -->
                        <ng-container matColumnDef="perDosePrice">
                            <th mat-header-cell *matHeaderCellDef> Total Price (£) </th>
                            <td class="delivery_status" mat-cell *matCellDef="let element"> {{element.perDosePrice}} </td>
                          </ng-container>
                          
                          <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr> 
               
     <ng-container>
      <tr  mat-row *matRowDef="let row; columns:displayedColumns1;let i=index" [class.expanded-row]="row.expanded" ></tr>
    </ng-container> 
    
        <tr class="tabledetailrow" *matRowDef="let row; columns: [];  let i=index" [class.expanded-row]="row.expanded"> 
            <ng-container>

            <td  [attr.colspan]="4">

                <table class="totalTableClass">
                    <thead>
                        <tr>
                            <th class="order_total_row">Total</th>
                            <th class="order_total_row">{{this.finalDosesCount}}  doses</th>
                            <th class="order_total_row">Total Price<br> <span>(Excluding VAT)</span></th>
                            <th class="order_total_row">£ {{this.rightsectionresponse.seasonData[0].totalCost}}</th>
                        </tr>
                    </thead>
                    
                </table>
            </td>
        </ng-container>
    </tr> 
  
                       
                    </table>
                    
                </div>  
                <div class="row order_panel_header">
                    <div class="col-md-12 order_id margin-T20">
                        Delivery Calendar</div>
                    <div class="col-md-12 orders_locationprodtxt margin-L10" style="width: 100%;">
                        This is the current schedule for this order. We do our best to get your doses to you on time, however planned deliveries are subject to change. As deliveries approach you will receive communications via email in addition to your flu360 account remaining up to date with the latest information.</div>

                    <div class="col-md-12 scheduled_del" *ngIf="deliveriesTable?.data[0].detailedStatus == 'Planned'">
                        <div class="col-xs-12 col-md-12 orders_nextdeliverble">Scheduled Delivery Date</div>
                        <div class="col-xs-12 col-md-12 orders_monthdelverytxt">22/09/25- 26/09/25</div>
                                <div class="col-xs-12 col-md-12 orders_nextdeliverble margin-T20 margin-B10">
                                Products</div>                          
                                <div class="col-md-12 total_product_delivery_first">
                                    <span class="addComma">{{deliveriesTable.data[0].aqivTotal}}</span>&nbsp; doses&nbsp; 
                                    {{deliveriesTable.data[0].displayProduct1}} <span class="gap_spacer"></span>
                                    <span class="addComma">{{deliveriesTable.data[0].qivcTotal}}</span>&nbsp; doses&nbsp; 
                                    {{deliveriesTable.data[0].displayProduct2}} <span class="gap_spacer"></span>
                                    </div>
                        </div>
                        <div class="col-md-12 scheduled_del" *ngIf="deliveriesTable?.data[0].detailedStatus == 'Delivered'">
                            <div class="col-xs-12 col-md-12 orders_nextdeliverble">Deliveries Completed</div>
                                    </div>
                       
<div class="row order_details_cal">
    <div class="orders_monthtxt month_duration">
        September &nbsp;2022&nbsp; - March,&nbsp;2023
    </div>
    <div class="col-xs-12 col-md-12 margin-T20 no-padding calender_wrapper">
       

    <div class="col-12 calendar0">
  
                        <full-calendar class=" "  #calendar
                        [options]="calendarOptions0"
                        [deepChangeDetection]="true"
                        [events]="all_events"
                        id="mxCalendar1"
                      ></full-calendar>
                    </div>
                    <div class="col-12 calendar1">
                      <full-calendar  class="" #calendar1
                      [options]="calendarOptions1"
                      [deepChangeDetection]="true"
                      [events]="all_events"
                      id="mxCalendar2"
                    ></full-calendar> 
                </div>
                <div class="col-12 calendar2">
                    <full-calendar  class=" "  #calendar2
                    [options]="calendarOptions2"
                    [deepChangeDetection]="true"
                    [events]="all_events"
                    id="mxCalendar3"
                  ></full-calendar>
                </div>
                </div>
                <div class="col-md-12 order-details-calenderindicator">
                    <div class="order-details-calenderschedule">
                        <div class="order-details-calenderdeliveredcircle"></div>
                        <div class="order-details-calenderdeliveredname">
                            Delivered</div>
                    </div>
                    <div class="order-details-calenderschedule">
                        <div class="order-details-calenderdeliveredsquare"></div>
                        <div class="order-details-calenderdeliveredname">
                            Scheduled Delivery Date</div>
                    </div>
                    <div class="order-details-calenderschedule">
                        <div class="order-details-calenderdeliveredsquareoval"></div>
                        <div class="order-details-calenderdeliveredname">
                            Planned Delivery Window</div>
                    </div>

                </div> 
            </div>
                <!-- <div
                class="col-xs-12 col-md-12 margin-T20 no-padding calender_wrapper">
                <div id="openorder_calendar0_{{rightsectionresponse.seasonData[0].orderId}}"
                    class="col-xs-12 order_details_cal calendar0"><full-calendar id class="calendar0"  #calendar
                    [options]="calendarOptions0"
                    id="mxCalendar"
                  ></full-calendar></div>
                <div id="openorder_calendar1_{{rightsectionresponse.seasonData[0].orderId}}"
                    class="col-xs-12 order_details_cal calendar1">
                    <full-calendar  class="calendar1" #calendar1
                    [options]="calendarOptions1"
                    id="mxCalendar"
                  ></full-calendar></div>
                <div id="openorder_calendar2_{{rightsectionresponse.seasonData[0].orderId}}"
                    class="col-xs-12 order_details_cal calendar2">
                    <full-calendar  class="calendar2"  #calendar2
                    [options]="calendarOptions2"
                    id="mxCalendar"
                  ></full-calendar>
                </div>
            </div> -->
           
               
                </div>
                





                <div class=" order_panel_header">
                    <div class="col-md-12  deliveriesClass order_id">
                        Deliveries</div>
                    <div class="col-md-12 no-padding">
                        <div id="openordershipmenttable_{{rightsectionresponse.seasonData[0].orderId}}_wrapper" class="dataTables_wrapper shipping_history no-footer">
                            <!-- <table id="openordershipmenttable_0010465981" class="display table order-details-table shipping_history dataTable" role="grid">
                        <thead>
                            <tr role="row"><th class="sorting_disabled" rowspan="1" colspan="1"></th></tr>
                        </thead><tbody><tr role="row"><td>
                        <div class="delivery_customNew"><span>Status</span><span>Scheduled</span></div>
                        <div class="delivery_customNew deliverySecond"><span>Expected Delivery Date</span><span>28/11/22</span></div>
                        <div class="delivery_customNew"><span>aQIV  Volume</span><span>(10 doses)<br>(1 packs)</span></div>
                        <div class="delivery_customNew"><span>QIVc  Volume</span><span> (0 doses)<br>(0 packs)</span></div>
                        <div class="delivery_customNew"><span>Track Delivery</span><span class="deliveryTrackingLink"><a target="_blank" href="https://preprod-portal.alloga.co.uk/csl-seqirus/startup.php?order_id=0080487492"><i class="global_blackarrow"></i></a></span></div></td></tr>
                    </tbody></table> -->
                    <table mat-table  class="table order-details-table" [dataSource]="dataSource4" >
                        
                        <ng-container matColumnDef="detailedStatus">
                          <th mat-header-cell *matHeaderCellDef> Status </th>
                          <td mat-cell *matCellDef="let element"> {{element.detailedStatus}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="deliveryDate">
                          <th mat-header-cell *matHeaderCellDef> Expected Delivery Date </th>
                          <td mat-cell *matCellDef="let element"> {{element.deliveryDate}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="volume1">
                          <th mat-header-cell *matHeaderCellDef> aQIV Volume </th>
                          <td mat-cell *matCellDef="let element"> <span [innerHTML]="element.volume1"></span> </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="volume2">
                          <th mat-header-cell *matHeaderCellDef> QIVc Volume </th>
                          <td mat-cell *matCellDef="let element"> <span [innerHTML]="element.volume2"></span> </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="orderedDose">
                          <th mat-header-cell *matHeaderCellDef> Track Delivery </th>
                          <td mat-cell *matCellDef="let element">
                            <a [href]="element.trackDelivery" target="_blank"><i class="global_blackarrow"></i></a>
                          </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayproduct"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayproduct;"></tr>
                      </table>
                </div>
                    </div>
                </div>
    

            </div> 
        </div>
        
    </ng-container> 
    </div>
</div>
</div>
    