<div *ngIf="!userLoggedIn">
<div id="influenza-burden-hero" *ngIf="banner | async as item" class="row-flex bg--grey-100">
    <div class="container col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <h3 class="line" [innerHTML]="item.headline"></h3>
        <a href="{{item.urlLink}}">{{item.content}}<img alt="Next screen" src="/assets/images/whitearrow.png"></a>
    </div>
</div>
</div>
<!-- <img src="../../../assets/images/cells-bg.png"> -->
<!-- <a href="{{item.link1url}}"><div class="button--hero-outline-red">{{item.link1content}}</div></a>
            <a href="{{item.link2url}}"><div class="button--hero-outline-grey">{{item.link2content}}</div></a>
         -->