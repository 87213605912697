import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-slide-resources',
  templateUrl: './custom-slide-resources.component.html',
  styleUrl: './custom-slide-resources.component.scss'
})
export class CustomSlideResourcesComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, public accessService: ContentService, public cd: ChangeDetectorRef, private userInfoService: UserInfoService) {
  }
  slideNode: Observable<any> = this.component.data$;
  mergedObject1: any = [];
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  userLoggedIn: boolean = false;
  // ngOnInit() {
  //   this.slideNode.subscribe(res=>{
  //    console.log('Received datafinal:', res);
  //    const tilesList = res.flu360VaccineSlideResources.split(" ")
  //    tilesList.forEach((el:any) => {this.accessService.allproductsslides(el).subscribe(res=>{
  //      this.mergedObject1 = [...this.mergedObject1, res]
  //      this.cd.detectChanges();
  //      console.log(this.mergedObject1);
  //     })})
  //   })

  //  }
  ngOnInit() {
    this.slideNode.subscribe(res => {
      this.slideresources(res);
    });
  }

  slideresources(res: any) {
    const tilesList = res.flu360VaccineSlideResources.split(" ");
    const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

    combineLatest(observables).pipe(
      map((results: any) => results.flat())
    ).subscribe((mergedResults: any) => {
      this.mergedObject1 = mergedResults;
      this.cd.detectChanges();
      console.log(this.mergedObject1);
    });
    //for looged, loggedout
    this.userInfoService.userLoggedIn$.subscribe(res => {
      console.log('login check', res);
      this.userLoggedIn = res;
    })
  }
}
