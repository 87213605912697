<div class="container" *ngIf="leftBannerNode | async  as item">
    <div class="col-sm-12 text-center">
        <h2 class="head-line">{{item.headLine}}</h2>
        <p class="text-center top-text">{{item.subHeadLine}}.</p>
        <div class="row-flex large-carousel-desktop-row">
        </div>
    </div>
</div>
<div id="custom-Carousel" class="carousel slide desktop-carousel" data-bs-ride="carousel">
    <div class="large--carousel-btn-container">
        <button class="custom-carousel-control-btn custom-carousel-control-prev" type="button" (click)="goprevious()">
            <span class="custom-icon prev-icon">
                <img alt="Navigate to previous arrow" src="assets/images/prev-btn.svg">
            </span>
        </button>
        <button class="custom-carousel-control-btn custom-carousel-control-next" type="button" (click)="gonext()">
            <span class="custom-icon next-icon">
                <img alt="Navigate to previous arrow" src="assets/images/next-btn.svg">
            </span>
        </button>
    </div>
    <div class="carousel-inner">
        <div *ngFor="let item of mergedObject1; let i = index" class="carousel-item1"
            [ngClass]="{'active': activeIndex1 === i}">
            <div class="row slide--content d-flex">
                <div class="col-lg-4 col-md-12 col-sm-12 left-column">
                    <h1 [innerHTML]="item.headline"></h1>
                    <p>{{item.largeparagraphcontent}}</p>
                    <a href="https://www.seqirus.us/expertise" >Learn more about our global capabilities and expertise at seqirus.us<img alt="Next screen" src="../../../app/../assets/images/blackarrow.png"></a>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 right-column">
                    <img [src]="getImage(item.media)" alt="slide-image" class="d-block w-100">
                </div>
            </div>
        </div>
    </div>
</div>
 
<!---below for mobile-->
<div id="mobilecustomCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner mobile-carousel-inner">
        <div *ngFor="let item of mergedObject1; let i = index" class="carousel-item1"
            [ngClass]="{'active': activeIndex1 === i}">
            <div class="row slide--content d-flex">
                <div class="top-row">
                    <img [src]="getImage(item.media)" alt="slide-image" class="d-block w-100">
                </div>
                <div class="bottom-row">
                    <h1 [innerHTML]="item.headline"></h1>
                    <p>{{item.largeparagraphcontent}}</p>
                    <h1 [innerHTML]="item.h2content"></h1>
                    <a href="https://www.seqirus.us/expertise" >Learn more about our global capabilities and expertise at seqirus.us<img alt="Next screen" src="../../../app/../assets/images/blackarrow.png"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="large--carousel-btn-container mobile-carousel-btn-container">
        <div class="custom-carousel-control-btn custom-carousel-control-prev" type="button" (click)="goprevious()">
            <span class="custom-icon prev-icon">
                <img alt="Navigate to previous arrow" src="assets/images/prev-btn.svg">
            </span>
        </div>
        <div class="custom-carousel-control-btn custom-carousel-control-next" type="button" (click)="gonext()">
            <span class="custom-icon next-icon">
                <img alt="Navigate to previous arrow" src="assets/images/next-btn.svg">
            </span>
        </div>
        <div class="carousel-indicators">
            <button *ngFor="let item of mergedObject1; let i = index" type="button" [class.active]="activeIndex1 === i"
                (click)="setActiveIndex1(i)">
            </button>
        </div>
    </div>
</div>