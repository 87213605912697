import { NgModule } from '@angular/core';
import { ConfigModule, provideConfig } from '@spartacus/core';
import { LayoutConfig, defaultLayoutConfig } from '@spartacus/storefront';



@NgModule({
  declarations: [],
  imports: [
  ],

  providers:[
    provideConfig(
      <LayoutConfig>{
        ...defaultLayoutConfig,
        layoutSlots: {
       
          header:{
              lg:{
              slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot'],
                 },
				      sm:{
                  slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot','SideContent'],
                 },
              xs:{
                  slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot','SideContent'],
                 }
          },
          LandingPage2Template:{
            slots:['AlertMessageSlot','homepageBanner','SeqirusPISlot','homepageproducts','planningSection','solutionsSection','strengthenSection','featuredResourcesSection','BroughtybSection','SectionReferences1-LandingPage2','HomepageJobCodeSlot'],
          },
          LandingPage1Template:{
            slots:['HeaderLinksSlot','TopHeaderSlot','Section1','Section2A','Section2C','Section3','seqirusOverviewMultipleNeed-Slot','Section6','BottomHeaderSlot'],
          },
          AccountPageTemplate:{
            lg:{
              slots: ['SideContent','BodyContent', 'MyOrdersSection-MyOrdersPage','LeftContent']
            },
            sm:{
              slots :['BodyContent','MyOrdersSection-MyOrdersPage','LeftContent'],
            },
            xs:{
              slots :[ 'BodyContent','MyOrdersSection-MyOrdersPage','LeftContent'],
            }
          },
          InvoicePageTemplate:{
            lg:{
              slots: ['SideContent','BodyContent','LeftContent']
            },
            sm:{
              slots :['BodyContent','LeftContent'],
            },
            xs:{
              slots :[ 'BodyContent','LeftContent'],
            }
          },
          PaymentStatusPageTemplate:{
            lg:{
            slots:['MiddleContent']
          },
          sm:{
            slots :['MiddleContent'],
          },
          xs:{
            slots :['MiddleContent'],
          }
          },
        LoginPageTemplate:{
          lg:{
            slots:['BodyContent']
          }
        },       
        GigyaRegistrationPageTemplate:{
          // lg:{
            slots:['LeftContentSlot','BodyContent'],
          // }
        },
        GigyaProfileVerificationPageTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaChangePasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaChangePasswordSuccessTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaForgotPasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaResetPasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        ProfileActivationPageTemplate:{
          slots:['LeftContentSlot','BodyContentSlot1','BodyContentSlot2','BodyContentSlot3',]
        },
        JoinAccountTemplate:{
          slots:['SideContent','BodyContent']
        },
        VerificationLinkExpiredPageTemaplate:{
          slots:['LeftContentSlot','BodyContentSlot1']
        },
        VerificationEmailSentPageTemplate:{
          slots:['LeftContentSlot','BodyContentSlot1']
        },
        RegistrationPageTemplate:{
          slots:['registerBodyContentSlot','RegisterThankyouContentSlot']
        },
        paymentInformationPageTemplate:{
           
          slots: ['SideContent','BodyContent','LeftContent']
        },
        OperationalEfficiencyNewPageTemplate:{
          slots:['AlertMessageSlot','SeqirusOperationalEfficiencyFirst-Slot','seqirusOperationalEfficiencySecond-Slot','seqirusOperationalEfficiencyMultipleNeed-Slot','SeqirusOperationalEfficiencyThird-Slot','SeqirusOperationalEfficiencyFourth-Slot']
      },
      ClinicalSupportNewPageTemplate:{
        slots:['AlertMessageSlot','SeqirusClinicalSupportFirst-Slot','SeqirusClinicalSupportSecond-Slot','SeqirusClinicalSupportMultipleNeed-Slot','SeqirusClinicalSupportThird-Slot','SeqirusClinicalSupportFourth-Slot']   
       },
       Influenza65PlusNewPageTemplate:{
        slots:['AlertMessageSlot','SeqirusInfluenzaPlusFirst-Slot','SeqirusInfluenzaPlusSecond-Slot','SeqirusInfluenzaPlusThird-Slot','SeqirusInfluenzaPlusFourth-Slot','SeqirusInfluenzaPlusFifth-Slot','SeqirusInfluenzaPlusSixth-Slot']
       },
       AllProductsPageTemplate:{
         slots:['AlertMessageSlot','SideContent','SeqirusLOAllProductFirst-Slot','SeqirusLOAllProductSecond-Slot','SeqirusLOAllProductThird-Slot','SeqirusLOAllProductFourth-Slot','SeqirusLOAllProductFifth-Slot']
       },
      //  FaqsPageTemplate:{
      //   slots:['AlertMessageSlot','SideContent','SeqirusHelpAndFAQLOFourth-Slot','SeqirusHelpAndFAQLOFirst-Slot','SeqirusHelpAndFAQLOSecond-Slot','SeqirusHelpAndFAQLOThird-Slot']
      // },
      FaqsPageTemplate:{
        slots:['SideContent','BodyContent']
      },
      fluadClinicalDataPageTemplate:{
        slots:['SideContent','BodyContent']
      },
       AboutSeqirusPageTemplate:{
        slots:['AlertMessageSlot','seqirusaboutSection1-aboutPage','seqirusaboutusoptionSection1-aboutPage','seqirusaboutusFLuworldSection1-aboutPage','seqirusaboutusReferenceSection1-aboutPage']
       },
       adjuvantedFluadPageTemplate:{
        slots:['AlertMessageSlot','SideContent','SeqirusFluadAdjuvantFirst-Slot','SeqirusFluadAdjuvantSecond-Slot','SeqirusFluadAdjuvantThird-Slot','SeqirusFluadAdjuvantFourth-Slot','SeqirusFluadAdjuvantBanner-Slot','SeqirusSafetyInfoComponent']
 
       },
       FlucelvaxPageTemplate:{
         slots:['AlertMessage','SeqirusFlucelvaxFirst-Slot','SeqirusFlucelvaxSecond-Slot','SeqirusFlucelvaxThird-Slot','SeqirusFlucelvaxFourth-Slot','SeqirusFlucelvaxFifth-Slot','SeqirusFlucelvaxSixth-Slot','SeqirusFlucelvaxSeventh-Slot']
       },
        CartPageTemplate: {
          lg:{
            slots: ['SideContent','CenterLeftContentSlot']
          },
          sm:{
            slots :['CenterLeftContentSlot'],
          },
          xs:{
            slots :['CenterLeftContentSlot'],
          }
        },
        CreateOrderPageTemplate: {
          slots:['SideContent', 'BodyContent']
        },
        footer:{
            lg:{
            slots:['Footer'],
            }, 
        }
         
      },
      }
    )
  ]
})
export class LayoutModule { }
