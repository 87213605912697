<!-- <p>clinical-fluad-third works!</p> -->
<div class="clinicalimgbanner" *ngIf="thirdheadline | async as item">
    <div class="imagecontainer">
        <div class="simple-banner banner__component--responsive">
            <img class="js-responsive-image"
                alt="a medical technician in a clean room inspecting a tray of influenza vaccine"
                title="a medical technician in a clean room inspecting a tray of influenza vaccine"
                [src]="getImage(item.media)">
        </div>
        <div class="simple-banner-mobile banner__component--responsive-mobile">
            <img class="js-responsive-image-mobile"
                alt="a medical technician in a clean room inspecting a tray of influenza vaccine"
                title="a medical technician in a clean room inspecting a tray of influenza vaccine"
                [src]="getMobileImage(item.media)">
        </div>
        <div class="imagebannerbox">
            <div class="imagebannertext" [innerHTML]="item.headLine"></div>
        </div>
    </div>
    <div class="imagebtmtext">{{item.bottomContent}}</div>
</div>