import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { BaseSiteService, CmsNavigationComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ContentService } from '../../spartacus/services/content.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-custom-left-navigation',
  templateUrl: './custom-left-navigation.component.html',
  styleUrl: './custom-left-navigation.component.scss'
})
export class CustomLeftNavigationComponent {
  currentSite: string="";
  flucelvaxPdf!: Observable<any>;
  fluadPdf!: Observable<any>;
  afluriaPdf!: Observable<any>;
  
  constructor(public component: CmsComponentData<CmsNavigationComponent>,
    private userInfoService:UserInfoService,protected contentService: ContentService,
    protected service: NavigationService,private cd:ChangeDetectorRef,
    private usersrvice:UserInfoService,
    protected config: OccConfig, private router: Router, private route: ActivatedRoute,private renderer: Renderer2, private el: ElementRef,private baseSiteService:BaseSiteService) { }
  bannerNode: Observable<NavigationNode | null | undefined | any> = this.service.createNavigation(
    this.component?.data$
  );
  iconClass: string = "fa fa-bars";
  isOpen: boolean = false;
  glyphiconIcon = "glyphicon-menu-down";
  
  afluriaurl: any;
  fluadurl: any;
  flucelvaxurl: any;

  fetchPIPdfData(){
    if(this.currentSite == 'seqirusb2b-flu360'){
      this.afluriaPdf= this.contentService.getNavigation("AfluriaHeaderLink");

      this.fluadPdf= this.contentService.getNavigation("FluadHeaderLink");

      this.flucelvaxPdf= this.contentService.getNavigation("FlucelvaxHeaderLink");
      this.afluriaPdf.pipe(map(val => val.media?.url)).subscribe(res => {
        this.afluriaurl = res;
      });
      this.fluadPdf.pipe(map(val => val.media?.url)).subscribe(res => {
        this.fluadurl = res;
      });
      this.flucelvaxPdf.pipe(map(val => val.media?.url)).subscribe(res => {
        this.flucelvaxurl = res;
      });
    }
   }
   isUserLoggedIn: boolean=false;
  ngOnInit(){

    this.fetchPIPdfData();
   
    this.userInfoService.getMobileHeader().subscribe((res)=>{
      this.isOpen=res;
      if(this.isOpen){
        const temp= <HTMLElement> document.getElementsByClassName("SideContent")[0];
        if(temp){
          temp.style.width='100%';
        }
        
      }
      this.cd.detectChanges();
    })
    this.baseSiteService.getActive().subscribe(siteId => {
      this.currentSite = siteId;
    })
    this.usersrvice.userLoggedIn$.subscribe(res=>{
      this.isUserLoggedIn=res;
    });
  }
  handleIconToggle(node: any) {
    if (node.title == 'Prescribing Information') {
      node.children[0].url = this.fluadurl;
      node.children[1].url = this.flucelvaxurl;
      node.children[2].url = this.afluriaurl;
    }
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
 
  
  isActive(url: string): boolean { 
    if (!url) {
      return false;
    }
    const currentUrlSegment = this.router.url.split('/').pop();
  const nodeUrlSegment = url.split('/').pop();
  return currentUrlSegment === nodeUrlSegment;
    
  // return this.router.url === url || this.router.url.includes(url); 
  }

  ngAfterViewInit() {
    
    setTimeout(()=>{ 
    const childElements = this.el.nativeElement.querySelectorAll('.active');
    childElements.forEach((child: HTMLElement) => {
      
        const parent = child.closest('.collapse');
        if (parent) {
          this.renderer.addClass(parent, 'show');
        }
     
    });
  }, 5000);
  }
  
}