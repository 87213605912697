import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { OrgLocationComponent, getCustomPaginatorIntl } from './org-location/org-location.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { CdkColumnDef } from '@angular/cdk/table';
import { OrgLocationUkComponent } from './org-location-uk/org-location-uk.component';
import { NewLocationUkComponent } from './new-location-uk/new-location-uk.component';

@NgModule({
  declarations: [
    OrgLocationComponent,
    OrgLocationUkComponent,
    NewLocationUkComponent,
    //OrglocformComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    NgxMaskModule,
    /* RouterModule.forChild([{
      path: 'organization-location',
      data: { pageLabel: 'organization-location'}
  }]), */
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusOrganizationLocationComponent : {
          component: OrgLocationComponent ,
        },
      }
    } as CmsConfig),
  // MatDialog,

  ],
  providers:[
    {
      provide:MatPaginatorIntl,useValue:getCustomPaginatorIntl()
    },CdkColumnDef
  ]
})
export class OrgLocationModule { }
