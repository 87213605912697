import { HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AppRoutingModule, CmsPageGuard, LogoutModule, PageLayoutComponent } from "@spartacus/storefront";
import { AppComponent } from './app.component';
import { SmartEditConfig } from '@spartacus/smartedit/root';
import { CustomHeaderModule } from "./custom-header/custom-header.module";
import { LayoutModule } from "@spartacus/storefront";
import { CustomFooterModule } from "./custom-footer/custom-footer.module";
import { CmsCommonModule } from "./cms-common/cms-common.module";
import { BannerModule } from "./banner/banner.module";
import { CustomCreateAccountModule } from "./custom-create-account/custom-create-account.module";
import { CustomGigyaRaasModule } from "./custom-gigya-raas/custom-gigya-raas.module";
import { I18nModule, PageMetaService, provideConfig } from "@spartacus/core";
import { DashboardModule } from "./dashboard/dashboard.module"
import { SeqirusAuthGuard,  } from "./guards/seqirus-auth.guard";
import { RouterModule, Routes } from "@angular/router";
import { CustomAccountModule } from "./custom-account/custom-account.module";
import { JoinAccountModule } from "./join-account/join-account.module";
import { SeqirusNotAuthGuard } from "./guards/seqirus-not-auth.guard";
import { MyOrdersModule} from "./my-orders/my-orders.module"
import { CustomMyProfileModule } from "./custom-my-profile/custom-my-profile.module";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RegistrationModule } from "./registration/registration.module";
import { SummaryModule } from "./summary/summary.module"
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OrgLocationModule } from "./org-location/org-location.module";
import { CustomStartOrderModule } from "./custom-start-order/custom-start-order.module";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FinancialDashboardModule } from "./financial-dashboard/financial-dashboard.module";
import { ContactUsModule } from "./contact-us/contact-us.module";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha";
import { AllInvoicesModule } from "./all-invoices/all-invoices.module";
import {PaymentStatusModule} from "./payment-status/payment-status.module"
import { PaymentInfoModule } from "./payment-info/payment-info.module";
import {OverviewModule} from "./overview/overview.module"
import { CustomOperationalEfficiencyModule } from "./custom-operational-efficiency/custom-operational-efficiency.module";
import { ClinicalSupportModule } from "./clinical-support/clinical-support.module";
import { SpartacusModule } from "./spartacus/spartacus.module";
import { Influenza65PlusComponent } from "./influenza-65-plus/influenza-65-plus.component";
import { Influenza65PlusModule } from "./influenza-65-plus/influenza-65-plus.module";
import { StartOrderUKModule } from "./start-order-uk/start-order-uk.module";
import { CustompagemetatitleService } from "./spartacus/services/custompagemetatitle.service";
import { CustomAllproductsModule } from "./custom-allproducts/custom-allproducts.module";
import { AboutModule } from "./about/about.module";
import { Flu360AsmComponent } from "./flu360-asm/flu360-asm.component";
import { Flu360AsmModule } from "./flu360-asm/flu360-asm.module";
import { HelpAndFaqModule } from "./help-and-faq/help-and-faq.module";
import { ClinicaldataFluadModule } from "./clinicaldata-fluad/clinicaldata-fluad.module";
import { FlucelvaxModule } from "./flucelvax/flucelvax.module";
import { AdjuvantAdvantageModule } from "./adjuvant-advantage/adjuvant-advantage.module";


export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

const routes:Routes=[
  {
      path: 'my-account',
      data: { pageLabel: '/my-account' },
      component: PageLayoutComponent,
      canActivate: [CmsPageGuard,SeqirusAuthGuard]
  },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard,SeqirusNotAuthGuard]
  },
  {
    path: 'my-profile',
    data: { pageLabel: '/my-profile' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard,SeqirusAuthGuard]
  },
  {

    path: 'register/join-account',
    data: { pageLabel: 'register/join-account' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard,SeqirusAuthGuard]
  },
  {

    path: 'orders',
    data: { pageLabel: '/orders' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard,SeqirusAuthGuard]
  },
  {
 
    path: 'start-order',
    data: { pageLabel: '/start-order' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard,SeqirusAuthGuard]
  },
  {
    path: 'payment-information',
    data: { pageLabel: '/payment-information' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard,SeqirusAuthGuard]
  },
  {
    path: 'payment-status',
    data: { pageLabel: '/payment-status' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard,SeqirusAuthGuard]
  },
  {
    path: 'liveassist',
    component: Flu360AsmComponent
  },
]
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CmsCommonModule,
    BrowserModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    EffectsModule.forRoot([]),
    SpartacusModule,
    CustomHeaderModule,
    LayoutModule,
    CustomFooterModule,
    BannerModule,
    CustomCreateAccountModule,
    CustomGigyaRaasModule,
    DashboardModule,
    I18nModule,
    MyOrdersModule,
    I18nModule,
    RouterModule.forChild(routes),
    CustomAccountModule,
    JoinAccountModule,
    CustomMyProfileModule,
    RegistrationModule,
    SummaryModule,
    FinancialDashboardModule,
    MatTableModule,
    MatPaginatorModule,
	MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    OrgLocationModule,
    CustomStartOrderModule,
	NgxMaskModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    ContactUsModule,
    AllInvoicesModule,
    HttpClientModule,
    PaymentStatusModule,
    PaymentInfoModule,
    OverviewModule,
    CustomOperationalEfficiencyModule,
    ClinicalSupportModule,
    Influenza65PlusModule,
    StartOrderUKModule,
    CustomAllproductsModule,
    AboutModule,
    Flu360AsmModule,
    HelpAndFaqModule  ,
    ClinicaldataFluadModule,
    FlucelvaxModule,
    AdjuvantAdvantageModule
  ],
  providers: [provideHttpClient(withFetch(), withInterceptorsFromDi()), provideAnimationsAsync(),{
    provide:PageMetaService,useClass:CustompagemetatitleService
  },
  provideConfig({
    smartEdit: {
      storefrontPreviewRoute: 'cx-preview',
      allowOrigin: 'backoffice.caah8q6d5y-seqirusuk1-d3-public.model-t.cc.commerce.ondemand.com:443',
    },
  } as SmartEditConfig)
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
