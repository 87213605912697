<div id="secondclinicalfluad" *ngIf="secondheadline | async as item">
    <div class="container headings-container">
        <div class="trivalent-formulation"></div>
        <h1 class="product_clinical-header">{{item.headLine}}</h1>
        <div class="product_clinical-text" [innerHTML]="item.subHeadLine"></div>
    </div>
    <div class="mobcontainer1 container">
        <mat-tab-group>
            <mat-tab label="" class="d-none">
                <div class="clinical-second-wrapper">
                    <div class="tab3subtext tab5bodytext1 col-xs-12">{{item.text2Content}}</div>
                    <div class="tab3subtext tab5bodytext2 col-xs-12" [innerHTML]="item.text3Content"></div>
                    <div class="tab3subtext tab5bodytext3 col-xs-12" [innerHTML]="item.text4Content"></div>
                    <div class="panel_body-Image col-xs-12">
                        <div class="simple-banner banner__component--responsive">
                            <img class="js-responsive-image"
                                alt="FLUAD TRIVALENT local and systemic adverse events for randomized trials of adults 65+. Fluad has a similar safety profile to that of a non-adjuvanted, standard- dose influenza vaccine."
                                title="FLUAD TRIVALENT local and systemic adverse events for randomized trials of adults 65+. Fluad has a similar safety profile to that of a non-adjuvanted, standard- dose influenza vaccine."
                                src="/assets/images/FLUAD_demonstrated-safety-profile-table-desktop-1122x415.webp">
                        </div>
                        <div class="simple-banner-mobile banner__component--responsive-mobile">
                            <img class="js-responsive-image-mobile"
                                alt="a medical technician in a clean room inspecting a tray of influenza vaccine"
                                title="a medical technician in a clean room inspecting a tray of influenza vaccine"
                                src="/assets/images/FLUAD_demonstrated-safety-profile-table-mobile-321x811.webp">
                        </div>
                    </div>
                    <div class="panel_body-bottom col-xs-12">
                        <div class="bottomtext2 text2pad">{{item.text1Content}}</div>
                        <div class="bottomtext3">{{item.text5Content}}</div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>