<div id="influenza-burden-65plus">
    <div class="breadcrumbs--page-nav">
        <div class="container">
            <p>
                <a href="/">Home</a>
                <span> &gt; </span>
                <a href="/overview">flu360® Overview</a> 
                <span> &gt; </span><a href="/clinical-support">Clinical Support</a> 
                <span> &gt; </span>
                <strong>Influenza Burden 65+ years</strong></p></div>
    </div>
        <div class="hero--overview" role="img" aria-label="Healthcare professional facing left">
        
    <div class="container" *ngIf="items | async as item">
        <div class="hero--overview-content" >
            <img class="rectangle-down" alt="angled corner" src="../../app/../assets/images/rectangle-down.svg">
                <h1 class="text-black"[innerHTML]="item.headline"></h1>
                <p class="text" [innerHTML]="item.content"></p>
            </div>
<!-- hidden -->
<div class="row-flex center-xs"  *ngIf="items | async as item">
    <div class="hero--overview-content-mobile hide-desktop">
        <img class="rectangle-down" alt="angled corner" src="../../app/../assets/images/rectangle-down.svg">
        <div class="new_header"[innerHTML]="item.headline"></div>
        <p class="text1"[innerHTML]="item.content"></p>
</div>
</div>
</div>
