<div *ngIf="bannerNode | async  as item" class="main_inner-wrapper">
    <div class="home_container container col-xs-12" id="landingHome">
        <div class="container" *ngIf="!userLoggedIn">
            <p class="breadcrumbs-for-products d-none"><a href="/">Home</a><span> &gt; </span><strong>Products</strong></p>
        </div>
       
        <section class="col-xs-12 home_loginsectionUS" id="home_loginsection">
            <div class="simple-banner banner__component--responsive">
                <img class="js-responsive-image" alt="Healthcare professional silhouette"
                    title="Healthcare professional silhouette" [src]="getImage(item.media)">
            </div>
            <div class="col-xs-12 home_topfirst home_stretch">
                <div class="homepagemobuserguideparent col-xs-12 col-md-12  d-md-block d-lg-none d-block d-sm-none">
                    <app-help-widget></app-help-widget>
                </div>
             
                <div class="col-xs-12 col-md-6 col-lg-7" id="home_loginleftsection"
                    *ngIf="item.uid !='seqirus-overview-hero-component'">
                    <div id="home_leftcontent">
                       
                        <h1 id="home_leftheader" class="modified-h1">
                            <div class="global_Lshape">
                                <div class="global_horizontalL"></div>
                                <div class="global_verticalL"></div>
                            </div>
                            <div class="allproducts_heading" [innerHTML]="item.headline"></div>
                        </h1>
                        <div id="home_leftcontenttext">{{item.h2content}}</div>
                        <div class="home_tivbtn" id="home_leftoverviewbtn" [innerHTML]="item.content"></div>
                        <div id="home_leftoverviewtext"></div>
                    </div>
                </div>
                <div *ngIf="item.uid =='seqirus-overview-hero-component'" class="col-xs-12 col-md-6 col-lg-7"
                    id="overView_loginleftsection">
                    <div id="home_leftcontent">
                        <div class="breadcrumbs-opeff overViewCrumbs" >
                            <p><a href="/">Home</a><span> &gt; </span><a href="/overview"><strong>flu360<sup>®</sup> Overview</strong></a></p>
                        </div>
                        <h1 id="home_leftheader" class="modified-h1">
                            <div class="global_Lshape">
                                <div class="global_horizontalL"></div>
                                <div class="global_verticalL"></div>
                            </div>
                            <div [innerHTML]="item.headline"></div>
                        </h1>
                        <div id="home_leftcontenttext">{{item.h2content}}</div>
                        <div class="overView_tivbtn" id="overView_leftoverviewbtn" [innerHTML]="item.content"></div>
                        <div id="home_leftoverviewtext"></div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5 d-none d-sm-block d-md-none d-lg-block " id="home_loginrightsection">
                    <div id="home_rightcontent">
                        <div class="homepageuserguideparent">
                            <app-help-widget></app-help-widget>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="productsmobile d-none">
        <div class="simple-banner banner__component--responsive">
            <img class="js-responsive-image" alt="Healthcare professional silhouette"
                title="Healthcare professional silhouette" [src]="getImage(item.media)">
        </div>
        <div class="container products-mobile-content col-md-8">
            <h1 id="home_leftheader" class="modified-h1">
                <div class="global_Lshape">
                    <div class="global_horizontalL"></div>
                    <div class="global_verticalL"></div>
                </div>
                <div class="allproducts_heading" [innerHTML]="item.headline"></div>
            </h1>
            <div id="home_leftcontenttext">{{item.h2content}}</div>
            <div id="home_leftoverviewtext"></div>
        </div>
    </div>
</div>

<!-- <form [formGroup]="myForm" (ngSubmit)="onClick()">
    <div>
      <label>
        password:
        <input formControlName="password" placeholder="Your name">
      </label>
    </div>
    <div>
      <label>
        Email:
        <input formControlName="email" placeholder="Your email">
      </label>
    </div>
    <button type="submit">Send</button>
  </form> -->