import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Flu360AsmComponent } from './flu360-asm.component';
import { RouterModule } from '@angular/router';
import { AsmModule } from '@spartacus/asm';
import { AsmComponentsModule, AsmToggleUiComponent } from '@spartacus/asm/components';
import { AsmCoreModule } from '@spartacus/asm/core';
import { AsmOccModule } from '@spartacus/asm/occ';
import { AsmRootModule, AsmLoaderModule } from '@spartacus/asm/root';
import { I18nModule, FeaturesConfigModule } from '@spartacus/core';
import { provideOutlet, OutletPosition, MessageComponentModule, SpinnerModule } from '@spartacus/storefront';
import { TopHeaderComponent } from '../custom-header/top-header/top-header.component';
import { AsmFeatureModule } from '../spartacus/features/asm/asm-feature.module';
import { Flu360AsmLoginComponent } from './flu360-asm-login/flu360-asm-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Flu360AsmSessionTimerComponent } from './flu360-asm-session-timer/flu360-asm-session-timer.component';
import { Flu360AsmCustomerSeletcionComponent } from './flu360-asm-customer-seletcion/flu360-asm-customer-seletcion.component';



@NgModule({
  declarations: [
    Flu360AsmComponent,
    Flu360AsmLoginComponent,
    Flu360AsmSessionTimerComponent,
    Flu360AsmCustomerSeletcionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    AsmCoreModule,
    AsmModule,
    AsmComponentsModule,
    AsmRootModule,
    AsmOccModule,
    AsmLoaderModule,
    MessageComponentModule,
    SpinnerModule,
    FeaturesConfigModule
  ],
  providers: [
    provideOutlet({
      id: 'TopHeaderSlot',
      position: OutletPosition.REPLACE,
      component: Flu360AsmComponent,
    }),
  ],
})
export class Flu360AsmModule { }
