import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BaseSiteService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-new-location-uk',
  templateUrl: './new-location-uk.component.html',
  styleUrl: './new-location-uk.component.scss'
})
export class NewLocationUkComponent {

  addShippingLocForm: FormGroup;
  editedFields: string[]=[];
  @Input() cc_obj: any;
  showThankyouModal: boolean=false;
  @Output() closeModal = new EventEmitter<void>();
  
  constructor(private userService: UserInfoService,private cdr:ChangeDetectorRef,private fb:FormBuilder,public orgLocComponent: CmsComponentData<any>,private dialog:MatDialog,private router:Router,private sanitizer:DomSanitizer,private basesiteService:BaseSiteService) {
    
    const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
  
    this.addShippingLocForm = this.fb.group({
      loc_firstname:["",[Validators.required,this.nameValidator.bind(this)]],
      loc_lastname:["",[Validators.required,this.nameValidator.bind(this)]],
      loc_email:["",[Validators.required,Validators.pattern(emailPattern)]],
      
      loc_phone:["",[Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(10),Validators.maxLength(11)]],
      loc_ext:["",[Validators.pattern('^[0-9]*$'),Validators.maxLength(4)]],
      
      loc_orgname:[""],
      add_AddressLookup:[''],
      loc_add1:['',[Validators.required]],
      loc_add2:[''],
      loc_city:["",[Validators.required]],
      loc_zip:['',[Validators.required,Validators.pattern(/^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$/i)]],
      loc_country:['',[Validators.required]],
      
      loc_licname:['',[Validators.required,this.nameValidator.bind(this)]],
      loc_licnum:['',[Validators.required,Validators.pattern('^[0-9]*$'),this.ukLicNumValidator.bind(this)]],
      loc_nhscode:['']
      
    });
  }

  nameValidator(control:any){
    return /^(?!\s)(?!.*\s$)[A-Za-z][A-Za-z\-\,\s]*$/g.test(control.value)?true:{invalidName:true};
  }

  ukLicNumValidator(control:any){
    const licNum = control.value;
    const org = this.addShippingLocForm?.get('loc_businesstype')?.value;
    
    if (org =="gp medical practice" ||org =="nursing homes" ||org =="wholesaler"){
      return /^\d{7}$/.test(licNum)?true:{invalidLicNum:true};
    }
    if (org =="pharmacy" || org =="PHARMACY" ||org =="hospitals - public" ||org =="hospitals - private"||org =="correctional facility"){
      if(licNum.length < 4){
        return {invalidLicNum:true};
      }
      else{
        return true;
      }
    }
    if (org =="pharmacy" || org =="PHARMACY" ||org =="hospitals - public" ||org =="hospitals - private"||org =="correctional facility"){
      if(licNum.slice(0,1) == 2){
        return /^[2]{1}\d{6}$/.test(licNum)?true:{invalidLicNum:true};
      }
      else{
        return true;
      }
    }
    if (org =="pharmacy" || org =="PHARMACY" ||org =="hospitals - public" || org =="hospitals - private"|| org =="correctional facility"){
      return /^.{4,7}$/.test(licNum)?true:{invalidLicNum:true};
    }
    else{
      return true;
    }

			
  }

  

  addNewLocation(){
    const invalidFields:string[]=[]
    Object.keys(this.addShippingLocForm.controls).forEach((key) => {
      if(this.addShippingLocForm.get(key) && this.addShippingLocForm.get(key)?.invalid){
        invalidFields.push(key);
      }
    });
    console.log(invalidFields);
    if(this.addShippingLocForm.valid){
      
    const addLocData = this.addShippingLocForm.value;

    addLocData.loc_addressId="New";
    addLocData.loc_type="Shipping";
    

    Object.keys(this.addShippingLocForm.controls).forEach((fieldName => {
      if(this.addShippingLocForm.get(fieldName)?.dirty){
        this.editedFields.push(fieldName.substring(fieldName.indexOf('_')+1));
      }
    }))
    addLocData.modifiedQualifiers=this.editedFields.join(',') || "";
    
    this.userService.addOrgLocation(addLocData).subscribe((resposne) => {
      console.log("success",resposne);
      this.showThankyouModal=true;
      this.cdr.detectChanges();
      /* this.thankYouDialogRef.backdropClick().subscribe(() => {
        this.closeModal.emit();
    this.cdr.detectChanges();
      })*/
    }); 
    
    }

  }
  onClose(){
    this.closeModal.emit();
    this.cdr.detectChanges();
    
  }
  ngOnInit(){

  }
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      
      if(chng.currentValue != null){
        
        chng.currentValue.attach({
          search:     'add_AddressLookup',
          town:       'loc_city',
          postcode:  'loc_zip',
          line_1:     'loc_add1',
          line_2:     'loc_add2',
          country: 'loc_country'
        },
        {
          onResultSelected: (c2a: any, elements: any, address: any) => {
            this.addShippingLocForm.controls['loc_add1']?.setValue(address.line_1);
            this.addShippingLocForm.get('loc_add1')?.markAsDirty();
            this.addShippingLocForm.controls['loc_add2']?.setValue(address.line_2);
       this.addShippingLocForm.get('loc_add2')?.markAsDirty();
       this.addShippingLocForm.controls['loc_city']?.setValue(address.locality);
       this.addShippingLocForm.get('loc_city')?.markAsDirty();
       
       this.addShippingLocForm.controls['loc_zip']?.setValue(address.postal_code);
       this.addShippingLocForm.get('loc_zip')?.markAsDirty();
       this.addShippingLocForm.controls['loc_country']?.setValue(address.country_name);
       this.addShippingLocForm.get('loc_country')?.markAsDirty();
    
       this.addShippingLocForm.updateValueAndValidity();
       this.cdr.detectChanges();
       
          }
        }); 
      }
    }
  }
}
