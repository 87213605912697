<div class="product_image-textCenter" *ngIf="node1 | async as item">
    <div class="simple-banner banner__component--responsive">
<img class="js-responsive-image" alt="Adult man holding his young daughter" title="Adult man holding his young daughter" src="../../../app/../assets/images/Image_FLX_Landing_01_LI_Desktop.webp">
</div><div class="container">
        <div class="product_image-textcontainer">
            <div class="product_image-textHeader">{{item.headline1}}</div>
            <div class="product_image-text1" [innerHTML]="item.paragraphcontent"></div>
            <!-- <a href="{{item2.linkUrl}}" [innerHTML]="item2.linkName"></a> -->
            <a href="/products/flucelvax/clinical-data">See the clinical Data</a>
        </div>
    </div>
</div>
