<div class="col-md-12 col-sm-12 col-12 padding-R5 myprofile_flex_items padding-right40" id="myProfilepage">
    <div class="myprofile_membership" *ngIf="memberDetails as item">
        <div class="row">
            <div class="col-md-8 col-sm-8 col-8">
                <div class="myprofile_tophybrisheader">Account Membership</div>
            </div>
            <ng-container *ngIf="item?.membershipStatus=='Active'">
                <div class="col-md-3 col-sm-4 col-4" *ngIf="!toggleEdit" (click)="edit()"> 
                    <div class="myprofilemember_editbtn" id="myprofilemember_editbtn">Edit<img src="/assets/images/edit.png" width="20" alt="Edit"></div>
                </div>
            </ng-container>
           
        </div>
        <div class="myprofile_view-membership">
            <div class="row margin-T20">

                <div class="col-md-5 col-sm-6 col-xs-12">
                    <div class="myprofile_hybrisheader">Membership</div>
                    <ng-container *ngIf="item?.membershipStatus=='Pending';else activemember">
                        <span class="myprofile_hybrisheader_txt myprofile_membership-num">{{item?.requestedMembership}}</span>

                    </ng-container>
                    <ng-template #activemember>
                        <span class="myprofile_hybrisheader_txt myprofile_membership-num">{{item?.currentMembership}}</span>

                    </ng-template>
                    <!-- Membership for My profile page-->
                </div>
                <div class="col-md-7 col-sm-6 col-xs-12">
                    <div class="myprofile_hybrisheader myprofile_Mobile-status">Status</div>
                    <span
                        class="myprofile_hybrisheader_txt myprofile_membership-status">{{item?.membershipStatus}}</span>
                    <!-- Membership for My profile page-->
                </div>
                <div *ngIf="item?.membershipStatus=='Pending'" class="col-md-12 col-sm-12 col-xs-12 margin-T20"> <!-- to be shown if status is pending -->
                    <div class="myprofile_membership-PendingText">Your membership is currently being reviewed and will
                        be validated in 1-2 business days. Please contact <a
                            href="mailto:customerservice.us@seqirus.com">flu360 Customer Service</a> with any questions.
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 margin-T20 pendingtextcontainer">
                    <!-- to be shown if status is pending hidden from less and showing on click of submit-->
                    <!-- <div class="myprofile_membership-PendingText">Your membership is currently being reviewed and will be validated in 1-2 business days. Please contact <a href="mailto:customerservice.us@seqirus.com">flu360 Customer Service</a> with any questions.</div> -->
                </div>
            </div>
        </div>
        <div class="myprofile_editview-membership" *ngIf="toggleEdit">
            <form id="myprofile_memberform" [formGroup]="seqirusMemberShipVisibilityForm" method="post">
                <div class="col-xs-12 cart_questionheader">Do you intend to order under this membership this season?
                </div>
                <div class="col-xs-12 memberradiocontainer">
                    <input (click)="toggleMemberDecision('yes')" formControlName="membershipDecision" type="radio" name="membershipDecision" class="memberradiobtn" id="memberradiobtn1"
                        value="yes">
                    <span class="radio-button__control"></span>
                    <label for="memberradiobtn1">Yes, I confirm I want to use this membership for this season</label>
                </div>
                <div class="col-xs-12 memberradiocontainer">
                    <input (click)="toggleMemberDecision('no')" formControlName="membershipDecision" type="radio" name="membershipDecision" class="memberradiobtn" id="memberradiobtn2"
                        value="no">
                    <span class="radio-button__control"></span>
                    <label for="memberradiobtn2">No, I would like to change my membership to the following</label>
                </div>
                <div class="col-md-9 col-xs-11 addmemberfield">
                    <label class="cartmember_inputLabel" for="memberinput">Membership</label>
                    <input  formControlName="memberinput" class="cartmember_input form-control" id="memberinput" name="requestedMembership"
                        placeholder="Enter only one membership here" autocomplete="no">
                    <span class="glyphicon  form-control-feedback"></span>
                    <div class="membererrortext"></div>
                </div>
                <div class="clearboth"></div>
                <div class="col-xs-12 memberradiocontainer">
                    <input (click)="toggleMemberDecision('notsure')" formControlName="membershipDecision" type="radio" name="membershipDecision" class="memberradiobtn" id="memberradiobtn3"
                        value="notsure">
                    <span class="radio-button__control"></span>
                    <label for="memberradiobtn3">I'm not sure</label>
                </div>
                <div class="clearboth"></div>
                <div class="row margin-T30">
                    <div class="col-md-5 col-sm-6 col-xs-12"> </div>
                    <div class="col-md-7 col-sm-6 col-xs-12">
                        <div class="col-md-6 col-sm-6 col-xs-6 no-padding">
                            <button type="button" class="myprofile_cancel" id="myprofilemember_cancel" (click)="edit()">Cancel</button>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 no-padding">
                            <button [disabled]="seqirusMemberShipVisibilityForm.invalid" (click)="submit()" type="button" class="myprofile_submit" id="myprofilemember_submit">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>