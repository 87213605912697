
// import { Component, HostListener } from '@angular/core';
// import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// @Component({
//   selector: 'app-second-adjuvanted-video',
//   templateUrl: './second-adjuvanted-video.component.html',
//   styleUrl:'./second-adjuvanted-video.component.scss',
// })
// export class SecondAdjuvantedVideoComponent {
//   node: Observable<any> = this.component.data$;
//   animationFlag!: boolean;
//   constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
    
// }
// }
// import { animate, state, style, transition, trigger } from '@angular/animations';
// import { Component, HostListener, OnInit } from '@angular/core';
// import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// @Component({
//   selector: 'app-second-adjuvanted-video',
//   templateUrl: './second-adjuvanted-video.component.html',
//   styleUrls: ['./second-adjuvanted-video.component.scss'],
//   animations: [
//     trigger('fadeInOut', [
//       state('fadeIn', style({ opacity: 1, transform: 'scale(1)' })),
//       state('fadeOut', style({ opacity: 0, transform: 'scale(0.6)' })),
//       transition('fadeOut => fadeIn', [
//         animate('2.5s ease-in-out')
//       ]),
//       transition('fadeIn => fadeOut', [
//         animate('2.5s ease-in-out')
//       ])
//     ]),
//     trigger('integrate', [
//       state('integrate', style({ opacity: 1, transform: 'scale(1)' })),
//       transition('* => integrate', [
//         animate('2.5s ease-in-out')
//       ])
//     ])
//   ]
// })
// export class SecondAdjuvantedVideoComponent implements OnInit {
//   node: Observable<any> = this.component.data$;
//   animationFlag = true;
//   constructor(
//     public component: CmsComponentData<CmsBannerComponent>,
//     private cmsService: CmsService,
//     protected config: OccConfig
//   ) {}
//   ngOnInit(): void {
//     if (document.body.classList.contains('page-adjuvantedFluadPage')) {
//       setTimeout(() => {
//         const vennDiagram = document.querySelector('.js-responsive-image.animate-shield3');
//         const shield3 = document.getElementById('product_animation-3dshield');
//         const shield2 = document.getElementById('product_animation-2dshield');
//         if (vennDiagram) {
//           vennDiagram.classList.add('fadeIn');
//           setTimeout(() => {
//             vennDiagram.classList.add('fadeOut');
//           }, 2500);
//         }
//         if (shield3) {
//           shield3.style.display = 'inline';
//           setTimeout(() => {
//             shield3.classList.add('fadeOut');
//             setTimeout(() => {
//               shield3.classList.add('integrate');
//             }, 2500);
//           }, 2500);
//         }
//         if (shield2) {
//           shield2.style.display = 'inline';
//           shield2.classList.add('fadeIn');
//         }
//       }, 1000);
//     }
//   }
//   @HostListener('window:scroll', [])
//   onWindowScroll(): void {
//     const container = document.querySelector('.product_animation-container');
//     if (container) {
//       const eT = container.getBoundingClientRect().top - 250;
//       const wSt = window.scrollY;
//       if (wSt > eT && this.animationFlag) {
//         this.shieldAnimation();
//       }
//     }
//   }
//   shieldAnimation(): void {
//     const vennDiagram = document.querySelector('.js-responsive-image.animate-shield3');
//     const shield3 = document.getElementById('product_animation-3dshield');
//     const shield2 = document.getElementById('product_animation-2dshield');
//     if (vennDiagram) {
//       vennDiagram.classList.add('fadeIn');
//       vennDiagram.classList.add('fadeOut');
//     }
//     if (shield3) {
//       shield3.classList.add('fadeOut');
//       shield3.classList.add('integrate');
//     }
//     if (shield2) {
//       shield2.classList.add('fadeOut');
//       shield2.classList.add('fadeIn');
//     }
//     this.animationFlag = false;
//   }
// }
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-second-adjuvanted-video',
  templateUrl: './second-adjuvanted-video.component.html',
  styleUrls: ['./second-adjuvanted-video.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('fadeIn', style({ opacity: 1, transform: 'scale(1)' })),
      state('fadeOut', style({ opacity: 0, transform: 'scale(0.6)' })),
      transition('fadeOut => fadeIn', [
        animate('2.5s ease-in-out')
      ]),
      transition('fadeIn => fadeOut', [
        animate('2.5s ease-in-out')
      ])
    ]),
    trigger('integrate', [
      state('integrate', style({ opacity: 1, transform: 'scale(1)' })),
      transition('* => integrate', [
        animate('2.5s ease-in-out')
      ])
    ])
  ]
})
export class SecondAdjuvantedVideoComponent implements OnInit {
  node: Observable<any> = this.component.data$;
  animationFlag = true;
  constructor(
    public component: CmsComponentData<CmsBannerComponent>,
    private cmsService: CmsService,
    protected config: OccConfig
  ) {}
  ngOnInit(): void {
    if (document.body.classList.contains('page-adjuvantedFluadPage')) {
      setTimeout(() => {
        const vennDiagram = document.querySelector('.js-responsive-image.animate-shield3');
        const shield3 = document.getElementById('product_animation-3dshield');
        const shield2 = document.getElementById('product_animation-2dshield');
        if (vennDiagram) {
          vennDiagram.classList.add('fadeIn');
          setTimeout(() => {
            vennDiagram.classList.add('fadeOut');
          }, 2500);
        }
        if (shield3) {
          shield3.style.display = 'inline';
          setTimeout(() => {
            shield3.classList.add('fadeOut');
            setTimeout(() => {
              shield3.classList.add('integrate');
            }, 2500);
          }, 2500);
        }
        if (shield2) {
          shield2.style.display = 'inline';
          shield2.classList.add('fadeOut');
        }
      }, 1000);
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const container = document.querySelector('.product_animation-container');
    if (container) {
      const eT = container.getBoundingClientRect().top - 250;
      const wSt = window.scrollY;
      if (wSt > eT && this.animationFlag) {
        this.shieldAnimation();
      }
    }
  }
  shieldAnimation(): void {
    const vennDiagram = document.querySelector('.js-responsive-image.animate-shield3');
    const shield3 = document.getElementById('product_animation-3dshield');
    const shield2 = document.getElementById('product_animation-2dshield');
    if (vennDiagram) {
      vennDiagram.classList.add('fadeOut');
    }
    if (shield3) {
      shield3.classList.add('integrate');
    }
    if (shield2) {
      shield2.classList.add('fadeOut');
    }
    this.animationFlag = false;
  }
}