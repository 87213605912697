<div *ngIf="!userLoggedIn">
<div class="product-3col container" *ngIf=" slideNode | async as item">
    <div class="product-3col__body">
        <!-- <input type="hidden" id="webEnabled" value="true">
        <input type="hidden" id="logincheck" value=""> -->
        <div class="product-3col__item" *ngFor="let item2 of mergedObject1; let i= index">
            <img alt="Fluad Quadrivalent logo" [src]="getImage(item2.media)" id="prodFluad_img">
            <h3>{{item2.headline}}</h3>
            <p [innerHTML]="item2.content" class="sup_bold"></p>
            <a href="{{item2.urlLink}}" class="no-underline">{{item2.h2content}}</a>
        </div>
    </div>
    <div class="product-3col__footer">
        <p class="disclaimer--paragraph">{{item.bottomContent}}</p>
    </div>
</div>
</div>
<!--For logged in-->
<div class="LI_allProduct col-xs-12 col-md-10 col-lg-10" *ngIf="userLoggedIn">
<div class="product-3col" *ngIf=" slideNode | async as item1">
    <div class="product-3col__body product-3col__body margin" id="product_row1" *ngFor="let item3 of mergedObject1; let i= index">
        <div class="product-3col__item col-md-6 product-3col__item">
            <h3>{{item3.headline}}</h3>
            <p [innerHTML]="item3.content" class="sup_bold"></p>
            <div class="btnParent">
                <a href="{{item3.urlLink}}" class="no-underline">{{item3.h2content}}</a>
                <a href="start-order" class="no-underline">Start Order</a>
            </div>
        </div>
        <div class="product-3col__item col-md-6 product-3col__item">
            <img alt="Fluad Quadrivalent logo" [src]="getImage(item3.media)" id="prodFluad_img">
        </div>
    </div>
    <div class="speedbump__content"></div>
    <div class="product-3col__footer">
        <p class="disclaimer--paragraph">{{item1.bottomContent}}</p>
    </div>
</div>
</div>