import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpFaqSecondComponent } from './help-faq-second/help-faq-second.component';
import { HelpFaqThirdComponent } from './help-faq-third/help-faq-third.component';
import { ConfigModule } from '@spartacus/core';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    HelpFaqSecondComponent,
    HelpFaqThirdComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CmsCommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusFaqAllSlides:{
          component: HelpFaqSecondComponent
        },
        SeqirusHelpFaqContactUsComponent:{
          component: HelpFaqThirdComponent
        }
      }
    })
  ]
})
export class HelpAndFaqModule { }
