<!-- <p>topnavigation works!</p> -->
<section class="product_common-banner fluad_common-banner">
    <div class="simple-banner banner__component--responsive">
        <img class="js-responsive-image" alt="cellular-level depiction of circulating influenza virus"
            title="cellular-level depiction of circulating influenza virus"
            src="/assets/images/Fluad_CoverImage_LO_Desktop.webp">
    </div>
    <div class="container">
        <img class="common-banner_logo" alt="" src="assets/images/Fluad_TIV2024_US_Logo_RGB.webp">
    </div>
</section>
<div class="product_secondary-navigation fluad-nav hidden-xs hidden-sm" *ngIf="topheadline | async as item">
    <div class="container">  
            <a *ngFor="let item2 of mergedObject1; let i= index" href="{{item2.linkUrl}}" [innerHTML]="item2.linkName" [ngClass]="{'blue-background': i === mergedObject1.length - 1}"></a>
    </div>
</div>