<div id="influenza-carousel-desktop">
  <div class="row-flex bg--grey-0">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 icon-carousel-row">
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 center-xs icon-carousel-col" [class.active]="activeIndex === 0" (click)="setActiveIndex(0)">
              <img alt="microscope" src="../../../app/../assets/images/influenza-icon.svg" class="icon-carousel-img" id="icon-carousel-img1">
              <p>Influenza innovations</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 center-xs icon-carousel-col" [class.active]="activeIndex === 1" (click)="setActiveIndex(1)">
              <img alt="adjuvant technology option" src="../../../app/../assets/images/adjuvent-icon.svg" class="icon-carousel-img" id="icon-carousel-img2">
              <p>Adjuvanted option</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 icon-carousel-col" [class.active]="activeIndex === 2" (click)="setActiveIndex(2)">
              <img alt="virus cell" src="../../../app/../assets/images/cell-icon.svg" class="icon-carousel-img" id="icon-carousel-img3">
              <p>Cell-based option</p>
          </div>
      </div>
      <div class="carousel-bar-line">
          <div class="active-bar" [style.left.%]="activeIndex * 33.33"></div>
      </div>

      <div id="customCarousel" class="carousel slide desktop-carousel">
          <div class="carousel-inner">
              <div *ngFor="let item of mergedObject; let i = index" class="carousel-item" [ngClass]="{'active': activeIndex === i}">
                  <div class="row slide--content d-flex">
                      <div class="col-lg-4 col-md-12 col-sm-12 left-column">
                          <p class="carousel-content" *ngIf="activeIndex === i" [innerHTML]="item.largeparagraphcontent"></p>
                          <div class="link-container" *ngIf="activeIndex === i">
                              <a href [innerHTML]="item.h2content"></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="influenza-carousel-desktop-btn-container">
              <button class="custom-carousel-control-btn custom-carousel-control-previous" type="button" (click)="gotoprevious()">
                  <span class="custom-icon prev-icon">
                      <img alt="Navigate to previous arrow" src="assets/images/prev-btn.svg">
                  </span>
              </button>
              <button class="custom-carousel-control-btn custom-carousel-control-next" type="button" (click)="gotonext()">
                  <span class="custom-icon next-icon">
                      <img alt="Navigate to next arrow" src="assets/images/next-btn.svg">
                  </span>
              </button>
          </div>
      </div>
  </div>
</div>

 
 

 
<!--mobile-->

<div class="carousel-container" *ngIf="isMobile">
  <!-- Mobile Slick Dots -->

  <!-- Carousel Items (Image + Content) -->
  <div class="carousel-content">
  <div *ngFor="let item of mergedObject; let i = index"
       class="carousel-item" [ngClass]="{'active': activeIndex === i}">
  <div class="carousel-image">
  <img [src]="getImage(item)" alt="carousel image">
  </div>
  <div class="carousel-text">
      <p class="carousel-content" *ngIf="activeIndex === i" [innerHTML]="item.largeparagraphcontent"></p>
      <a href [innerHTML]="item.h2content"></a>
  </div>
  <div class="slick-dots">
      <span *ngFor="let item of mergedObject; let i = index"
           [class.active]="activeIndex === i" (click)="onDotClick(i)"></span>
      </div>
  <div class="influenza-carousel-desktop-btn-container">
      <button class="custom-carousel-control-btn custom-carousel-control-previous" type="button" (click)="gotoprevious()">
          <span class="custom-icon prev-icon">
              <img alt="Navigate to previous arrow" src="assets/images/prev-btn.svg">
          </span>
      </button>
      <button class="custom-carousel-control-btn custom-carousel-control-next" type="button" (click)="gotonext()">
          <span class="custom-icon next-icon">
              <img alt="Navigate to next arrow" src="assets/images/next-btn.svg">
          </span>
      </button>
  </div>
  </div>
  </div>
  </div>