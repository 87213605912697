import { Component,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-uk-my-profile',
  templateUrl: './uk-my-profile.component.html',
  styleUrl: './uk-my-profile.component.scss',
 // encapsulation: ViewEncapsulation.None,
})
export class UkMyProfileComponent {

}
