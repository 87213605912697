import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-saftey-info',
  templateUrl: './custom-saftey-info.component.html',
  styleUrl: './custom-saftey-info.component.scss'
})
export class CustomSafteyInfoComponent{ 
  @ViewChild('safetyInfoAnchor') safetyInfoAnchor!: ElementRef;
constructor(public component: CmsComponentData<CmsBannerComponent>,private el: ElementRef,private renderer: Renderer2,private userInfoService:UserInfoService){}
safteyinfodata: Observable<any> = this.component.data$;
isSticky: boolean = true;
showSticky: boolean = true;
 lastScrollY: number = 0;
  // ngAfterViewInit() {
  //   this.checkSticky();  // Initialize on page load
  // }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.checkSticky();
  }
 
  checkSticky() {
    const anchor = document.getElementById('safetyInfoAnchor');
    const safetyInfo = document.getElementById('safetyInfo');
    if (anchor) {
      const scroll = window.scrollY;
      if (scroll !== this.lastScrollY) {  // Only update if there's a change
        const anchorTop = anchor.getBoundingClientRect().top + scroll;
        console.log("sticky response:",anchorTop);
        console.log("sticky2 response:" ,window.innerHeight);
        this.isSticky = scroll <= anchorTop - window.innerHeight;
        this.lastScrollY = scroll;  // Update the last scroll position
      }
    }
  }
 

  scrollToAnchor() {
    if (this.safetyInfoAnchor) {
      console.log('Scrolling to anchor:', this.safetyInfoAnchor);
      this.safetyInfoAnchor.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Anchor element not found');
    }
  }
  userLoggedIn: boolean = false;
  ngOnInit(){
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      console.log('login check',res);
      this.userLoggedIn = res;
      // if(this.userLoggedIn == true){
      //   this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
      // }
    })
  }
}