import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { GigyaRaasComponentData } from '@spartacus/cdc/core';
import { CdcAuthFacade, CdcConfig, CdcJsService } from '@spartacus/cdc/root';
import { AuthService, BaseSiteService, ClientTokenService, LanguageService, OccEndpointsService, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, distinctUntilChanged, tap, take } from 'rxjs';
import { CdcService } from '../../spartacus/services/cdc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CommonApiService } from '../../spartacus/services/common-api.service';

@Component({
  selector: 'app-custom-gigya-raas',
  templateUrl: './custom-gigya-raas.component.html',
  styleUrl: './custom-gigya-raas.component.scss'
})
export class CustomGigyaRaasComponent {
  protected renderScreenSet = true;
  language$: Observable<string> | undefined;
  jsError$: Observable<boolean> | undefined;
  jsLoaded$: Observable<boolean> | undefined;
  currentRoute: string = '';
  setCurrentScreen: string = '';
  isUserLoggedIn: boolean = false;
  editProfile: boolean = false;
  apiErr: string='';
  public constructor(
    public component: CmsComponentData<GigyaRaasComponentData>,
    private baseSiteService: BaseSiteService,
    private languageService: LanguageService,
    private cdcConfig: CdcConfig,
    private winRef: WindowRef,
    private cdcJSService: CdcJsService,
    private zone: NgZone,
    protected cdcAuth: CdcAuthFacade,
    private cdc: CdcService,
    private router: Router,
    public userInfoService: UserInfoService,
    private cd: ChangeDetectorRef,
    private authService:AuthService,private occEndpointsService: OccEndpointsService,
    private commonService: CommonApiService,
    private clientTokenService: ClientTokenService,
  ) {
    let url = this.router.url.split('?')[0].split('/');
    this.currentRoute = url[url.length - 1];
  }

  loadGigyaJs() {
      this.jsLoaded$ = this.cdcJSService.didLoad();
      this.jsError$ = this.cdc.didScriptFailToLoad();
      this.language$ = this.languageService.getActive().pipe(
        distinctUntilChanged(),
        // On language change we want to rerender CDC screen with proper translations
        tap(() => (this.renderScreenSet = true))
      );
  }
  ngOnInit(): void {
   
    this.userInfoService.userLoggedIn$.subscribe(res => {
      this.isUserLoggedIn = res;
      const temp = <HTMLElement>document.getElementsByClassName("GigyaLoginPageTemplate")[1]?.querySelector('.BodyContent');
      if (this.isUserLoggedIn) {
        if (temp) {
          temp!.style.display='none';
        }
      } else {
        if (temp) {
        temp!.style.display='';
        }
      }
    })
    this.loadGigyaJs();
    }

  ngAfterViewInit(){
    document.getElementById('login-container')?.addEventListener('click',this.handleLoginRedirect);
  }

  handleLoginRedirect = (event:Event):void => {
    const target = event.target as HTMLElement;
    if(target.matches('.changepasssuccess_loginbtn')){
      this.router.navigate(['login']);
    }
    
    if(target.matches('.resendemail_btn')){
       const profEmail = (window as any).profileEmail;
        console.log(profEmail);
        localStorage.setItem('email',profEmail);

      if (profEmail) {
        this.clientTokenService.getClientToken().subscribe(res => {
          if (res) {
            let token = res.access_token;
            this.passCalltoVerification(token);
          }
        })
      }
    }
  }

  passCalltoVerification(token: string) {
    const email =localStorage.getItem('email');
    let endpoint = this.occEndpointsService.buildUrl(`Verify/VerifyProfileEmail?emailId=${localStorage.getItem('email')}`);
    let payload = {
      url: endpoint,
      token: token,
      contentType: 'text/xml',
      responseType: 'text',
    }
    this.commonService.getAPICallNoAuth(payload).subscribe({
      next: (res: any) => {
        if (res == 'success') {
          //localStorage.setItem('email', this.emailVerificationForm!.value.expired_email);
          this.router.navigate(['/verificationsent']);
        }
        else {
          this.apiErr = res;
        }
        this.cd.detectChanges();
      },
      error: (e) => {
      },
    });
  }

  /**
   * Display screen set in embed mode
   *
   * @param data - GigyaRaasComponentData
   * @param lang - language
   */
  displayScreenSet(data: GigyaRaasComponentData, lang: string): void {
    if (this.renderScreenSet) {
      this.showScreenSet(data, lang);
    }
    this.renderScreenSet = false;
  }

  /**
   * Show screen set
   *
   * @param data - GigyaRaasComponentData
   * @param lang - language
   */
  showScreenSet(data: GigyaRaasComponentData, lang: string) {
    this.customRegisterEventHandlers();
    
    (this.winRef.nativeWindow as { [key: string]: any })?.[
      'gigya'
    ]?.accounts?.showScreenSet({
      screenSet: data.screenSet,
      startScreen: data.startScreen,
      lang,
      ...(this.displayInEmbedMode(data)
        ? { containerID: data.containerID }
        : {}),
      ...(this.isLoginScreenSet(data)
        ? { sessionExpiration: this.getSessionExpirationValue() }
        : {
          onAfterSubmit: (...params: any[]) => {
           
            this.zone.run(() => {
              this.cdc.onProfileUpdateEventHandler(...params);
              setTimeout(()=>{
                this.handleGigyaProfileEvt(false);
              },300);
            }
            );
           
          },
          
          
        }),
  
    });
    
  }


  protected isLoginScreenSet(data: GigyaRaasComponentData): boolean {
    //data.profileEdit ='true';
    const profileEditScreen: boolean =
      data.profileEdit === 'true' ? true : false;

    return !profileEditScreen;
  }

  protected getSessionExpirationValue(): number {
    if (this.cdcConfig?.cdc !== undefined) {
      const filteredConfigs: any = this.cdcConfig.cdc.filter(
        (conf) => conf.baseSite === this.getCurrentBaseSite()
      );
      if (filteredConfigs && filteredConfigs.length > 0) {
        return filteredConfigs[0].sessionExpiration;
      }
    }
    // Return a default value
    return 3600;
  }

  getCurrentBaseSite(): string {
    let baseSite: string = '';
    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe((data) => (baseSite = data));
    return baseSite;
  }

  /**
   * Check if the component should be displayed in embed mode
   *
   * @param data - GigyaRaasComponentData
   */
  displayInEmbedMode(data: GigyaRaasComponentData): boolean {
    const embedValue: boolean = data.embed === 'true' ? true : false;
    if (embedValue && data.containerID && data.containerID.length > 0) {
      return true;
    }
    return false;
  }
  customRegisterEventHandlers() {
    let baseSite = this.getCurrentBaseSite();
    if (baseSite) {
      this.cdc.customAddCdcEventHandlers(baseSite)
    }
  }

  handleGigyaProfileEvt(evt:boolean) {
    this.editProfile = evt;
    const temp = <HTMLElement>document.getElementsByClassName("BodyContent")[0];
    if (this.editProfile) {
      if (temp) {
        temp.classList.add('profile_view');
      }
    } else {
      if (temp) {
      temp.classList.remove('profile_view');
      }
    }
    this.cd.detectChanges();
  }

  toggleEditValue(evt: any): void {
    this.handleGigyaProfileEvt(evt);    
  }
}
