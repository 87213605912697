import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-opefficiency-first',
  templateUrl: './custom-opefficiency-first.component.html',
  styleUrl: './custom-opefficiency-first.component.scss'
})
export class CustomOpefficiencyFirstComponent {
  // firstheadline: Observable<any> = this.cmsService.getComponentData('SeqirusOperationalEfficiencyFirstComponent');
  leftBannerNode: Observable<any> = this.bannerComponent.data$; 
  userLoggedIn: boolean = false;
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, private userInfoService:UserInfoService) {
     }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
 ngOnInit(): void{
   //for looged, loggedout
   this.userInfoService.userLoggedIn$.subscribe(res=>{
    console.log('login check',res);
    this.userLoggedIn = res;
  })
 }
}
  //    ngOnInit(): void {
  //     this.cmsService.getComponentData('SeqirusOperationalEfficiencyFirstComponent').subscribe(res=>{
  //     this.firstheadline=res;
  //    })
  // }
