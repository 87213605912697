import { Component,AfterViewInit,OnInit,ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CustomfilterPipe } from '../../cms-common/Pipes/customfilter.pipe'; 
import { FormControl } from '@angular/forms';
import { CmsService } from '@spartacus/core';
import { ActivatedRoute } from '@angular/router';

export interface PeriodicElement1 {
  loc: string;
  ordernum: number;
  prodname: string;
  season:string;
  status:string;
  unit:number;

} 
export interface Products {
  materialID: string,
  itemNumber: string,
  quantity: string,
  baseName: string,
  presentationName: string,
  currencyIso: string,
  formattedValue: string,
  doses: string,
  seqId: string,
  sellable: true,
  price: 0
}
@Component({
  selector: 'app-my-orders-left-section',
  templateUrl: './my-orders-left-section.component.html',
  styleUrl: './my-orders-left-section.component.scss',
})

export class MyOrdersLeftSectionComponent implements OnInit,AfterViewInit {
  togglefilterView:boolean=false;
  togglesortView:boolean=false;

  displayedColumns: string[] = ['combined'];
 //dataSource = new MatTableDataSource<any>();
 dataSource:any=[];
 dataSource1= new MatTableDataSource<any>();
 displayedColumns1: string[] = ['fullProductName','totalOrderdQty','totalInvoiceAmount','totalShippedQty','latestStatus']; 
displayproduct:string[]=['quantity','shipmentStatus'];
 dataSourceWithPageSize = new MatTableDataSource<PeriodicElement1>;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort)
sort!: MatSort;
  checkout: any;
  loadedFromSummary: any;
filteropen(){
  this.togglefilterView=!this.togglefilterView;
  this.togglesortView=false;
}
sortopen(){
  this.togglefilterView=false;
  this.togglesortView=!this.togglesortView;
  
}


dropdownValue:any;
getfilterdata:any;
apiresponse:any;
rightsectionresponse:any;
count=0;
getstatus:any
selectedproducts:any[]=[];
selectedstatus:any[]=[];
isLoading = false;
totalshippingquantity:number=0;
////////////////////////////////
rightisLoading = false;
editdata=false;
editdatasource = new MatTableDataSource<any>();
editcolumns: string[]=['fullProductName','price','totalOrderdQty','errorMessage'];
initialQuantities: Products[]=[];
originalQuantities:number[]=[];
totalQuantity: number = 0;
isFormValid:boolean = true;
activemembership:any 
nomembership:any 
membershipdata:any
selectedOrder: string | null = null;
////////////////////////////////////////////////////////////
showingLabel: string = "Showing";
  pageSize: number = 6;
  pageSizeControl = new FormControl(6);
  totalLength = 0;
  currentPage: number = 1;
  valformsummary :any;
  pageSizeOptions: number[] = [5, 10, 15];
firstvalue:string='';
filterValues = {
  search: '',
  filterbyproduct:[''],
  filterbystatus:[''],
};

   constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef,private cmsService:CmsService,private activatedRoute: ActivatedRoute) {
    this.userInfoService.filterproduct().subscribe((data)=>{
      this.getfilterdata=data;
   });
   this.userInfoService.filterstatus().subscribe((data)=>{
    this.getstatus=data;
 });

  // Assign the data to the data source for the table to render
 
   }
 

  ngOnInit(): void {
    
    this.isLoading = true;
    this.userInfoService.getDropdownValue().subscribe(value => {
      this.dropdownValue = value;
      if(this.dropdownValue){
      this.leftsection(this.dropdownValue);
      
      }
      
    }); 

    this.userInfoService.MembershipDetails().subscribe(res=>{
      this.membershipdata = res;
    });
    this.cmsService.getComponentData('Membership-myOrdersMessageComponent1').subscribe(res=>{
      this.activemembership=res;
  });
  this.cmsService.getComponentData('Membership-myOrdersMessageComponent2').subscribe(res=>{
 this.nomembership=res;
});

  }
  ngAfterViewInit(){
    this.cd.detectChanges(); 
  }

    
  inputsearch(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
  
    this.filterValues.search =filterValue.trim().toLowerCase(); 
  }
  incrementcountproduct(event:any,i:number){
   
    if (event.target.checked) {

      this.count++;
      this.selectedproducts.push(event.target.value);
      this.filterValues.filterbyproduct=this.selectedproducts;
     
    } else {
      var index = this.selectedproducts.indexOf(event.target.value);
      this.selectedproducts.splice(index, 1);
      this.filterValues.filterbyproduct=this.selectedproducts;
     this.count--;
    } 
  } 
  incrementcountstatus(event:any,i:number){
   
    if (event.target.checked) {

      this.count++;
      this.selectedstatus.push(event.target.value);
      this.filterValues.filterbystatus=this.selectedstatus;
     
    } else {
      var index = this.selectedstatus.indexOf(event.target.value);
      this.selectedstatus.splice(index, 1);
      this.filterValues.filterbystatus=this.selectedstatus;
     this.count--;
    } 
  } 
  sortTest(val:any){
    let newdir :string = 'desc'
    if(val == '1to9' || val == '9to1'){
    const sortState: Sort = {active: 'ordernum', direction: val == '1to9'?'asc': 'desc'};    
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

    }
    else if(val == 'AtoZ' || val == 'ZtoA'){
      const sortState: Sort = {active: 'loc', direction: val == 'AtoZ'?'asc': 'desc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
   
    }
    else if(val == 'LeastRecent' || val == 'MostRecent'){
      const sortState: Sort = {active: 'ordernum', direction: val == 'LeastRecent'?'asc': 'desc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
 
    }
  }
  leftsection(currentdropdownValue:any){
    this.userInfoService.Myorderleftsection(currentdropdownValue).subscribe(value=>{
      this.apiresponse=value;
      this.dataSource = new MatTableDataSource(this.apiresponse.filterData);
      this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse.filterData);
      this.isLoading = false;
      
      this.cd.detectChanges();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort; 
      this.totalLength = this.apiresponse.filterData.length;
      this.sortTest('9to1');
      this.updateTableData()
      this.firstvalue=this.apiresponse.filterData[0].ordernum;
      this.activatedRoute.queryParams.subscribe(params => {   
        if(params) {
  
        this.loadedFromSummary = params;
        this.filterValues.search = this.loadedFromSummary?.address;
        this.applyFilter();
        this.firstvalue = this.dataSource.filteredData[0]?.ordernum;
        this.valformsummary = this.loadedFromSummary.address
        }
         // Print the parameter to the console. 
    });
      if(this.firstvalue){
      this.openrightsectio(this.firstvalue);
      }else{
   this.rightsectionresponse="";
      }
      
    
 }); 
  }
  applyFilter() {
     
     const filteredData1 = new CustomfilterPipe().transform(this.apiresponse.filterData, this.filterValues);
    this.dataSource = new MatTableDataSource(filteredData1);
    this.cd.detectChanges();
    this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse.filterData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort; 
    this.totalLength = filteredData1.length;
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = filteredData1.slice(starti, endi); 
  }
  getProgress(deliveredQty:number,totalQty:number){
    return (Math.floor((deliveredQty/totalQty)*100))
  }
  updateTableData() {
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = this.apiresponse.filterData.slice(starti, endi);
  }
  scrollToTop(): void { 
    window.scrollTo({ top: 0, behavior: 'smooth' });
   }
  onPageSizeChange(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
    this.updateTableData();
  }

  goToLastPage() {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    this.currentPage = totalPages;
    this.updateTableData();
  } 
 
   goToFirstPage() {
    this.currentPage = 1;
    this.updateTableData();
  }
  
  getPages(): any {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    const pages: any = [];
    const startPage = Math.max(1, this.currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);
 
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
 
 
    }
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    if (endPage < totalPages) {
      pages.push(totalPages);
    }
    return pages;
  }
   goToPage(page: number) {
    this.currentPage = page;
    const starti = (page - 1) * this.pageSize;
    this.dataSource.data = this.apiresponse.filterData.slice(starti, starti + this.pageSize);
  } 
   previousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  } 
     nextPage() {
    const totlpages = Math.ceil(this.totalLength / this.pageSize);
    if (this.currentPage < totlpages) {
      this.goToPage(this.currentPage + 1);
    }
  } 
  isFirstPage(): boolean {
    return this.currentPage == 1;
  }
  isLastPage(): boolean {
    return this.currentPage == Math.ceil(this.totalLength / this.pageSize);
  }
  isCurrentPage(page: number): boolean {
    return this.currentPage == page;
  }

  backbtn(){
    const mobileback=<HTMLElement>document.getElementById("backbtn");
    const mobiletemp=<HTMLElement>document.getElementById("rightsection");
    const left =<HTMLElement>document.getElementById("leftsection");
    left.classList.remove("lefthide");
        mobiletemp.classList.add("mobileright");
        mobileback.classList.remove("backhide");
  }
  openrightsectio(orderid:string){

    const temp= <HTMLElement>document.getElementsByClassName("activeorder")[0];
    if(temp){
    temp.classList.remove("activeorder");
    }
    const temp1= <HTMLElement> <unknown>document.getElementById(orderid);
    temp1.classList.add('activeorder');
    this.rightisLoading = true;
    if(orderid){
    orderid=orderid.split("#")[1];
   
      }
      // Close the edit section if a new order is selected
      this.selectedOrder = orderid;
      this.editdata = false;
    this.userInfoService.MyorderRightsection(this.dropdownValue,orderid).subscribe(value=>{
      this.rightsectionresponse=value;
      this.dataSource1 = new MatTableDataSource(this.rightsectionresponse.productDetailsTable);
      this.rightisLoading = false;
      const mobileback=<HTMLElement>document.getElementById("backbtn");
      const mobiletemp=<HTMLElement>document.getElementById("rightsection");
      const left =<HTMLElement>document.getElementById("leftsection");
      left.classList.add("lefthide");
      mobiletemp.classList.remove("mobileright");
      mobileback.classList.add("backhide");
      this.editdatasource = new MatTableDataSource(this.rightsectionresponse.editOrderProducts);
      this.initialQuantities = this.editdatasource.data.map(item => ({ ...item,}));
       this.originalQuantities = this.editdatasource.data.map(item => item.quantity);
      this.cd.detectChanges();
       
     });
  }
  expandedElement: any | null;

  toggleRow(element: any) {
    element.expanded = !element.expanded;
  //  this.expandedrowdata=element.shippedStatusTable;
    this.cd.detectChanges();
  }
  viewEditTable(){
    this.editdata= !this.editdata;
  }
  onKeyPress(event: KeyboardEvent) {
    const char = event.key;
    if (!/^[0-9]$/.test(char)) {
      event.preventDefault();
    }
  }
  calculateTotalQuantiy():number{
    // const dataArray = this.editdatasource;
    const dataArray = this.editdatasource;
    this.totalQuantity= dataArray.data.reduce((total,element,index)=>{
    if(Number(element.quantity) < this.originalQuantities[index]){
       element.errorMessage = "Sorry, existing order quantities can't be decreased online";
       element.error=true;
       this.isFormValid=false;
      }else{
          element.errorMessage=""
          element.error = false;
      }
     return total + Number(element.quantity);
     },0);
     this.isFormValid = !dataArray.data.some(element=>element.error);
     return this.totalQuantity;
   }
      //method calculates the total amount for each product based on its price and quantity.

  calculateTotalAmount(element:any) {
    var price=element.formattedValue.replace("$","")
    var finalprice= Number(price);
    return (element.quantity)*(finalprice);
 }

   //This method calculates the overall total price by summing up the total amounts of all products.
   calculateTotalPrice() {
    const total = this.editdatasource.data.reduce((acc, element) => acc + this.calculateTotalAmount(element), 0);
    return total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  closeEditOrderArea() {
    //when user clicks on edit but doesnt save it and closes the edit section value go back to previous
    this.editdatasource.data = this.initialQuantities.map(item => ({ ...item }));
    this.editdata = false;
  }
  iseditLoading = false;
  //method to save updated order
  saveOrder(){
    this.iseditLoading = !this.iseditLoading;
  const updatedOrder= this.editdatasource.data.map((item) =>({
    'materialID':item.materialID,
    'itemNo': item.itemNumber,
    'quantity':item.quantity,
  }));
  const newobj = {
    'shipToID':this.rightsectionresponse.shipToID,
    'sapOrderID':this.rightsectionresponse.orderID,
    'maxItemNo':this.rightsectionresponse.maxItemNumber,
    'updateOrderProduct': updatedOrder,
    'poRefnumber':this.rightsectionresponse.poNumber
  }  
const poRefnumber=this.rightsectionresponse.poNumber
const sapOrderID=this.rightsectionresponse.orderID

  this.userInfoService.updateOrder(newobj).subscribe(res=>{
   
    if(res == "cart-success"){
      this.userInfoService.editconfirmorder(poRefnumber,sapOrderID).subscribe((data: any)=>{
        this.checkout=data;
        
      },
      error=>{
        console.error('Error confirming order:' ,error);
      }
      );
    }
    this.editdata = false;
    this.cd.detectChanges();
    // this.iseditLoading = false;
    // window.scrollTo(0, 0);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 6000)
    setTimeout(()=>{
      this.iseditLoading = false;
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    },3000)
   },
   err => {
     console.error('Error updating order', err);
     this.iseditLoading = false;
  })
}


  getTotal(items:any) {
    return items.reduce((sum: any, item: { itemQty: any; }) => sum + item.itemQty, 0);
  }

//till above save method
}




 