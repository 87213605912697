<div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 no-padding joinAcc_UK">
    <div *ngIf="joinAccthankyouData | async as thankYouData" class="joinaccount_thankyou col-xs-12">
        <div class="joinAccount_label padding-B30">{{thankYouData.title}}</div>
        <div class="joinAccount_Rbatext padding-B10">{{thankYouData.text2}}</div>
        <div class="joinAccount_Rbatext padding-B30">{{thankYouData.content}}</div>

         <div class="joinAccount_Rbatext padding-B20">{{thankYouData.paragraphcontent}}</div>
         
             <ng-container *ngIf="joinAccResponse?.firstB2BUnit == 'true';else notNewCust">
                 
                <!-- check cnditonview only -->
                      <!-- <div *ngIf="seqirusb2b-uk.rba.permission.firstuser.account == 'VIEWONLY';else otherpermission" class="joinAccount_Rbatext padding-B30 Rbaordermanage">Ordering <span class="joinaccount_ordering">View Only</span> and Financial <span class="joinaccount_financial">View Only</span></div> -->
                       <div class="joinAccount_Rbatext padding-B30 Rbaordermanage">Ordering <span class="joinaccount_ordering">View Only</span> and Financial <span class="joinaccount_financial">View Only</span></div> 
                      
                      <ng-template #otherpermission>
                           <div class="joinAccount_Rbatext padding-B30 Rbaordermanage">Ordering <div class="joinaccount_firstOrdering">MANAGE</div> and Financial <div class="joinaccount_firstFinancial">MANAGE</div></div>
                      </ng-template>
                 
             </ng-container>
             <ng-template #notNewCust>
                   <div class="joinAccount_Rbatext padding-B30 Rbaordermanage">Ordering <span class="joinaccount_ordering">{{joinAccResponse?.orderingPermission}}</span> and Financial <span class="joinaccount_financial">{{joinAccResponse?.financialPermission}}</span></div>
                </ng-template>
         
            <ng-container *ngIf="joinAccResponse?.firstB2BUnit == 'false'">
             
                      <div *ngIf="joinAccResponse?.adminPermissions.length > 0;else noAdminId" class="joinAccount_Rbatext padding-B30">
                           <div class="padding-B20">{{thankYouData.h2content}}</div>
                           <div class="joinAccount_dynamicId margin-B30">
                               <div *ngFor="let admin of joinAccResponse?.adminPermissions" class="rbacentertextjoinaccount padding-B10">{{admin.name}}<span class="permissionmail"><a href=mailto:admin.emailId>{{admin.email}}</a></span></div>
                           </div>
                           <div [innerHTML]="thankYouData.h3content"></div>
                      </div>
                      <ng-template #noAdminId>
                       <div class="joinAccount_Rbatext padding-B30">
                           <div class="padding-B20">You may request changes by contacting<span class="permissionmail"> <a href=mailto:service.uk@seqirus.com>service.uk&#64;seqirus.com</a></span> for this account</div>
                       </div>
                    </ng-template>
            </ng-container>
             
        <div class="joinAccount_Thankyou-button">
            <a href="/my-account"><button type="button" class="joinAccount_my-profile">Go To My Dashboard</button></a>
        </div>
    </div>
</div>
