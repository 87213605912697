import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  
  private joinAccRespData = new BehaviorSubject<any>(null);
  currentJoinAccData = this.joinAccRespData.asObservable();
  
  constructor(private http: HttpClient, 
    private occ:OccEndpointsService) { 
  }
  public togglemabmenu:boolean=false;

  getnavigation(){
    const url = this.occ.buildUrl("/cms/components/AboutLink"); 
    return this.http.get(url);
  } 
  getimage(){
    const url = this.occ.buildUrl("/cms/components/SiteLogoComponent"); 
     return this.http.get(url);
  } 
  getimage1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusSiteLogoComponent"); 
     return this.http.get(url);
  }
  getNavigation(componentName:string){
    const url = this.occ.buildUrl("/cms/components/"+componentName); 
     return this.http.get(url);
  }
  getAccessResources(){
    const url = this.occ.buildUrl("/cms/components?fields=DEFAULT&currentPage=0&pageSize=16&componentIds=SeqirusResourceProduct1%2CSeqirusResourceProduct2%2CSeqirusResourceProduct3"); 
     return this.http.get(url);
  }
  getMultipleResources(){
    const url = this.occ.buildUrl("/cms/components?fields=DEFAULT&currentPage=0&pageSize=16&componentIds=seqirusClinicalSupportTile%seqirusFinancialGuidanceTile%seqirusOperationalEfficiencyTile"); 
     return this.http.get(url);
  }
  getAccess1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct1"); 
     return this.http.get(url);
  }
  getAccess2(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct2"); 
     return this.http.get(url);
  }
  getAccess3(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct3"); 
     return this.http.get(url);
  }
  // getWhatif1(){
  //   const url = this.occ.buildUrl("/cms/components/seqirusClinicalSupportTile"); 
  //    return this.http.get(url);
  // }
  // getWhatif2(){
  //   const url = this.occ.buildUrl("/cms/components/seqirusFinancialGuidanceTile"); 
  //    return this.http.get(url);
  // }
  // getWhatif3(){
  //   const url = this.occ.buildUrl("/cms/components/seqirusOperationalEfficiencyTile"); 
  //    return this.http.get(url);
  // }
  getBannerNode(){
    const url = this.occ.buildUrl("/cms/components/HomepageUserGuidePDFComponent"); 
    return this.http.get(url);
  }
  multiplewhatif(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  getSearchAccount(params:HttpParams):Observable<any>{
    return this.http.get(this.occ.buildUrl("/users/current/join-account/searchAccount"),{params:params,responseType:'text'});
  }

  getRequestAssistance(params: HttpParams): Observable<any> {
    //console.log(params);
    return this.http.get(this.occ.buildUrl("/users/current/join-account/requestAssistance"),{params:params,responseType:'text',observe:'response'});
  
  }
  submitJoinAccount(data: any): Observable<any> {
    return this.http.post(this.occ.buildUrl("/users/current/join-account/submit"),data);
  
  }
  
  getLeftContentForCreateAccount(){
    const url = this.occ.buildUrl("/cms/components/RegistrationBanner"); 
     return this.http.get(url);
  }
  vsscontent(){
    const url = this.occ.buildUrl("/users/current/my-account/vss?fields=FULL"); 
    return this.http.get(url);
  }
  verifyRecaptcha(captchaResp:string) {
    
    const headers = new HttpHeaders({
      'Accept':'application/json'
    })
    return this.http.get(this.occ.buildUrl(`/users/current/captcha-check/${captchaResp}`),{headers});
  }
  savedCreditDetail(){
    const url = this.occ.buildUrl("/users/current/financials/fetch-saved-cards?fields=DEFAULT");
    return this.http.get(url);
  }

  iFrame(obj: any){
    const url = this.occ.buildUrl("/users/current/financials/addNewCardIngenico?fields=DEFAULT");
    return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
    // return this.http.post(url, {headers:{'Content-Type':'application/json'+'hostedCheckoutId'+returnUrl}});
  }
  //opefficiency 
   //opefficiency featured resources(thirdcomponent)
   opeffeaturedResources(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  //opefficiency second component carousel
  getcarouselslides(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  clinicalSupportSlide1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusFlu360ContentSlide1");
    return this.http.get(url);
  }
  clinicalSupportSlide2(){
    const url = this.occ.buildUrl("/cms/components/SeqirusFlu360ContentSlide2");
    return this.http.get(url);
  }
  clinicalSupportSlide3(){
    const url = this.occ.buildUrl("/cms/components/SeqirusFlu360ContentSlide3");
    return this.http.get(url);
  }
  startorder_Landing(componentid:String){
    const url = this.occ.buildUrl("/cms/components/"+componentid);
    return this.http.get(url);
  }
  startapi(){
    const url = this.occ.buildUrl("/users/current/Start-order/start");
    return this.http.get(url);
  }
  allproductsslides(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  getcarouselstiles(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`);
    return this.http.get(url);
  }
  InflucarouselOptions(tilename: string){
    const url= this.occ.buildUrl(`/cms/components/${tilename}`);
    return this.http.get(url);
  }
  getUKProductDetails(season: string) {
    const getProductUrl = this.occ.buildUrl(`/users/current/Start-order/getProductPageDetails?fields=DEFAULT&season=${season}&selectedLoc=60063573&selectedLoc=60063572&selectedLoc=60063571`);
    return this.http.get(getProductUrl);
  }

  updateJoinAccResponse(response: any) {
    this.joinAccRespData.next(response);
  }
}

