import { ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import { AsmComponentService, AsmMainUiComponent } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import { AsmUi, CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, ClientTokenService, GlobalMessageService, GlobalMessageType, RoutingService, UserIdService, OccEndpointsService, AuthHttpHeaderService } from '@spartacus/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { distinctUntilChanged, filter, map, Observable, of, Subject, Subscription, switchMap, take, tap } from 'rxjs';
import { translationOverwrites } from '../translations/en/custom_asm';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../spartacus/services/user-info.service';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-flu360-asm',
  templateUrl: './flu360-asm.component.html',
  styleUrl: './flu360-asm.component.scss'
})
export class Flu360AsmComponent extends AsmMainUiComponent {
  showASM: boolean = false;
  notCollapsed: boolean = false;
  // override globalMessageType = GlobalMessageType;
  // override iconTypes = ICON_TYPE;
  globalMessageSubscription: Subscription;
  errorMsg$ = new Subject<boolean>();
  errorMsgContent: string = '';
  httpResponse: string = '';
  showResetModal: boolean = true;
  constructor(
    protected override authService: AuthService,
    protected override csAgentAuthService: CsAgentAuthService,

    protected override asmComponentService: AsmComponentService,
    protected override globalMessageService: GlobalMessageService,
    protected override routingService: RoutingService,
    protected override asmService: AsmService,
    protected userService: UserAccountFacade,
    protected userProfile: UserProfileFacade,
    protected override launchDialogService: LaunchDialogService,
    public userInfoService: UserInfoService,
    public userIdService: UserIdService,
    private clientTokenService: ClientTokenService,
    private occEndpointsService: OccEndpointsService,
    private http: HttpClient,
    protected authHttpHeaderService: AuthHttpHeaderService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    super(authService, csAgentAuthService, asmComponentService, globalMessageService, routingService, asmService, userService, launchDialogService);
    this.globalMessageSubscription = this.globalMessageService.get().subscribe((data) => {
      if (data['[GlobalMessage] Error']?.length && data['[GlobalMessage] Error'][0].params) {
        this.errorMsgContent = translationOverwrites.en.customAsm.asm.errorMessage;
        this.errorMsg$.next(true);
      }
    });
  }
  override ngOnInit(): void {
    // if(window.location.href.includes('liveassist')) {
    //   window.location.href = window.location.href.replace("liveassist", "?asm=true");
    // }
    // this.showASM = window.location.href.includes('?asm=true');
    this.showASM = window.location.href.includes('liveassist');
    this.customerSupportAgentLoggedIn$ =
      this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
    this.csAgentTokenLoading$ =
      this.csAgentAuthService.getCustomerSupportAgentTokenLoading();
    this.customer$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          this.handleCustomerSessionStartRedirection();
          return this.userService.get();
        } else {
          return of(undefined);
        }
      })
    );
    this.isCollapsed$ = this.asmService
      .getAsmUiState()
      .pipe(
        map((uiState: AsmUi) =>
          uiState.collapsed === undefined ? false : uiState.collapsed
        )
      );

  }
  hideErrorMessage() {
    this.errorMsgContent = '';
    this.errorMsg$.next(false);
  }
  handleToggleButton() {
    this.notCollapsed = !this.notCollapsed;
  }
  hideASMUi() {
    this.handleToggleButton()
    this.asmComponentService.unload();
  }
  override logout() {
    this.routingService.go(['/']);
    this.asmComponentService.logoutCustomerSupportAgentAndCustomer();
    this.showASM = false;

  }
  override loginCustomerSupportAgent({
    userId,
    password,
  }: {
    userId: string;
    password: string;
  }): void {
    this.csAgentAuthService.authorizeCustomerSupportAgent(userId, password);
    this.clientTokenService.getClientToken().subscribe(data => {
      if (data) {
        const header = {
          'Authorization': `Bearer ${data.access_token}`
        }
        let url = this.occEndpointsService.buildUrl(`users/current`);
        this.http.get<any>(url, { headers: header }).subscribe({
          next: (response: any) => {
            console.log("current asm api response--------------->", response);
          },

          error: (e) => console.error(e),
        })
      }
    })
  }
  onResetClick(email: string) {
    this.clientTokenService.getClientToken().subscribe(data => {
      if (data) {
        const header = {
          'Authorization': `Bearer ${data.access_token}`
        }
        let url = this.occEndpointsService.buildUrl(`/employee/resetAgentPassword?email=${email}&fields=DEFAULT`);
        this.http.get<any>(url, { headers: header }).subscribe({
          next: (response: any) => {
            if (response.value === "NOT_FOUND") {
              this.httpResponse = response.value;
              this.showResetModal = true;
              this.cdr.detectChanges();
            }
            if (response.value === "OK") {
              this.httpResponse = response.value;
              this.showResetModal = false;
              this.cdr.detectChanges();
            }
          },

          error: (e) => console.error(e),
        })
      }
    })

  }
}