<div class="col-xs-12 main helpnfaqcontainer" id="helpsection">
   <div *ngIf="secondnode | async as data">
      <div class="col-xs-12 help_searchheader">{{data.header1}}</div>
      <div class="col-xs-12 help_searchbar">
         <i class="fa fa-search" aria-hidden="true"></i>
         <input type="text" placeholder="Search frequently asked questions" id="help_searchbox"
            [(ngModel)]="searchvalue" (keydown.enter)="applyFilter(searchvalue)">
         <div id="help_searchbtn" (click)="applyFilter(searchvalue)">Go</div>
      </div>
<!--when user searches something invalid-->
      <div *ngIf="shownoresult">
         <div class="col-xs-12 help_noresultsection" *ngIf="secondnode | async as data">
            <div class="help_noresultheader">{{data.subHeadLine}}</div>
            <div class="help_noresultcontent" [innerHTML]="data.subContent"></div>
         </div>
      </div>
<!--code to give search results-->
      <div *ngIf="searchresults">
         <div *ngFor="let item4 of filteredData">
            <div class="accordion col-xs-12 search-accordion">
               <div class="accordion-item search-accordion-item">
                  <div class="accordion-header search-accordion-header" (click)="toggleAccordion(item4)">
                     <span class="accordion-text">{{item4.text2}}</span>
                     <span class="search-arrow-icon">
                        <!-- <i class="fa fa-chevron-down"></i> -->
                        <i class="fa" [ngClass]="{'fa-chevron-down': item4.isOpen, 'fa-chevron-up': !item4.isOpen}"></i>
                     </span>
                  </div>
                  <div class="accordion-content search-accordion-content" *ngIf="item4.isOpen"
                     [innerHTML]="item4.content">
                  </div>
               </div>
            </div>
         </div>
      </div>
<!--All accoerions appear at first and help button section-->
      <div *ngIf="!shownoresult && !searchresults">
         <div class="col-xs-12 help_sectionbtns">
            <div class="helpsectionbtn" id="helpbtn_1" (click)="showAccordion(0)">
               <div class="helpbtnicons" id="helpbtnicon1" role="img" aria-label="White general support gear icon">
               </div>
               <div>General Support</div>
            </div>
            <div class="helpsectionbtn" id="helpbtn_2" (click)="showAccordion(1)">
               <div class="helpbtnicons" id="helpbtnicon2"></div>
               <div>Creating an Account</div>
            </div>
            <div class="helpsectionbtn" id="helpbtn_3" (click)="showAccordion(2)">
               <div class="helpbtnicons" id="helpbtnicon3" role="img" aria-label="White orders icon"></div>
               <div>Orders &amp; Returns</div>
            </div>
            <div class="helpsectionbtn" id="helpbtn_4" (click)="showAccordion(3)">
               <div class="helpbtnicons" id="helpbtnicon4" role="img" aria-label="White dollar sign payment icon"></div>
               <div>Payment &amp; Invoices</div>
            </div>
            <div class="helpsectionbtn" id="helpbtn_5" (click)="showAccordion(4)">
               <div class="helpbtnicons" id="helpbtnicon5" role="img"
                  aria-label="Products and information icon in white">
               </div>
               <div>Products &amp; Resources</div>
            </div>
            <div class="helpsectionbtn" id="helpbtn_6" (click)="showAccordion(5)">
               <div class="helpbtnicons" id="helpbtnicon6" role="img" aria-label="Person outline account icon in white">
               </div>
               <div>Account Management</div>
            </div>
         </div>
          <div class="col-xs-12 help_accordion">
            <div id="faqDropdown">
               <div *ngFor="let item2 of mergedObject1; let i = index" [ngClass]="{'show': activeAccordion === i}">
                  <h3>{{item2.faqTitleType}}</h3>
                  <div class="dropdown-item">
                     <div *ngFor="let item3 of mergedObject2; let j = index">
                        <div *ngIf="item3.parentId === item2.uid" class="dropdown-outercontainer">
                           <button class="simple-dropdown-button" [ngClass]="{'active': isActive(i, j)}" type="button"
                              (click)="toggleDropdown(i, j)"> <span class="accordion-text">{{item3.text2}}</span>
                              <span class="arrow-icon">
                                 <i class="fa fa-chevron-down"></i>
                              </span>
                           </button>
                           <div id="content{{i}}-{{j}}" class="dropdown-content" [ngClass]="{'show': isActive(i, j)}">
                              <div class="dropdown-body help_searchsection" [innerHTML]="item3.content"></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="help_seeallsection" class="col-xs-12">
                     <button id="help_seeallbtn" (click)=" sealltopics()" *ngIf="seallbtn">
                        {{item2.bottomLine}}
                     </button>
                  </div>
               </div>
            </div>
         </div> 
      </div>
   </div>
</div>























































































<!-- <div class="col-xs-12 main helpnfaqcontainer" id="helpsection">
   <div *ngIf="secondnode | async as data">
      <div class="col-xs-12 help_searchheader">{{data.header1}}</div>     
      <div class="col-xs-12 help_searchbar">
         <i class="fa fa-search" aria-hidden="true"></i>
         <input type="text" placeholder="Search frequently asked questions" id="help_searchbox">
         <div id="help_searchbtn">Go</div>
      </div>
      <div class="col-xs-12 help_sectionbtns">
         <div class="helpsectionbtn" id="helpbtn_1" (click)="showAccordion(1)">
            <div class="helpbtnicons" id="helpbtnicon1" role="img" aria-label="White general support gear icon"></div>
            <div>General Support</div>
         </div>
         <div class="helpsectionbtn" id="helpbtn_2" (click)="showAccordion(2)">
            <div class="helpbtnicons" id="helpbtnicon2"></div>
            <div>Creating an Account</div>
         </div>
         <div class="helpsectionbtn" id="helpbtn_3" (click)="showAccordion(3)">
            <div class="helpbtnicons" id="helpbtnicon3" role="img" aria-label="White orders icon"></div>
            <div>Orders & Returns</div>
         </div>
         <div class="helpsectionbtn" id="helpbtn_4" (click)="showAccordion(4)">
            <div class="helpbtnicons" id="helpbtnicon4" role="img" aria-label="White dollar sign payment icon"></div>
            <div>Payment & Invoices</div>
         </div>
         <div class="helpsectionbtn" id="helpbtn_5" (click)="showAccordion(5)">
            <div class="helpbtnicons" id="helpbtnicon5" role="img" aria-label="Products and information icon in white"></div>
            <div>Products & Resources</div>
         </div>
         <div class="helpsectionbtn" id="helpbtn_6" (click)="showAccordion(6)">
            <div class="helpbtnicons" id="helpbtnicon6" role="img" aria-label="Person outline account icon in white"></div>
            <div>Account Management</div>
         </div>
      </div>
      <div class="col-xs-12 help_accordion">
         <div id="faqDropdown">
            <div *ngFor="let item2 of mergedObject1; let i = index" [ngClass]="{'show': activeAccordion === i || activeAccordion === null}">
               <h3>{{item2.faqTitleType}}</h3>
               <div class="dropdown-item">
                  <div *ngFor="let item3 of mergedObject2; let j = index">
                     <div *ngIf="item3.parentId === item2.uid" class="dropdown-outercontainer">
                        <button class="simple-dropdown-button" [ngClass]="{'active': isActive(i, j)}" type="button"
                           (click)="toggleDropdown(i, j)">
                           {{item3.text2}}
                           <span class="arrow-icon">
                              <i class="fa fa-chevron-down"></i>
                           </span>
                        </button>
                        <div id="content{{i}}-{{j}}" class="dropdown-content" [ngClass]="{'show': isActive(i, j)}">
                           <div class="dropdown-body help_searchsection" [innerHTML]="item3.content"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div> -->

<!-- <div class="col-xs-12 main helpnfaqcontainer" id="helpsection">
   <div *ngIf="secondnode | async as data">
      <div class="col-xs-12 help_searchheader">{{data.header1}}</div>
      <div class="col-xs-12 help_searchbar">
         <i class="fa fa-search" aria-hidden="true"></i>
         <input type="text" placeholder="Search frequently asked questions" id="help_searchbox">
         <div id="help_searchbtn">Go</div>
      </div>
      <div class="col-xs-12 help_accordion">
         <div class="accordion helpsections" id="faqAccordion">
            <div *ngFor="let item2 of mergedObject1; let i = index" class="accordion-item">
               <h3>{{item2.faqTitleType}}</h3>
               <div *ngFor="let item3 of mergedObject2">
                  <div *ngIf="item3.parentId === item2.uid">

                        <h2 class="accordion-header" id="heading{{i}}">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                           [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" aria-controls="'collapse' + i">
                           {{item3.text2}}
                        </button>
                     </h2>
                     <div id="collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="'heading' + i" data-bs-parent="#faqAccordion">
                        <div class="accordion-body">
                           {{item3.content}}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div> -->
<!-- <div class="col-xs-12 main helpnfaqcontainer" id="helpsection">
   <div *ngIf="secondnode | async as data">
      <div class="col-xs-12 help_searchheader">{{data.header1}}</div>
      <div class="col-xs-12 help_searchbar">
         <i class="fa fa-search" aria-hidden="true"></i>
         <input type="text" placeholder="Search frequently asked questions" id="help_searchbox">
         <div id="help_searchbtn">Go</div>
      </div>
      <div class="col-xs-12 help_accordion">
         <div class="accordion helpsections" id="faqAccordion">
            <div *ngFor="let item2 of mergedObject1; let i = index" class="accordion-item">
               <h3>{{item2.faqTitleType}}</h3>
               <div *ngFor="let item3 of mergedObject2">
                  <div *ngIf="item3.parentId === item2.uid">
                     <h2 class="accordion-header" id="heading{{i}}">
                        <button class="simple-accordion-button" type="button" (click)="toggleAccordion(i)">
                           {{item3.text2}}
                        </button>
                     </h2>
                     <div id="collapse{{i}}" class="simple-accordion-content" [ngClass]="{'show': isActive(i)}">
                        <div class="accordion-body">
                           {{item3.content}}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div> -->
<!-- <div class="col-xs-12 main helpnfaqcontainer" id="helpsection">
   <div *ngIf="secondnode | async as data">
      <div class="col-xs-12 help_searchheader">{{data.header1}}</div>
      <div class="col-xs-12 help_searchbar">
         <i class="fa fa-search" aria-hidden="true"></i>
         <input type="text" placeholder="Search frequently asked questions" id="help_searchbox">
         <div id="help_searchbtn">Go</div>
      </div>
      <div class="col-xs-12 help_accordion">
         <div id="faqDropdown">
            <div *ngFor="let item2 of mergedObject1; let i = index" class="dropdown-item">
               <h3>{{item2.faqTitleType}}</h3>
               <div *ngFor="let item3 of mergedObject2; let j = index">
                  <div *ngIf="item3.parentId === item2.uid">
                     <button class="simple-dropdown-button" [ngClass]="{'active': isActive(i, j)}" type="button"
                        (click)="toggleDropdown(i, j)">
                        {{item3.text2}}
                        <span class="arrow-icon">&#9660;</span>
                     </button>
                     <div id="content{{i}}-{{j}}" class="dropdown-content" [ngClass]="{'show': isActive(i, j)}">
                        <div class="dropdown-body">
                           {{item3.content}}
                        </div>
                     </div>
                     <hr> 
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div> -->