import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-productpage',
  templateUrl: './productpage.component.html',
  styleUrl: './productpage.component.scss'
})
export class ProductpageComponent {
  productData = {
    "constraintList": [
        {
            "key": "deliveryweeksrule2",
            "value": 0
        },
        {
            "key": "deliveryweeksrule1",
            "value": 1
        },
        {
            "key": "maxDeliverySlots",
            "value": 4
        },
        {
            "key": "MinLocProdQuantityCreateOrder",
            "value": 200
        },
        {
            "key": "MaxDelSlotQtyforProdCreateOrder",
            "value": 5000
        },
        {
            "key": "MinDelSlotQtyforProdCreateOrder",
            "value": 100
        },
        {
            "key": "MaxLocProdQuantityCreateOrder",
            "value": 10000
        },
        {
            "key": "firstNumberOfDelSlots",
            "value": 1
        }
    ],
    "priceResponse": {
        "SOR": "10",
        "creditDays": "120",
        "materials": [
            {
                "materialID": "000000000010054395",
                "prodAddedDate": "2024-11-27",
                "salePrice": "12.60"
            },
            {
                "materialID": "000000000010054364",
                "prodAddedDate": "2024-11-27",
                "salePrice": "11.50"
            }
        ],
        "requestType": "CR",
        "soldToID": "0060063573",
        "status": "Success"
    },
    "productList": [
        {
            "baseName": "AQIV",
            "baseProductName": "aQIV - 10 Pack",
            "code": "10054395",
            "doses": 10,
            "orderable": true,
            "perPack": 10,
            "presentationName": "aQIV - 10 Pack",
            "priceData": {
                "currencyIso": "GBP",
                "formattedValue": "£12.60",
                "priceType": "BUY",
                "value": 12.6
            },
            "sellable": false,
            "sequenceId": 1,
            "stock": {
                "isValueRounded": false,
                "stockLevel": 0,
                "stockLevelStatus": "inStock"
            },
            "url": "/products/Adjuvanted",
            "variantOptionQualifiers": [
                {
                    "name": "Seqirus_Trivalent",
                    "qualifier": "Seqirus_Trivalent",
                    "value": "Trivalent"
                }
            ],
            "waitlisted": false
        },
        {
            "baseName": "QIVC",
            "baseProductName": "QIVc - 10 Pack",
            "code": "10054364",
            "doses": 10,
            "orderable": true,
            "perPack": 10,
            "presentationName": "QIVc - 10 Pack",
            "priceData": {
                "currencyIso": "GBP",
                "formattedValue": "£11.50",
                "priceType": "BUY",
                "value": 11.5
            },
            "sellable": true,
            "sequenceId": 2,
            "stock": {
                "isValueRounded": false,
                "stockLevel": 0,
                "stockLevelStatus": "inStock"
            },
            "url": "/products/Adjuvanted",
            "variantOptionQualifiers": [
                {
                    "name": "Seqirus_Quadrivalent",
                    "qualifier": "Seqirus_Quadrivalent",
                    "value": "Quadrivalent"
                }
            ],
            "waitlisted": false
        }
    ],
    "selectedLoc": [
        "60149134",
        "60149133"
    ],
    "shippingData": [
        {
            "address": "22 The,Green",
            "checked": "false",
            "locID": "60149134",
            "locname": "Unity Health_post_sh3",
            "rbaPermission": "manage",
            "state": "Brill, Aylesbury, GB  HP18 9SA"
        },
        { 
            "address": "5 Station_Road",
            "checked": "false",
            "locID": "60149133",
            "locname": "Unity Health_post_SH",
            "rbaPermission": "manage",
            "state": "chinnor, GB  OX39 4PX"
        }
    ]
};
  constructor(public component: CmsComponentData<CmsBannerComponent>, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService) {

  }
  
  handleOnExitClick() {
    this.userInfoService.setPopUpValue(true);
  }
}
