import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-about-section1',
  templateUrl: './about-section1.component.html',
  styleUrl: './about-section1.component.scss'
})
export class AboutSection1Component {
aboutus: Observable<any> = this.component.data$;
constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
}
}
