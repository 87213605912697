import { Component } from '@angular/core';
import { BaseSiteService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-top-banner',
  templateUrl: './custom-top-banner.component.html',
  styleUrl: './custom-top-banner.component.scss'
})
export class CustomTopBannerComponent {
  constructor(public userInfoService: UserInfoService , protected baseSiteService: BaseSiteService) {
  }
  userData$:Observable<any>=this.userInfoService.getProfileData();
  profileData:Observable<any>=this.userInfoService.getUKMyProfileData();
  myProfileData:any;
  isDataAvailable = false
  currentSite :any;
  ngOnInit() {
    this.baseSiteService.getActive().subscribe(res => {
      this.currentSite = res;
      console.log(this.currentSite)
    });
   
  }
  ngAfterviewInit(){
    this.userInfoService.getUKMyProfileData().subscribe(res=>{
      this.isDataAvailable = true
      this.myProfileData = res;
      
      console.log(this.myProfileData)
    })
  }
  }
    

