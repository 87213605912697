<div class="join360-container" *ngIf="oppeffNode| async as item ">
    <div *ngIf="(item.uid === 'SeqirusOperationalEfficiencyFourthComponent')">
        <div id="join360-footer" class="row-flex">
            <div id="join360-row" class="col-12">
                <div class="join360-row-bg1"></div>
                <div class="join360-row-bg2"></div>
                <div class="join360--hero-container">
                    <div class="join360--hero-container--content container">
                        <p class="header">{{item.headline}}</p>
                        <p class="paragraph">{{item.h2content}}</p>
                        <div class="button-container">
                            <a href={{item.link1url}}>
                                <button class="global_redbtn button--hero-outline-red">
                                    {{item.link1content}}</button>
                            </a>
                            <a href="{{item.link2url}}">
                                <button class="button--hero-outline-grey global_greybtn">
                                    {{item.link2content}} </button>
                            </a>
                        </div>
                    </div>
                    <img class="cells-360-bg" alt="flu virus cells outline" [src]="getImage(item.mediaForPicture)">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="join360-clinical-container" *ngIf="clinicalNode | async as item">
    <div *ngIf="(item.uid === 'SeqirusClinicalSupportFourthComponent')">
        <div id="join-footer" class="row-flex">
            <div id="join-row" class="col-12">
                <!-- <div class="join360-row-bg1"></div>
                    <div class="join360-row-bg2"></div> -->
                <div class="join360--hero-container">
                    <div class="join360--hero-container--content container">
                        <p class="header">{{item.headline}}</p>
                        <p class="para">{{item.h2content}}</p>
                        <div class="button-container">
                            <a href={{item.link1url}}>
                                <button class="global_redbtn button--hero-outline-red">
                                    {{item.link1content}}</button>
                            </a>
                            <a href="{{item.link2url}}">
                                <button class="button--hero-outline-grey global_greybtn">
                                    {{item.link2content}} </button>
                            </a>
                        </div>
                    </div>
                    <img class="cells-360-bg" alt="flu virus cells outline" [src]="getImage(item.mediaForPicture)">
                </div>
            </div>
        </div>
    </div>
</div>