<div class="col-xs-12 locations_tablesection">	
    <div [style.display]="isRowClicked?'':'none'"  class="locations_disablebg"></div>	
    <div class="col-xs-12 locations_header">{{'orgLocation.loctextuk' | cxTranslate}}</div>
    <div class="clearfix"></div>

    <!-- mobile Filter -->
    <div class="locations_filtering">
        <div class="col-xs-12 locations_filterbox" (click)="filterClick()">
            <div class=" locations_filterLabel">{{'orgLocation.filterlabel' | cxTranslate}}</div>
            <span class=" locations_filterarrow glyphicon glyphicon-menu-down"></span>
        </div>
        <div class="clearfix"></div>
        <div [style.display]="isFilterOpen?'':'none'" class="locations_filteropen">
            <div class="location_checkboxparent">
                <label class="checkbox-button">
                    <input type="checkbox" name="checkbtn" value="Type" class="location_checkbox" (change)="sortTest('type')">
                    <span class="checkbox-button__control"></span>
                </label>
                <label>{{'orgLocation.typelabel' | cxTranslate}}</label>
            </div>
            <div class="location_checkboxparent">
                <label class="checkbox-button">
                    <input type="checkbox" name="checkbtn" value="Name" class="location_checkbox" (change)="sortTest('name')">
                    <span class="checkbox-button__control"></span>
                </label>
                <label>{{'orgLocation.namelabel' | cxTranslate}}</label>
            </div>
            <div class="location_checkboxparent">
                <label class="checkbox-button">
                    <input type="checkbox" name="checkbtn" value="Address" class="location_checkbox" (change)="sortTest('address')">
                    <span class="checkbox-button__control"></span>
                </label>
                <label>{{'orgLocation.addresslabel' | cxTranslate}}</label>
            </div>
            <div class="location_checkboxparent">
                <label class="checkbox-button">
                    <input type="checkbox" name="checkbtn" value="MainContact" class="location_checkbox" (change)="sortTest('mainContact')">
                    <span class="checkbox-button__control"></span>
                </label>
                <label>{{'orgLocation.contactlabel' | cxTranslate}}</label>
            </div>
            <div class="location_checkboxparent">
                <label class="checkbox-button">
                    <input type="checkbox" name="checkbtn" value="PhoneNumber" class="location_checkbox" (change)="sortTest('phoneNumber')">
                    <span class="checkbox-button__control"></span>
                </label>
                <label>{{'orgLocation.phnlabel' | cxTranslate}}</label>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div id="locationstable_wrapper" class="dataTables_wrapper no-footer">
<table id="locationstable" class="display nowrap locationstable" width="100%" mat-table multiTemplateDataRows matSort (matSortChange)="onSortChange($event)" [dataSource]="dataSource">
            
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 13%;">{{'orgLocation.namelabeluk' | cxTranslate}}
                    <span>
                        <img [ngClass]="{'rotated': currentSort === 'asc' && activeCol == 'name','unsorted':activeCol != 'name'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="name" >{{row.name}}</td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 21%;">{{'orgLocation.addresslabel' | cxTranslate}}
                    <span >
                        <img [ngClass]="{'rotated': currentSort === 'asc' && activeCol == 'address','unsorted':activeCol != 'address'}" src="assets/images/sort_grey.png"/>
                    </span></th>
                <td mat-cell *matCellDef="let row" data-label="address" >{{row.address}}</td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 8%;">{{'orgLocation.typelabel' | cxTranslate}}
                    <span>
                        <img [ngClass]="{'rotated': currentSort === 'asc' && activeCol == 'type','unsorted':activeCol != 'type'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="type" ><ng-container *ngIf="row.type == 'Shipping';else otherType">Delivery</ng-container><ng-template #otherType>{{row.type}}</ng-template></td>
            </ng-container>
            
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header  style="width: 13%;">{{'orgLocation.contactlabeluk' | cxTranslate}}
                    <span>
                        <img [ngClass]="{'rotated': currentSort === 'asc' && activeCol == 'email','unsorted':activeCol != 'email'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="email" >{{row.email}}</td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header  style="width: 13%;">{{'orgLocation.phnlabel' | cxTranslate}}
                    <span>
                        <img [ngClass]="{'rotated': currentSort === 'asc' && activeCol == 'phoneNumber','unsorted':activeCol != 'phoneNumber'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="phoneNumber">{{row.phoneNumber | mask:'00000000000'}}<ng-container *ngIf="row.ext">&nbsp;Ext.{{row.ext}}</ng-container></td>
            </ng-container>
            <ng-container matColumnDef="viewDetails">
                <th mat-header-cell *matHeaderCellDef style="width:12%"></th>
                <td mat-cell *matCellDef="let row" class="details-control">
                   <div (click)="viewDetails(row)">{{isRowExpanded(row) ? 'View Details':'View Details'}}</div> 
                </td>
                
              </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> 
            
            <ng-container *ngIf="isRowExpanded(selectedElement) || !selectedElement">
              <tr #tablemainrow mat-row *matRowDef="let row; columns:displayedColumns;let i=index" [class.row-hide]="!(isRowExpanded(row) || selectedElement===null)" class="main-row1">
            </tr>
            </ng-container>
              <!-- expanded row -->
            <div *ngIf="isRowExpanded(selectedElement)">
        <tr *matRowDef="let row ;columns:[]" [ngClass]="row.type == 'Shipping'?'locations_border':'locations_border billship'" [class.row-hide]="!(isRowExpanded(row) || selectedElement===null)"> 
            <ng-container *ngIf="isRowExpanded(row)">
            <td class="mob-details-td" [attr.colspan]="6">
                
                    <div *ngIf="editElement !== row;else editForm" class="col-xs-12 locations_editsection">
                        <div class="col-xs-12 col-md-2 locations_edit">
                            <div *ngIf="row.status !== 'PENDING'" class="locations_editbtn" (click)="onEdit(row)">Edit <div class="locations_editicon"></div></div>
                            <div class="locations_closeicon" [attr.id]="row.addressId" (click)="close()"></div>
                        </div>
                        <div class="col-xs-12 col-md-5"><div class="locations_editheader">{{'orgLocation.contactInfo' | cxTranslate}}</div> <br>
                        <div class="locations_editsubheader">{{'orgLocation.orgNameukLabel' | cxTranslate}}</div>{{row.name}}
                        <br><br>
                        <div class="locations_editsubheader">{{'orgLocation.addresslabel' | cxTranslate}}</div>{{row.addrLine1}}<br> {{row.addrLine2}}&nbsp;{{row.city}}<br>{{row.zipCode}}<br><br>
                        <div class="locations_editsubheader">{{'orgLocation.contactInfoLabel' | cxTranslate}}</div>{{row.mainContact}}<br>{{row.email}}<br>{{row.phoneNumber}}<br><br>
                        
                       </div>
                        
                        <div class="col-xs-12 col-md-5" [ngClass]="row.type == 'Shipping'?'locations_optional':'locations_optional test'"><div class="locations_editheader">{{'orgLocation.fulllicInfouk' | cxTranslate}}</div> <br>
                        <div class="locations_editsubheader">{{'orgLocation.licNameuk' | cxTranslate}}</div>{{row.licenseName}} <br><br><div class="locations_editsubheader">{{'orgLocation.licNumuk' | cxTranslate}}</div>{{row.licenseNumber}}<br><br><div class="locations_editsubheader">NHS Code</div>{{row.nhsNumber}}<br><br></div>
                    </div>
                        
         <ng-template #editForm>
                
           <form id="locations_editform" [formGroup]="orgLocationsUpdateForm" (ngSubmit)="updateLocation()">
                <div>
                <div class="col-xs-12 locations_editsection locations_editsectionuk">
                    <div class="col-xs-12 col-md-2"><div class="locations_edit"><button type="submit" class="locations_savebtn">Save <div class="locations_saveicon"></div></button><div class="locations_closeicon" (click)="close()"></div></div></div>
                
                <div class="col-xs-12 col-md-5">
                        <div class="locations_editheader col-xs-12">{{'orgLocation.contactInfo' | cxTranslate}}</div>
                        <br><br>
                        <div class="locuk_editheader col-xs-12">Contact Info</div>
                        <div class="">
                            <div class="col-xs-6 form-group"><label class="locations_inputheader">{{'orgLocation.firstNameLabel' | cxTranslate}}<sup>*</sup></label>
                                <div class="input-group">
                                <input type="text" id="loc_editfname" name="loc_editfname" class="form-control" formControlName="loc_editfname">
                                <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editfname')?.touched && orgLocationsUpdateForm.get('loc_editfname')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editfname')?.touched && !orgLocationsUpdateForm.get('loc_editfname')?.hasError('required') && orgLocationsUpdateForm.get('loc_editfname')?.hasError('invalidName')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editfname')?.touched &&  orgLocationsUpdateForm.get('loc_editfname')?.hasError('required')">{{'orgLocform.errorFirstName' | cxTranslate}}</div>
                            
                            </div>
                        </div>
                        <div class="">
                            <div class="col-xs-6 form-group location_lastname"><label class="locations_inputheader">{{'orgLocation.lastNameLabel' | cxTranslate}}<sup>*</sup></label>
                                <div class="input-group">
                                <input type="text" id="loc_editlname" name="loc_editlname" class="form-control" formControlName="loc_editlname">
                                <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlname')?.touched && orgLocationsUpdateForm.get('loc_editlname')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlname')?.touched && !orgLocationsUpdateForm.get('loc_editlname')?.hasError('required') && orgLocationsUpdateForm.get('loc_editlname')?.hasError('invalidName')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlname')?.touched && orgLocationsUpdateForm.get('loc_editlname')?.hasError('required')">{{'orgLocform.errorLastName' | cxTranslate}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group"><label class="locations_inputheader">Job Title<sup>*</sup></label><select id="loc_editjob" name="loc_job" class="loc_editjobuk" formControlName="loc_editjob" value=""><option value="Practice Manager">Practice Manager</option><option value="Practice Nurse">Practice Nurse</option><option value="General Practitioner">General Practitioner</option><option value="Pharmacist">Pharmacist</option><option value="Pharmacy Assistant">Pharmacy Assistant</option><option value="Purchasing/Procurement">Purchasing/Procurement</option></select></div>
                        <div class="">
                            <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocation.emailLabel' | cxTranslate}}<sup>*</sup></label>
                                <div class="input-group">
                                <input type="text" id="loc_editemail" name="loc_editemail" class="form-control" formControlName="loc_editemail">
                                <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editemail')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editemail')?.hasError('pattern')">{{'orgLocform.errorValidEmail' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editemail')?.hasError('required')">{{'orgLocform.errorEmail' | cxTranslate}}</div>
                            
                            </div>
                        </div>
                        
                        <div class="editsubheader_enable" style="display: block;"><div class="col-xs-6 form-group"><label class="locations_inputheader">{{'orgLocation.phnlabeluk' | cxTranslate}}<sup>*</sup></label>
                            <div class="input-group">
                            <input type="text" id="loc_editphone" name="loc_editphone" class="form-control" maxlength="11" formControlName="loc_editphone">
                            <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.touched && orgLocationsUpdateForm.get('loc_editphone')?.invalid">
                                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                            </span>
                            </div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.touched && orgLocationsUpdateForm.get('loc_editphone')?.hasError('pattern')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.touched && orgLocationsUpdateForm.get('loc_editphone')?.hasError('required')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.touched && !orgLocationsUpdateForm.get('loc_editphone')?.hasError('pattern') && orgLocationsUpdateForm.get('loc_editphone')?.hasError('minlength')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.touched && !orgLocationsUpdateForm.get('loc_editphone')?.hasError('pattern') && orgLocationsUpdateForm.get('loc_editphone')?.hasError('maxlength')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
                            </div>
                        </div>
                        <div class="editsubheader_enable" style="display: block;"><div class="col-xs-4 form-group location_ext"><label class="locations_inputheader">{{'orgLocation.extlabel' | cxTranslate}}</label>
                            <div class="input-group">
                            <input type="text" id="loc_editext" name="loc_editext" class="form-control" maxlength="4" formControlName="loc_editext">
                            <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editext')?.touched && orgLocationsUpdateForm.get('loc_editext')?.value && orgLocationsUpdateForm.get('loc_editext')?.invalid">
                                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                            </span>
                            </div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editext')?.touched &&  orgLocationsUpdateForm.get('loc_editext')?.value && orgLocationsUpdateForm.get('loc_editext')?.hasError('maxlength')">{{'orgLocform.errorExt' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editext')?.touched &&  orgLocationsUpdateForm.get('loc_editext')?.value && orgLocationsUpdateForm.get('loc_editext')?.hasError('pattern')">{{'orgLocform.errorValidExt' | cxTranslate}}</div>
                            </div>
                    </div>

                <div class="locations_editlocsubheader col-xs-12">{{'orgLocform.mainAddr' | cxTranslate}}</div><div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocation.orgNameukLabel' | cxTranslate}}</label>
                    <div class="input-group">
                    <input type="text" id="loc_editorgname" name="loc_editorgname" class="form-control" formControlName="loc_editorgname">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editorgname')?.touched && orgLocationsUpdateForm.get('loc_editorgname')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editorgname')?.touched && orgLocationsUpdateForm.get('loc_editorgname')?.hasError('pattern')">{{'orgLocform.errorValidOrgName' | cxTranslate}}</div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editorgname')?.touched && orgLocationsUpdateForm.get('loc_editorgname')?.hasError('required')">{{'orgLocform.errorOrgname' | cxTranslate}}</div>
                    </div>
                
                <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.shipAddrLookup' | cxTranslate}}</label><i class="fa fa-search loc_editsearchicon loc_editsearchiconuk"></i><input type="text" id="loc_editaddlookup" name="loc_editaddlookup" value="" formControlName="loc_editaddlookup" class="loc_editaddlookupuk form-control"></div>
                    <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.shipAddrLine1' | cxTranslate}}</label>
                        <div class="input-group">
                        <input type="text" id="loc_editadd1" name="loc_editadd1" class="form-control" formControlName="loc_editadd1">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editadd1')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editadd1')?.hasError('required')">{{'orgLocform.errorAddr' | cxTranslate}}</div>
                     </div>
                     <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.shipAddrLine2' | cxTranslate}}</label><input type="text" id="loc_editadd2" name="loc_editadd2" class="form-control" formControlName="loc_editadd2"></div>
                     <div class="col-xs-6 form-group"><label class="locations_inputheader">{{'orgLocform.shipCity' | cxTranslate}}</label>
                        <div class="input-group">
                        <input type="text" id="loc_editcity" name="loc_editcity" class="form-control" formControlName="loc_editcity">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editcity')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editcity')?.hasError('pattern')">{{'orgLocform.errorvalidCity' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editcity')?.hasError('required')">{{'orgLocform.errorCity' | cxTranslate}}</div>
                        
                    </div>
                    <div class="col-xs-5 form-group location_zip"><label class="locations_inputheader">{{'orgLocform.shipZipuk' | cxTranslate}}</label><div class="input-group edit-zip"><input type="text" id="loc_editzip" name="loc_editzip" class="form-control" formControlName="loc_editzip">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editzip')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editzip')?.hasError('pattern')">{{'orgLocform.errorzipcodeuk' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editzip')?.hasError('required')">{{'orgLocform.errorzipcodeuk' | cxTranslate}}</div>
                    </div>
                    <div class="col-xs-5 form-group location_country"><label class="locations_inputheader">Country<sup>*</sup></label><input type="text" id="loc_editcountry" name="loc_editcountry" readonly="" value="United Kingdom" class="form-control"></div>
                
                
            </div>
                
            <div class="col-xs-12 col-md-5" [ngClass]="row.type == 'Shipping'?'locations_optional':'locations_optional test'"><div class="locations_editheader col-xs-12">{{'orgLocation.fulllicInfouk' | cxTranslate}}</div> <br>
                    <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocation.licNameuk' | cxTranslate}}<sup>*</sup></label>
                        <div class="input-group"><input type="text" id="loc_editlicname" name="loc_editlicname" class="form-control" formControlName="loc_editlicname">
                            <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlicname')?.touched && orgLocationsUpdateForm.get('loc_editlicname')?.invalid">
                                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                            </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicname')?.touched && orgLocationsUpdateForm.get('loc_editlicname')?.hasError('required')">{{'orgLocform.errorValidLicName' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicname')?.touched && !orgLocationsUpdateForm.get('loc_editlicname')?.hasError('required') && orgLocationsUpdateForm.get('loc_editlicname')?.hasError('invalidName')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
                     </div>
                    <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocation.licNumuk' | cxTranslate}}<sup>*</sup></label><div class="input-group"><input type="text" id="loc_editlicnum" maxlength="7" name="loc_editlicnum" class="form-control" formControlName="loc_editlicnum">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlicnum')?.touched && orgLocationsUpdateForm.get('loc_editlicnum')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicnum')?.touched && orgLocationsUpdateForm.get('loc_editlicnum')?.hasError('required')">{{'orgLocform.errorValidLicNumuk' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicnum')?.touched && !orgLocationsUpdateForm.get('loc_editlicnum')?.hasError('required') && orgLocationsUpdateForm.get('loc_editlicnum')?.hasError('invalidLicNum')">{{'orgLocform.errorValidLicNumuk' | cxTranslate}}</div>
                    </div>
                    <div class="col-xs-12 form-group" [ngClass]="{'has-error':orgLocationsUpdateForm.get('loc_editnhsnumb')?.touched && orgLocationsUpdateForm.get('loc_editnhsnumb')?.value && orgLocationsUpdateForm.get('loc_editnhsnumb')?.invalid}"><label class="locations_inputheader">NHS Code</label><div class="input-group"><input type="text" id="loc_editnhsnumb" name="loc_editnhsnumb" value="NA" class="form-control" formControlName="loc_editnhsnumb">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editnhsnumb')?.touched && orgLocationsUpdateForm.get('loc_editnhsnumb')?.value && orgLocationsUpdateForm.get('loc_editnhsnumb')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editnhsnumb')?.touched && orgLocationsUpdateForm.get('loc_editnhsnumb')?.value && orgLocationsUpdateForm.get('loc_editnhsnumb')?.hasError('nhserror1')">{{'orgLocform.errorNhsGpadd' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editnhsnumb')?.touched && orgLocationsUpdateForm.get('loc_editnhsnumb')?.value && orgLocationsUpdateForm.get('loc_editnhsnumb')?.hasError('nhserror2')">{{'orgLocform.errorNhsPharmaadd' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editnhsnumb')?.touched && orgLocationsUpdateForm.get('loc_editnhsnumb')?.value && orgLocationsUpdateForm.get('loc_editnhsnumb')?.hasError('nhserror3')">{{'orgLocform.errorNhsAlladd' | cxTranslate}}</div>
                    
                    </div>
                
            </div>
            </div>
            </div>
                   
            </form>
       
        </ng-template>
        </td>
         </ng-container>
        </tr>
    </div>
     

</table>

<div [hidden]="isTableHasData" class="center-message dataTables_empty">
    No matching records found
</div>
<div [hidden]="tableDataLoaded" class="center-message dataTables_empty">
    Loading...
</div>
 
<div class="dataTables_length" id="locationstable_length"><label>Showing <select name="locationstable_length" (change)="onPageSizeChange($event)" [value]="pageSizeControl.value">
    <option *ngFor="let size of pageSizeOptions" [value]="size">{{size}}</option>
</select></label></div>
<div class="custom-paginator dataTables_paginate paging_full_numbers" id="locationstable_paginate">
    <button class="pagination-buttonOrg paginate_button" (click)="goToFirstPage()" [disabled]="isFirstPage()">
        <img alt="Navigate to previous page" src="assets/images/pagination-double-prev-arrow-icon.svg" class="locations_arrows">
      </button>
      <button class="pagination-buttonOrg paginate_button" (click)="previousPage()" [disabled]="isFirstPage()">
        <img alt="Navigate to previous page" src="assets/images/pagination-prev-btn.svg" class="locations_arrows">
      </button>
      <button [hidden]="!isTableHasData" class="pagination-buttonOrg paginate_button" *ngFor="let pge of getPages()" [class.active-page]="isCurrentPage(pge)" (click)="goToPage(pge)">
        {{pge}}
      </button>
      <button class="pagination-buttonOrg paginate_button" (click)="nextPage()" [disabled]="isLastPage()">
        <img alt="Navigate to next page" src="assets/images/pagination-next-btn.svg" class="locations_arrows">
      </button>
      <button class="pagination-buttonOrg paginate_button" (click)="goToLastPage()" [disabled]="isLastPage()">
        <img alt="Navigate to last page" src="assets/images/pagination-double-next-arrow-icon.svg" class="locations_arrows">
      </button>
    
</div>
</div>
</div>